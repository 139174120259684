<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Lista de itens
      </h5>
    </template>
    <div class="p-3">
      <!-- INFORMAÇOES -->
      <div class="d-flex align-items-center">
          <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared"/>
          <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Informação</span>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="">
          <div style="font-size: 18px ;font-weight: 500; color: #2B2D32;">{{ purchaseData.code }}</div>
          <div style="font-size: 14px ;font-weight: 400; color: #2B2D32;">manutenção</div>
          <div class="mt-2" style="font-size: 12px ;font-weight: 400; color: #606062;">
            {{ purchaseData.input_company_plant_name }}
          </div>
          <div class="mt-1" style="font-size: 12px ;font-weight: 400; color: #606062;">
            prazo: {{ purchaseData.deadline | parseDate("DD MMM YYYY") }}
          </div>
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <span class="font-weight-500">Solicitante:</span>
              <div style="font-size: 11px">{{ purchaseData.created_by_user_name }}</div>
              <div style="font-size: 11px">{{ purchaseData.created_by_user_email }}</div>
              <span slot="reference">
                <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
              </span>
            </el-popover>
          </div>
          <div class="mr-2">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div class="p-0" style="max-width: 14rem;">
                <span style="font-size: 14px;">Observações</span>
                <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                <div class="d-block mt-2">
                  <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                  <div style="font-size: 12px;" class="mt-1">{{ purchaseData.note }}</div>
                </div>
                <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                <div class="d-block mt-2">
                  <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                  <div style="font-size: 12px;" class="mt-1">{{ purchaseData.purchase_justification }}</div>
                </div>
              </div>
              <span slot="reference">
                <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="26"/>
              </span>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="dropdown-divider my-4" style="width: -webkit-fill-available;" />
      <div class="d-flex align-items-center">
        <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2"/>
        <span  style="font-size: 16px ;font-weight: 500; color: #2B2D32;">Itens</span>
      </div>
      <!-- TABELA -->
      <div class="table-container mt-4 pt-0">
        <div class="row mx-1">
          <table class="table table-custom-shaded table-sm mb-9 pb-3 mt-4">
            <thead>
              <tr class="fixed">
               
                <th class="text-center" @click="selectAll()">
                  <img width="18" src="/img/icons/double-box-tick-primary.png" class="mr-2 pointer"/>
                </th>
                <th class="text-left new-default-black-font">Item</th>
                <th class="text-left new-default-black-font">Marca</th>
                <th class="text-left new-default-black-font">Quantidade</th>
                <th class="text-left new-default-black-font">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in requestItems" :key="item.id">
                <td class="text-center">
                  <img 
                    v-if="requestItems[index]" 
                    @click="toggleStatus(index)" 
                    width="18" 
                    class="mr-2 pointer"
                    :src="requestItems[index].status ? '/img/icons/box-tick-primary.png' : '/img/icons/black-square.svg'"
                  />
                </td>
                <td>{{ item.description }}</td>
                <td>{{ item.brand }}</td>
                <td>{{ item.quantity }}</td>
                <td>
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                    </div>
                    <a class="dropdown-item font-weight-500 new-default-black-font"
                      style="align-items: center; display: flex"  @click="removePurchaseRequestItem(item.id)">
                      <img width="23" height="23" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                      Remover
                  </a>
                  </base-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if=" showButtons && purchaseData.status != purchaseRequestEnum.CANCELED && !showUpdateButton"
        class="d-flex justify-content-end mb-2 mt-3 text-center">
        <div v-if="purchaseData.status != purchaseRequestEnum.DENIED" @click="save(purchaseRequestEnum.DENIED)" class="aprovation-button bg-danger mr-0 mr-2">
          <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down.png">
          negar
        </div>
        <div @click="save(purchaseRequestEnum.APPROVED)" class="aprovation-button bg-primary" v-if="purchaseData.status != purchaseRequestEnum.APPROVED">
          <img class="mr-2" width="20" src="/img/icons/thumb-up--v1-white.png">
          aprovar
        </div>
      </div>
      <div v-if="showUpdateButton" class="d-flex justify-content-end mb-2 mt-3 text-center">
        <div @click="save(purchaseRequestEnum.APPROVED)" class="aprovation-button bg-primary" >
          Salvar
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import {PurchaseRequestEnum} from "@/enum/PurchaseRequestEnum";

export default {
  name: "ModalDigitalSignatureList",
  props: {
    showUpdateButton: {
      type: Boolean,
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modal: false,
      purchaseData: null,
      requestItems: [],
      purchaseRequestEnum: PurchaseRequestEnum,
      filter: {
        order_by: [
          {
            column: 'purchase_request_items.id',
          }
        ],
        purchase_request_id: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  methods: {
  // Modal
    handleCreateModal(data) {
      this.purchaseData = data;
      this.filter.purchase_request_id = this.purchaseData.id;
      this.init();
    },
    closeModal() {
      this.modal = false
    },
    init(filter = null) {
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseRequest/fetchRequestItems', this.filter)
        .then((response) => {
          this.requestItems = response.data.items;
        }).finally(() => {
        this.loading = false;
        this.modal = true
        loader.hide()
      })
    },
    save(status) {
      if (!this.purchaseData.can_approve) {
        this.$notify({type: 'warning', message: 'Usuário não tem permissão para aprovar/negar requisição!'});
        return
      }
      if (!this.requestItems.some(item => item.status === this.purchaseRequestEnum.APPROVED) && status === this.purchaseRequestEnum.APPROVED) {
        this.$notify({type: 'warning', message: 'Selecione pelo menos um item!'});
        return
      } else if (this.purchaseData.has_active_budget && status === this.purchaseRequestEnum.DENIED) {
        this.$notify({type: 'warning', message: 'A cotação já possui orçamento ativo!'});
        return
      }
      let loader = this.$loading.show();
      let data = { ...this.purchaseData };
      data.items = this.requestItems;
      data.updated_by_user_id = this.$_user.id;
      data.status = status;
      this.$store.dispatch('purchaseRequest/update', data)
        .then(() => {
          loader.hide();
          this.$notify({type: 'success', message: 'Requisição salva!'});
          this.modal = false;
          this.$emit('fetch');
        });
    },
    removePurchaseRequestItem(id) {
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseRequest/destroy', id).then(() => {
        loader.hide();
        this.$notify({type: 'success', message: 'Item removido!'});
        this.init({});
      })
    },
    toggleStatus(index) {
      // Alterna o status entre 0 (Desmarcado) e 1 (Selecionado)
      const newStatus = this.requestItems[index].status === 1 ? 0 : 1;
      this.$set(this.requestItems, index, {
        ...this.requestItems[index],
        status: newStatus
      });
    },
    selectAll() {
      this.requestItems = this.requestItems.map(item => ({
        ...item,
        status: 1
      }));
    },
  }
}
</script>

<style scoped>
.table-container {
  overflow: auto;
  max-height: 16rem;
  width: 100%;
  background-color: #f4f8fb;
  padding: 1rem;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
}
.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}
.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

</style>
