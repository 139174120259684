<template>
  <div>
    <div class="container-fluid">
      <!-- Widgets -->
      <div v-if="$_widgets && !loadingWidgets" class="row row-cols-1 row-cols-md-4">
        <div class="col mt-n4 p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/calendar.png" width="25px" height="25px" class="mr-2"/>
              <span class="card-title text-dark font-size-title-widgets">
                Programado
              </span>
              <div class="text-dark card-text-border mb-2 mt-2 ml-1 font-size-widgets">
                {{ $_widgets.scheduled_volume }} m³ ({{ $_widgets.scheduled_travels }} viagens)
              </div>
            </div>
          </div>
        </div>
        <div class="col mt-n4 p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/ok--v1.png" width="25px" height="25px" class="mr-2"/>
              <span class="card-title text-dark font-size-title-widgets">
                Realizado
              </span>
              <div class="text-dark card-text-border mb-2 mt-2 ml-1 font-size-widgets">
                {{ $_widgets.realized_volume }} m³ ({{ $_widgets.realized_travels }} viagens)
              </div>
            </div>
          </div>
        </div>
        <div class="col mt-n4 p-1">
          <div class="card height-card">
            <div class="card-body">
              <img
                src="/img/icons/icons8/ios/do-not-disturb.png"
                width="25px"
                height="25px"
                class="mr-2"
                style="filter: brightness(0)"
              />
              <span class="card-title text-dark font-size-title-widgets">
                Não realizado
              </span>
              <div class="text-dark card-text-border mb-2 mt-2 ml-1 font-size-widgets">
                {{ $_widgets.scheduled_volume - $_widgets.realized_volume - $_widgets.canceled_volume }} m³
                ({{ $_widgets.scheduled_travels - $_widgets.realized_travels - $_widgets.canceled_travels }} viagens)
              </div>
            </div>
          </div>
        </div>
        <div class="col mt-n4 p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/icons8/ios/cancel_danger.png" width="25px" height="25px" class="mr-2"/>
              <span class="card-title text-dark font-size-title-widgets">
                Cancelamentos
              </span>
              <div class="text-dark card-text-border mb-2 mt-2 ml-1 font-size-widgets">
                {{ $_widgets.canceled_volume }} m³ ({{ $_widgets.canceled_travels }} viagens)
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div class="row card-wrapper mt-3 p-0" v-show="loadingWidgets">
        <div
          v-for="index in 4"
          :key="index" :class="'col-md-3'"
          class="mt-n4"
        >
          <div class="card card-border-top-light bg-gray-content">
            <div style="padding: 1.4rem" class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-n1">
        <div class="col-md-12 p-1 mt-3">
          <MultiFilter ref="multi-filter" @fetch="init" :filter="filter" :without_filter="true">
            <div class="col-md-3 p-2">
              <InputDatePicker
                size="small"
                ref="date-picker"
                @handleFilterDate="handleFilterDate"
                :isRange="false"
              />
            </div>
            <div class="col-md-3 p-2">
              <PuzlSelect
                v-model="filter.company_plants"
                :items="$_plants"
                :loading="loadingCompanyPlants"
                :multiple="true"
                placeholder="Centrais"
                @input="handleFilterCompanyPlants"
              />
            </div>
            <div class="col-md-3 p-2">
              <button
                style="
                  margin: 0;
                  width: 115px;
                  justify-content: center;
                  min-height: 28px; padding: 0;
                  font-size: 11px;
                  display: flex;
                  align-items: center;
                "
                slot="title-container"
                @click="handleFilterAdvancedStatus()"
                :class="[
                  'ml-2',
                  'btn',
                  'text-uppercase',
                  'colorize-btn-img',
                  { 'btn-outline-danger': filter.canceled !== ScheduleTravelEnum.CANCELED },
                  { 'btn-danger': filter.canceled === ScheduleTravelEnum.CANCELED }
                ]"
                type="button"
              >
                <img
                  :src="filter.canceled === ScheduleTravelEnum.CANCELED ? '/img/icons/close-white.png' : '/img/icons/close.png'"
                  width="16px"
                  class="mr-1"
                />
                Cancelada
              </button>
            </div>
          </MultiFilter>
          <TableConcretes
            :loadingSkeleton="loadingSkeleton"
            @copyContractProposal="copyContractProposal"
            @downloadProof="downloadProof"
            @downloadDanfe="downloadDanfe"
          />
          <PuzlEmptyData v-if="!$_concretes.length && !loadingSkeleton" />
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
import { floatToHuman } from "@/helpers";
import moment from "moment";
import ModalAdjustment from "@/views/Modules/Operational/Schedule/Charging/Shared/_ModalAdjustments.vue";
import TableConcretes from "./_TableConcretes";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";
import { XmlTravelEnum } from "@/enum/XmlTravelEnum";

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzl,
    PuzlSelect,
    ModalAdjustment,
    TableConcretes,
  },
  name: "ListChargeResume",
  mixins: [cursorPaginate],
  data() {
    return {
      ScheduleTravelEnum: ScheduleTravelEnum,
      XmlTravelEnum: XmlTravelEnum,
      filter: {
        dateFilter: new Date(),
        company_plants: [],
        canceled: null,
      },
      selectedCompanyPlants: [],
      loadingSkeleton: false,
      loadingConcretes: false,
      loadingWidgets: true,
      loadingCompanyPlants: true,
      floatToHuman: floatToHuman,
    };
  },
  computed: {
    ...mapGetters({
      $_widgets: "chargeResume/getWidgets",
      $_concretes: "chargeResume/fetch",
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingCompanyPlants = false;
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    }
  
    this.filter.dateFilter = moment().format('YYYY-MM-DD');
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.init(this.filter);
    },
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.$Progress.start();
      this.startCursor(filter);
      this.filter.company_plants = this.selectedCompanyPlants;

      this.$store
        .dispatch("chargeResume/fetchConcretesItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingConcretes = false;
          this.$Progress.finish();
        });

       this.getWidgets();
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store.dispatch("chargeResume/getWidgets", { filter: this.filter })
        .then((response) => {
          this.loadingWidgets = false;
        });
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.filter.dateFilter = moment(filter).format('YYYY-MM-DD');
      this.startLoading();
      this.init({});
    },
    /**
     * @param {object} filter
     */
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.startLoading();
      this.init({});
      this.getWidgets();
    },
    startLoading() {
      this.loadingConcretes = true;
      this.loadingWidgets = true;
    },
    handleFilterAdvancedStatus() {
      this.filter.canceled = this.filter.canceled === this.ScheduleTravelEnum.CANCELED ?
        null :
        this.ScheduleTravelEnum.CANCELED;
      this.startLoading();
      this.init({});
      this.getWidgets();
    },
    /**
     * @param {string} value
     */
    copyContractProposal(value) {
      navigator.clipboard.writeText(value);
    },
    /**
     * Download o.s de viagens
     * @param {string} schedule_travel_uuid
     */
    downloadProof(schedule_travel_uuid) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    /**
     * Download Nf-e
     * @param {number} xml_travel_status
     * @param {string} xml_travel_key
     * @param {string} schedule_travel_uuid
     */
    async downloadDanfe(xml_travel_status, xml_travel_key, schedule_travel_uuid) {
      if (xml_travel_status !== XmlTravelEnum.NFE_COMPLETED) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml_travel_key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.font-size-title-widgets {
  font-size: 11.3px;
}
.font-size-widgets {
  font-size: 10px;
}

.custom-button-canceled {

}
</style>
