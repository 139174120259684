<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-n1">
        <div class="col-md-12 p-1">
          <MultiFilter ref="multi-filter" @fetch="init" :filter="filter" :status="multi_filter">
            <div class="col-md-3 p-2">
              <InputDatePicker size="small" ref="datePicker" @handleFilterDate="handleFilterDate"
                :default-range="range" :filterable="4" />
            </div>
            <div class="col-md-3 p-2">
              <PuzlSelect
                v-model="filter.company_plants"
                :items="$_plants"
                :loading="loadingCompanyPlants"
                customKey="id"
                :multiple="true"
                placeholder="Centrais"
                @input="handleFilterCompanyPlants"
              />
            </div>
            <div class="col-md-3 p-2">
              <PuzlSelect
                v-model="filter.sellers_uuid"
                :items="$_sellers"
                :loading="loadingSeller"
                customKey="uuid"
                multiple
                placeholder="Vendedor"
                @input="handleFilterSellers"
              />
            </div>
            <div class="col-md-2 p-2">
              <base-button 
                :class="filter.is_without_invoice && 'active'" 
                @click="
                  filter.is_without_invoice = +!filter.is_without_invoice, 
                  type === 'concretes' ? loadingConcretes = true : loadingServices = true,
                  init({})
                "
                size="sm"
                outline 
                block 
                type="dark"
              >
                FATURAMENTO PENDENTE
              </base-button>
            </div>
            <div class="col-md-1 p-2 text-right" v-if="$_concretes.length || $_services.length">
              <a href="#" @click.prevent="downloadStockExcel()">
                <img
                  style="width: 28px"
                  src="/img/icons/excel.png" />
              </a>
            </div>
          </MultiFilter>
        </div>
      </div>
      <!-- Widgets -->
      <div v-if="$_widgets && !loadingWidgets" class="card-row row">
        <div class="col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-1">
                  <img src="/img/icons/test-tube.png" height="29px" > 
                </div>
                <div class="col-12">
                  <p
                    class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                    style="font-family: FreeMono, monospace"
                  >
                    <span class="card-text font-weight-500 text-dark ml-n2">
                      VOLUME DE CONCRETO
                    </span>
                  </p>
                </div>
                <div class="col-md-12">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric ml-n2" style="font-size: 13px;">
                      {{ floatToHuman($_widgets.formulation_volume) }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <img src="/img/icons/todo-list.png" height="29px">
                </div>
                <div class="col-12">
                  <p
                    class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                    style="font-family: FreeMono, monospace"
                  >
                    <span class="card-text font-weight-500 text-dark ml-n2">
                      VOLUME DE SERVIÇO
                    </span>
                  </p>
                </div>
                <div class="col-md-12">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric" style="font-size: 13px;">
                      {{ floatToHuman($_widgets.service_volume) }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <img src="/img/icons/icons8/ios/estimate-warning.png" height="29px">
                </div>
                <div class="col-12">
                  <p
                    class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                    style="font-family: FreeMono, monospace"
                  >
                    <span class="card-text font-weight-500 text-dark ml-n2">
                      TOTAL
                    </span>
                  </p>
                </div>
                <div class="col-md-12">
                  <h2
                    v-if="$_widgets.total_value"
                    class="font-weight-semibold mb-0 pl-2"
                  >
                    <div class="numeric" style="font-size: 13px">
                      {{ $_widgets.total_value | currency }}
                    </div>
                  </h2>
                  <h2 v-else class="font-weight-semibold mb-0 pl-2">
                    <div class="numeric" style="font-size: 13px">
                      {{ 0 | currency }}
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div class="row card-wrapper" v-show="loadingWidgets">
        <div 
          v-for="index in 3" 
          :key="index" :class="'col-md-4'" 
          class="mt-n4 mb-2"
        >
          <div class="card card-border-top-light bg-gray-content">
            <div style="padding: 0.7rem" class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tabelas -->
      <div class="row card-row mt-0 mt-n4">
        <div class="col-md-12 p-1">
          <div>
            <ul role="tablist" class="nav nav-tabs nav-justified">
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    type = 'concretes', 
                    loadingWidgets = true,
                    loadingConcretes = true, 
                    init({})
                  "
                  role="tab"
                  aria-selected="true"
                  aria-setsize="4"
                  aria-posinset="1"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #DCDFE6"
                  :style="
                    type === 'concretes'
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="type === 'concretes' && 'active'"
                >
                  <img 
                    :src="
                      type === 'concretes'
                        ? '/img/icons/test-tube-white.png'
                        : '/img/icons/test-tube-dark.png'
                    " 
                    width="22px" 
                    height="22px" 
                    class="mr-1" 
                  />
                  <b class="font-weight-500">Concretos</b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    type = 'services', 
                    loadingWidgets = true,
                    loadingServices = true, 
                    init({})
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #DCDFE6"
                  :style="
                    type === 'services'
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="type === 'services' && 'active'"
                >
                  <img 
                    :src="
                      type === 'services'
                        ? '/img/icons/todo-list-white.png'
                        : '/img/icons/todo-list-dark.png'
                    " 
                    width="22px" 
                    height="22px" 
                    class="mr-1" 
                  />
                  <b class="font-weight-500">Serviços</b>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
            v-if="type === 'concretes'"
          >
            <TableConcretes
              :loadingSkeleton="loadingSkeleton"
              @downloadProof="downloadProof"
              @downloadDanfe="downloadDanfe"
              @copyContractProposal="copyContractProposal"
              @showAdjustments="showAdjustments"
            />
			<div style="margin-top: 5px;">
            	<PuzlEmptyData v-if="!$_concretes.length && !loadingSkeleton" />
			</div>
          </div>
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
            v-if="type === 'services'"
          >
            <TableServices
              :loadingSkeleton="loadingSkeleton"
              @getReport="getReport"
              @copyContractProposal="copyContractProposal"
            />
			<div style="margin-top: 5px;">
            	<PuzlEmptyData v-if="!$_services.length && !loadingSkeleton" />
			</div>
          </div>
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
      <ModalAdjustment ref="modalAdjustment" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from '@/components/InputDatePicker';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
import { floatToHuman } from "@/helpers";
import moment from "moment";
import ModalAdjustment from "@/views/Modules/Operational/Schedule/Charging/Shared/_ModalAdjustments.vue";
import TableConcretes from "./_TableConcretes";
import TableServices from "./_TableServices";
import { base_url_ms } from "@/plugins";
import { XmlTravelEnum } from "@/enum/XmlTravelEnum";

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    PuzlSelect,
    ModalAdjustment,
    TableConcretes,
    TableServices,
  },
  name: "ListDeliveryResume",
  mixins: [cursorPaginate],
  data() {
    return {
      filter: {
        status: null, // para aba concretos
        schedule_status: null, // para aba serviços
        range: null,
        company_plants: null,
        cursorPaginate: true, // para aba de serviços
        is_without_invoice: 0,
      },
      selectedCompanyPlants: [],
      selectedSellers: [],
      defaultRange: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date()
      },
      multi_filter: { // viagens
        1: {
          name: "Concluído",
          type: "success",
        },
        6: {
          name: "Cancelado",
          type: "danger",
        },
      },
      loadingConcretes: false,
      loadingServices: false,
      loadingSkeleton: false,
      loadingCompanyPlants: true,
      loadingSeller: true,
      loadingWidgets: true,
      type: 'concretes', // concretes | services
      floatToHuman: floatToHuman,
      base_url_ms: base_url_ms(),
      XmlTravelEnum: XmlTravelEnum,
    }
  },
  computed: {
    ...mapGetters({
      $_widgets: "salesResume/getWidgets",
      $_widgetsService: 'salesResume/getWidgets',
      $_concretes: "deliveryResume/fetch",
      $_services: 'salesResume/fetchServices',
      $_plants: "plant/activeItems",
      $_sellers: "user/sellers",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingCompanyPlants = false;
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    }

    this.$store.dispatch("user/fetchSellers").then(() => {
      this.loadingSeller = false;
    });
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.range = this.defaultRange;
      this.filter.status = 1; // viagens concluídas
      this.init(this.filter);
    },
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.$Progress.start();
      this.startCursor(filter);
      this.filter.range = this.range;
      this.filter.company_plants = this.selectedCompanyPlants;
      if (filter && filter.status) {
        this.filter.schedule_status = filter.status; // viagens
      } else {
        this.filter.status = 1;
        this.filter.schedule_status = 1; // viagens concluídas
      }
      if (filter && filter.status) {
        this.startLoading();
      }
      this.updateMultiFilterForConcretes();
      if (this.loadingWidgets) {
        this.getWidgets();
      }

      // Aba concretos ou serviços
      if (this.type === 'concretes') {
        this.$store
          .dispatch("deliveryResume/fetchConcretesItemsPaginate", {
            filter: this.filter,
            next_page: this.paginate.nextUrl
          })
          .then((response) => {
            this.resolveCursor(response);
          })
          .catch((error) => {
            this.resolveCursor();
          })
          .finally(() => {
            this.loadingConcretes = false;
            this.$Progress.finish();
          });
      } 
      else { // serviços
        if (filter && filter.status) {
          this.filter.status = filter.status; // payment_status da tabela invoices
        } else {
          this.filter.status = 1;
        }
        this.updateMultiFilterForServices();
        this.$store
        .dispatch("salesResume/fetchServicesItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingServices = false;
          this.$Progress.finish();
        });
      }
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store
        .dispatch("salesResume/getWidgets", { filter: this.filter })
        .then(() => {
          this.loadingWidgets = false;
        });
    },
    updateMultiFilterForConcretes() {
      // viagens
      this.multi_filter = {
        1: {
          name: "Concluído",
          type: "success",
        },
        6: {
          name: "Cancelado",
          type: "danger",
        },
      };
    },
    updateMultiFilterForServices() {
      // programação de serviços
      this.multi_filter = {
        1: {
          name: "Concluído",
          type: "success",
        },
        3: {
          name: "Cancelado",
          type: "danger",
        },
      };
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date()
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
      this.startLoading();
      this.init({});
    },
    /**
     * @param {object} filter
     */
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.startLoading();
      this.init({});
      this.getWidgets();
    },
    handleFilterSellers() {
      const index = this.selectedSellers.findIndex((item) => item == "all");
      if (this.selectedSellers.length > 1 && index === 0) {
        return this.selectedSellers.splice(0, 1);
      }
      if (
        this.selectedSellers.length > 1 &&
        this.selectedSellers.includes("all")
      ) {
        this.selectedSellers = [];
        this.selectedSellers.push("all");
      }
      this.init({});
      this.getWidgets();
    },
    startLoading() {
      this.loadingConcretes = true;
      this.loadingServices = true;
      this.loadingWidgets = true;
    },
    /**
     * @param {string} value
     */
    copyContractProposal(value) {
      navigator.clipboard.writeText(value);
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {string} schedule_travel_uuid - O UUID da viagem
     */
    showAdjustments(schedule_travel_uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("travels/getAdjustments", {
          schedule_travel_uuid: schedule_travel_uuid,
          mix_design_mixe_id: null,
        })
        .then((response) => {
          this.$notify({ type: "success", message: response.message });
          this.modal_shown = true;
          this.$refs.modalAdjustment.openModal();
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    downloadStockExcel() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("deliveryResume/getSReportExcel", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório resumo de entregas.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
    /**
     * Download o.s de viagens
     * @param {string} schedule_travel_uuid
     */
    downloadProof(schedule_travel_uuid) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    /**
     * Download o.s de serviços.
     * @param {string} schedule_service_order_uuid
     */
    getReport(schedule_service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", schedule_service_order_uuid)
        .then(response => {
          let blob = new Blob([response], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    /**
     * Download Nf-e
     * @param {number} xml_travel_status
     * @param {string} xml_travel_key
     * @param {string} schedule_travel_uuid
     */
    async downloadDanfe(xml_travel_status, xml_travel_key, schedule_travel_uuid) {
      if (xml_travel_status !== XmlTravelEnum.NFE_COMPLETED) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml_travel_key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
  }
}
</script>

<style>

</style>
