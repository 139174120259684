<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <card>
            <div class="row">
              <div class="col-md-6 pl-2 pr-2 pb-2 ">
                <label>Período do carregamento</label>
                <InputDatePicker
                  :filterable="4"
                  size="small"
                  :default-range="range"
                  ref="datePicker"
                  @handleFilterDate="handleFilterDate"
                />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Filtro de centrais
                </label>
                <PuzlSelect
                  v-model="filter.company_plant_uuids"
                  :items="$_company_plants"
                  customKey="uuid"
                  @change="init({}), getWidgets({})"
                  multiple
                  placeholder="Centrais"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Widgets -->
      <Widgets :loadingWidgets="loadingWidgets" />
      <!-- Tabelas -->
      <div class="row pt-0 mt-n3">
        <div class="col-md-12 p-1">
          <div>
            <ul role="tablist" class="nav nav-tabs nav-justified">
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    listConcretes(),
                    init({}, true)
                  "
                  role="tab"
                  aria-selected="true"
                  aria-setsize="4"
                  aria-posinset="1"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #dcdfe6"
                  :style="
                    selectConcretes
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="selectConcretes && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        selectConcretes
                          ? '/img/icons/test-tube-white.png'
                          : '/img/icons/test-tube-dark.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    Concretos
                  </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    listServices(),
                    init({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #dcdfe6"
                  :style="
                    selectServices
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="selectServices && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        selectServices
                          ? '/img/icons/todo-list-white.png'
                          : '/img/icons/todo-list-dark.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    Serviços
                  </b>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <div class="card-body">
              <div v-if="selectConcretes && $_concretes.length">
                <a href="#"
                  class="float-right mt-2 px-1"
                  @click.prevent="downloadDriverResumePdf('driverResume/getTravelReportPdf')"
                >
                  <img
                    style="width: 25px"
                    src="/img/icons/pdf-v2.png"
                  />
                </a>
                <a href="#"
                  class="float-right mt-2"
                  @click.prevent="downloadDriverResumeExcel()"
                >
                  <img
                    style="width: 25px"
                    src="/img/icons/excel.png"
                  />
                </a>
              </div>
              <div v-if="selectServices && $_services.length">
                <a href="#"
                  class="float-right mt-2 px-1"
                  @click.prevent="downloadDriverResumePdf('driverResume/getServiceReportPdf')"
                >
                  <img
                    style="width: 25px"
                    src="/img/icons/pdf-v2.png"
                  />
                </a>
                <a href="#"
                  class="float-right mt-2"
                  @click.prevent="downloadDriverResumeExcel()"
                >
                  <img
                    style="width: 25px"
                    src="/img/icons/excel.png"
                  />
                </a>
              </div>
              <span class="px-1">
                <SelectEquipment
                  @load="handleFilterEquipment"
                  :disableButton="activateGroupingByPlant"
                />
              </span>
              <span class="px-1">
                <SelectCompanyPlant
                  @activateGroupingByPlant="activeGroupingByPlant"
                  :disableButton="hasEquipment"
                />
              </span>
              <!-- Concreto -->
              <div class="pt-0" v-if="selectConcretes">
                <TableConcretes
                  :loadingSkeleton="loadingSkeleton"
                  :activateGroupingByPlant="activateGroupingByPlant"
                  :hasEquipment="hasEquipment"
                />
                <div style="margin-top: 5px">
                  <PuzlEmptyData
                    v-if="!$_concretes.length && !loadingSkeleton"
                  />
                </div>
              </div>
              <!-- Serviços -->
              <div class="pt-0" v-if="selectServices">
                <TableServices 
                  :loadingSkeleton="loadingSkeleton"
                  :activateGroupingByPlant="activateGroupingByPlant"
                  :hasEquipment="hasEquipment"
                />
                <div style="margin-top: 5px">
                  <PuzlEmptyData
                    v-if="!$_services.length && !loadingSkeleton"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {floatToHuman} from "@/helpers";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import Widgets from "./_Widgets";
import TableConcretes from "./_TableConcretes";
import TableServices from "./_TableServices";
import SelectEquipment from "@/components/Utils/SelectEquipment";
import SelectCompanyPlant from "@/components/Utils/SelectCompanyPlant";
import moment from 'moment';

export default {
  name: "ListDriverResume",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    InputDatePicker,
    PuzlSelect,
    LoadingPagination,
    Widgets,
    TableConcretes,
    TableServices,
    SelectEquipment,
    SelectCompanyPlant,
  },
  data() {
    return {
      loadingSkeleton: false,
      floatToHuman: floatToHuman,
      loadingWidgets: false,
      loadingSkeletonService: false,
      loadingConcrete: false,
      hasEquipment: false,
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      filter: {
        range: null,
        company_plant_uuids: null,
        service_agroup_equipment: 0,
        hasEquipment: null,
        hasPlant: 0,
      },
      selectConcretes: true,
      selectServices: false,
      activateGroupingByPlant: false,
      loadingTable: false,
      initTabLoad: true,
    }
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_widgets: "driverResume/fetchWidgets",
      $_concretes: "driverResume/getConcreteItems",
      $_services: "driverResume/getServiceItems",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
    } else {
      this.$store.dispatch('plant/getPlantsSimplified');
    }
    
    this.setDefaultParams();
    this.getWidgets();
    this.init({}, true);
  },
  methods: {
    /**
     * @param {object|null} filter,
     * @param {bool|null} initTabLoad
     */
    init(filter = null, initTabLoad = null) {
      // loading apenas ao iniciar a tela ou trocar de aba.
      if (initTabLoad) {
        this.initTabLoad = false;
        this.loadingTable = true;
      }
      this.setDefaultParams();
      if (this.selectConcretes) {
        this.getConcretes(filter);
      } else {
        this.getServices(filter);
      }
    },
    /**
     * @param {object} filter
     */
    getWidgets(filter = null) {
      this.loadingWidgets = true;
      this.$store.dispatch("driverResume/getWidgets", {filter: this.filter})
        .then((response) => {
          this.loadingWidgets = false;
        });
    },
    /**
     * @param {object} filter
     */
    getConcretes(filter) {
      this.startCursor(filter);
      this.$store.dispatch("driverResume/fetchConcretesItemsPaginate", {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      })
        .then((response) => {
          this.resolveCursor(response);
          this.loadingTable = false;
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    /**
     * @param {object} filter
     */
    getServices(filter) {
      this.startCursor(filter);
      this.$store.dispatch("driverResume/fetchServicesItemsPaginate", {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      })
        .then((response) => {
          this.resolveCursor(response);
          this.loadingTable = false;
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    setDefaultParams() {
      this.filter.range = this.range;
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date()
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
      this.setDefaultParams();
      this.getWidgets();
      this.init(filter, true);
    },
    /**
     * Agrupa por equipamento.
     * @param {boolean} value
     */
    handleFilterEquipment(value) {
      this.loadingTable = true;
      this.filter.service_agroup_equipment = +value
      this.filter.hasEquipment = +value;
      this.hasEquipment = !this.hasEquipment;
      if (this.selectConcretes) {
        this.getConcretes(this.filter);
      } else {
        this.getServices(this.filter);
      }
    },
    /**
     * Agrupa por central.
     */
    activeGroupingByPlant() {
      this.loadingTable = true;
      this.activateGroupingByPlant = !this.activateGroupingByPlant;
      this.filter.hasPlant = this.activateGroupingByPlant ? 1 : 0;
      if (this.selectConcretes) {
        this.getConcretes(this.filter);
      } else {
        this.getServices(this.filter);
      }
    },
    listConcretes() {
      this.selectConcretes = !this.selectConcretes;
      this.selectServices = false;
    },
    listServices() {
      this.selectServices = !this.selectServices;
      this.selectConcretes = false;
    },
    /**
     * Download pdf relatório da listagem.
     * @param {string} actionName
     */
    async downloadDriverResumePdf(actionName) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store.dispatch(actionName, {filter: this.filter})
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório resumo de motoristas.pdf");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo pdf!",
          });
        }).finally(() => {
        loader.hide();
      });
    },
    downloadDriverResumeExcel() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("driverResume/getReportExcel", {filter: this.filter})
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório resumo de motoristas.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
        loader.hide();
      });
    },
  },
}
</script>
<style scoped>

</style>
