<template>
  <div>
    <modal :show.sync="modal" size="lg" class="user-edit-details">
      <template slot="header">
        <img
          src="/img/icons/icons8/ios/contact-card.svg"
          class="img-fluid"
          loading="lazy"
        />
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-4 m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4 m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4 m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4 m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
          v-show="!loading"
        >
          <form
            class="needs-validation pt-3"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div class="modal-body-title col-12">
              <img
                src="/img/icons/icons8/ios/info-squared.png"
                class="img-fluid"
                alt=""
                title=""
                loading="lazy"
              />
              <h5>IDENTIFICAÇÃO</h5>
              <div class="hr"></div>
            </div>

            <h3 class="col-12" style="white-space: nowrap; font-size: 16px">
              {{ userInfoFromCard.name }}
            </h3>
            <h3 class="col-12" style="white-space: nowrap; font-size: 14px">
              {{ userInfoFromCard.email }}
            </h3>
            <span
              class="col-12 mb d-block"
              style="white-space: nowrap; font-size: 16px; color: #606062"
              >{{ userInfoFromCard.phone }}</span
            >
            <div class="modal-body-title col-12">
              <img
                src="/img/icons/contact-blue.png"
                class="img-fluid"
                alt=""
                title=""
                loading="lazy"
              />
              <h5>COMPLEMENTOS</h5>
              <div class="hr"></div>
            </div>

            <!-- CPF/CNPJ -->
            <div class="app-form-group">
              <label for="cpf-cnpj" class="col-md-4"> Cpf/Cnpj </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider
                  :rules="'document|required'"
                  v-slot="{ errors }"
                >
                  <input
                    id="cpf-cnpj"
                    class="input"
                    type="text"
                    v-model="complement.document"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    :class="
                      (complement.document &&
                        complement.document.length > 0 &&
                        !verifyDocument(complement.document) &&
                        'is-invalid') ||
                      errors[0]
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                  <small
                    v-show="
                      complement.document &&
                      complement.document.length > 0 &&
                      !verifyDocument(complement.document)
                    "
                    ><i
                      class="fas fa-exclamation-triangle text-danger mr-2"
                    ></i>
                    <strong class="w-100 mt-1" style="color: #7e7e7e"
                      >CPF/CNPJ Inválido!</strong
                    >
                  </small>
                </validation-provider>
              </div>
            </div>

            <!-- CEP -->
            <div class="app-form-group">
              <label for="cep" class="col-md-4"> CEP </label>
              <div class="wrapper col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    id="cep"
                    class="input"
                    type="text"
                    v-model="complement.postal_code"
                    v-on:blur="getAddressByCode"
                    v-mask="['#####-###']"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- ENDEREÇO -->
            <div class="app-form-group">
              <label for="endereco" class="col-md-4"> endereço </label>
              <div class="wrapper col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    id="endereco"
                    class="input"
                    v-model="complement.address"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- NUMERO -->
            <div class="app-form-group">
              <label for="numero" class="col-md-4"> NÚMERO </label>
              <div class="wrapper col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    maxlength="5"
                    v-if="!without_number"
                    v-model="complement.number"
                    v-mask="'#####'"
                    inputmode="numeric"
                    class="radius-right-0 input"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                  <input
                    v-else
                    maxlength="5"
                    v-model="complement.number"
                    disabled
                    type="text"
                    inputmode="numeric"
                    class="radius-right-0 input"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                  <small class="input-append" style="text-transform: none">
                    <a
                      href="#"
                      @click.prevent="without_number = !without_number"
                    >
                      <i
                        v-if="without_number"
                        class="fa-solid fa-square-check"
                      ></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </a>
                    Sem número
                  </small>
                </validation-provider>
              </div>
            </div>

            <!-- BAIRRO -->
            <div class="app-form-group">
              <label for="bairro" class="col-md-4"> bairro </label>
              <div class="wrapper col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    id="bairro"
                    class="input"
                    v-model="complement.district"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- COMPLEMENTO -->
            <div class="app-form-group">
              <label for="complemento" class="col-md-4"> complemento </label>
              <div class="wrapper col-md-8">
                <validation-provider v-slot="{ errors }">
                  <input
                    id="complemento"
                    class="input"
                    v-model="complement.complement"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- UF -->
            <div class="app-form-group">
              <label for="letter" class="col-md-4"> UF </label>
              <div class="col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <puzl-select
                    v-model="complement.state"
                    :items="states"
                    @change="getCities()"
                    customKey="letter"
                    label="letter"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- CIDADE -->
            <div class="app-form-group">
              <label for="cidade" class="col-md-4"> cidade </label>
              <div class="wrapper col-md-8">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <puzl-select
                    v-model="complement.city"
                    :items="cities"
                    customKey="title"
                    label="title"
                    :disabled="loadingCities"
                    :loading="loadingCities"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer">
              <button class="danger button" @click="closeModal()">
                <img
                  src="/img/icons/cancel-red.png"
                  class="img-fluid"
                  loading="lazy"
                  width="16px"
                />
                Cancelar
              </button>

              <button
                class="success button"
                type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                <img
                  src="/img/icons/save-green.png"
                  class="img-fluid"
                  width="16px"
                  loading="lazy"
                />
                Salvar
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
const { formatErrorValidation } = require("@/plugins");
import { verifyDocument, viacep } from "@/helpers";

export default {
  name: "ModalUserDetail",
  components: {
    SkeletonPuzl,
    PuzlSelect,
  },
  data() {
    return {
      modal: false,
      title: "Informaçoes complementares",
      loadingSave: false,
      loading: true,
      verifyDocument: verifyDocument,
      userInfoFromCard: {
        email: "",
        id: "",
        name: "",
        phone: "",
        uuid: "",
      },
      complement: {
        document: "",
        postal_code: null,
        state: null,
        address: null,
        number: null,
        complement: null,
        district: null,
        city: null,
      },
      without_number: false,
      loadingStore: false,
      checkZipCode: null,
      loadingCities: false,
    };
  },
  computed: {
    ...mapGetters({
      states: "localizations/states",
      cities: "localizations/cities",
    }),
  },
  watch: {
    without_number: function (val) {
      if (val) {
        this.complement.number = "SN";
      } else {
        this.complement.number = "";
      }
    },
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(userInfoFromCard) {
      this.userInfoFromCard = { ...userInfoFromCard };
      this.getUser(userInfoFromCard.uuid);
      this.$store.dispatch("localizations/fetchStates");
      this.modal = true;
    },
    async getUser(userUuid) {
      this.$Progress.start();
      this.loading = true;
      this.$store.dispatch("userDetail/show", userUuid).then(({ data }) => {
        this.complement = {
          document: data && data.document ? data.document : "",
          postal_code: data && data.postal_code ? data.postal_code : "",
          state: data && data.state ? data.state : "",
          address: data && data.address ? data.address : "",
          number: data && data.number ? data.number : "",
          complement: data && data.complement ? data.complement : "",
          district: data && data.district ? data.district : "",
          city: data && data.city ? data.city : "",
        };

        if (data.city && data.city.length > 0) {
          this.getUserCities();
        }

        if (this.complement.number === "SN" || !this.complement.number) {
          this.complement.number = "SN";
          this.without_number = true;
        }
        this.$Progress.finish();
        this.loading = false;
      });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;

      this.$store
        .dispatch("userDetail/add", {
          payload: this.complement,
          user_uuid: this.userInfoFromCard.uuid,
        })
        .then((response) => {
          this.loadingStore = false;
          this.checkZipCode = null;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.modal = false;
        })
        .catch((error) => {
          if (error.status) {
            this.$Progress.finish();
            this.loadingStore = false;
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            }
          }
        });
    },
    getCities() {
      if (this.complement.state) {
        this.complement.city = "";
        this.$Progress.start();
        this.loadingCities = true;
        this.$store
          .dispatch("localizations/fetchCities", {
            uf: this.complement.state,
          })
          .then((response) => {
            this.loadingCities = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loadingCities = false;
            this.$Progress.finish();
          });
      }
    },
    getUserCities() {
      this.loadingCities = true;
      this.$store
        .dispatch("localizations/fetchCities", {
          uf: this.complement.state,
        })
        .then((response) => {
          this.loadingCities = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadingCities = false;
          this.$Progress.finish();
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.complement.postal_code.replace(/[^\d]+/g, "");
      if (postal_code) {
        viacep(postal_code)
          .then((response) => {
            if (response.data.logradouro != "") {
              this.getCities();
              this.complement.address = response.data.logradouro;
              this.complement.district = response.data.bairro;
            } else {
              this.complement.address = "";
              this.complement.number = "";
              this.complement.district = "";
            }
            this.complement.state = response.data.uf;
            this.complement.city = response.data.localidade;
            if (response.data.erro) {
              this.$notify({
                type: "warning",
                message:
                  "Dados incompletos para o CEP fornecido. Preencha manualmente.",
              });
            } else {
              this.$notify({
                type: "success",
                message: "Dados carregados com sucesso.",
              });
            }
            this.$Progress.finish();
          })
          .catch((error) => {
            this.$notify({
              type: "danger",
              message: "Não foi possível encontrar o endereço.",
            });
          });
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

// MODAL
.modal.user-edit-details {
  // SPACER
  .mb {
    margin-bottom: 32px;
  }

  // PUZL-SELECT RESET
  .vs--single > div.vs__dropdown-toggle {
    height: 32px !important;
  }

  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $muted-light !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    img {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  // MODAL-BODY
  .modal-body {
    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      img {
        width: 24px;
        height: 24px;
      }

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }

      .hr {
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        transform-origin: top;
        background-color: $muted-light;
      }
    }
    .app-form-group {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;

      @media (max-width: 991.98px) {
        flex-wrap: wrap;
      }

      label {
        @include rhythm(font-size, 16);
        margin-bottom: 0;
        font-weight: 400;

        @media (max-width: 991.98px) {
          padding-bottom: 4px;
          width: 100%;
        }
      }

      .wrapper > span {
        display: flex;
        flex-wrap: inherit;
        width: 100%;

        & .v-select {
          width: 100%;

          .vs__search {
            box-shadow: none !important;
          }
        }
      }

      .input-append {
        text-wrap: nowrap;
        padding: 4px 8px;
        line-height: 1;
        border: 1px solid $muted-light;
        background-color: white;
        @include rhythm(font-size, 14);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .input {
        display: block;
        border: none;
        @include rhythm(font-size, 14);
        box-shadow: 0 0 0 0.5px #e8e8e8, 0px 5px 10px 0px #0000000d !important;
        border-radius: 4px;
        padding: 4px 8px;
        box-sizing: border-box;
        height: 32px;
        width: 100%;

        &:disabled {
          background-color: $muted-light;
        }

        &.is-invalid {
          box-shadow: 0 0 0 1px $danger, 0px 5px 10px 0px #0000000d !important;
          animation: shake 0.3s ease-in-out;

          &:focus {
            outline: none;
          }
        }

        &.is-valid {
          box-shadow: 0 0 0 1px $success, 0px 5px 10px 0px #0000000d !important;

          &:focus {
            outline: none;
          }
        }

        &.radius-right-0 {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        /* Shake animation */
        @keyframes shake {
          0% {
            transform: translateX(0);
          }
          25% {
            transform: translateX(-5px);
          }
          50% {
            transform: translateX(5px);
          }
          75% {
            transform: translateX(-5px);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
    }
  }

  // MODAL-FOOTER
  .modal-footer {
    gap: 10px;
    padding-top: 1rem !important;

    .button {
      all: unset;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      padding: 8px 20px;
      display: flex;
      gap: 10px;
      background-color: white;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 0.3s;
      @include rhythm(font-size, 14);

      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }

      &.danger {
        border: 1px solid $danger;
        color: $danger;

        &:hover {
          background-color: $danger;
          color: white;
        }
      }

      &.success {
        border: 1px solid $success;
        color: $success;

        &:disabled {
          opacity: 0.5;

          &:hover {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        &:hover {
          background-color: $success;
          color: white;
        }
      }
    }
  }
}
</style>
