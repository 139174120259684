<template>
  <div>
    <modal size="lg"
      :show.sync="modal"
      :backgroundColor="'#F2F4F9'"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/download-dark.png" height="24" width="24">
          </div>
          <span class="text-title">
            {{ title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div>
        <div class="row container-fluid">
          <div class="col-md-3 mt-1 m-0 data-label">
            <img src="/img/icons/icons8/ios/extensions-folder.png" class="mr-2" height="23" width="23">
            Modelo 
          </div>
          <div class="col-md-9 mb-3">
            <hr class="gray-divider mt-3 mb-4">
          </div>
        </div>

        <div class="container-fluid mt-n1" style="margin-bottom: 25px;">
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Agrupamento
            </label>
            <div class="col-md-6">
              <base-input input-group-classes="input-group-sm no-box-shadow">
                <PuzlSelect
                  v-model="filter.grouping_type"
                  :items="groupingTypes"
                  :disableBoxShadow="true"
                />
              </base-input>
            </div>
          </div>
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Tipo da data
            </label>
            <div class="col-md-6">
              <base-input input-group-classes="input-group-sm no-box-shadow">
                <PuzlSelect
                  v-model="filter.date_type"
                  :items="datetypes"
                  :disableBoxShadow="true"
                />
                </base-input>
              </base-input>
            </div>
          </div>
        </div>

        <div class="row container-fluid">
          <div class="col-md-3 m-0 data-label mt-1">
            <img src="/img/icons/icons8/ios/filter_primary.png" class="mr-2" height="20" width="20">
            Filtros
          </div>
          <div class="col-md-9 mb-3">
            <hr class="gray-divider mt-3 mb-4">
          </div>
        </div>

        <div class="container-fluid mt-n1">
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Data
            </label>
            <div class="col-md-6">
              <base-input input-classes="form-control-sm">
                <InputDatePicker
                  ref="date-picker"
                  @handleFilterDate="handleFilterDate"
                  :default-range="range" />
                </base-input>
              </base-input>
            </div>
          </div>
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Central de entrada
              <span class="text-danger">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <base-input input-classes="form-control-sm">
                <PuzlSelect
                  v-model="filter.company_plant_id"
                  :items="$_companyPlants"
                  :loading="loadingPlants"
                  :disabled="loadingPlants"
                  :disableBoxShadow="true" />
                </base-input>
              </base-input>
            </div>
          </div>
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Tipo de item
            </label>
            <div class="col-md-6">
              <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    v-model="filter.product_service_hub_type_ids"
                    :items="[{id: 'all', name: 'Todos'}, ...$_product_service_hub_types]" 
                    :loading="loadingProductServiceHubType"
                    :disabled="loadingProductServiceHubType"
                    :clearable="false"
                    :disableBoxShadow="true"
                    :multiple="true"
                    @input="setAllProductServiceHubTypes"
                  />
                </base-input>
              </base-input>
            </div>
          </div>
          <div class="form-group row mt-n3">
            <label class="col-md-6 col-form-label label-item">
              Tipo de operação puzl
            </label>
            <div class="col-md-6">
              <base-input input-classes="form-control-sm">
                <PuzlSelect
                  v-model="filter.operation_source_ids"
                  :loading="loadingOperationSource"
                  :items="[{id: 'all', name: 'Todos'}, ...$_operation_sources]" 
                  :multiple="true"
                  :disableBoxShadow="true"
                  @input="setAllOperationSources"
                />
                </base-input>
              </base-input>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="d-flex pr-3" style="gap: 18px;">
            <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
              <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
              <span style="font-weight: 500;">
                Cancelar
              </span>
            </div>
            <div
              class="d-flex align-items-center py-1 px-4 button-save"
              style="gap: 5px;"
              @click.prevent="generatePdfReport(filter)"
            >
              <img src="/img/icons/icons8/ios/ok--v1_success.png" alt="save_success" width="16px">
              <span style="font-weight: 500;">
                Exportar
              </span>
            </div>
          </div>
        </div>

      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import {EntryLaunchGroupingTypeEnum} from "@/enum/EntryLaunchGroupingTypeEnum";
import {EntryLaunchGroupingDateTypeEnum} from "@/enum/EntryLaunchGroupingDateTypeEnum";

export default {
  name: "ModalReportEntryNotes",
  components: {PuzlSelect, InputDatePicker},
  data() {
    return {
      title: 'Relatório de notas de entrada',
      modal: false,
      loadingPlants: true,
      loadingOperationSource: true,
      loadingProductServiceHubType: true,
      loadingExport: false,
      filter: {
        grouping_type: EntryLaunchGroupingTypeEnum.ITEM_TYPE,
        date_type: EntryLaunchGroupingDateTypeEnum.ENTRY,
        range: null,
        company_plant_id: null,
        product_service_hub_type_ids: [],
        operation_source_ids: [],
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      groupingTypes: [
        {id: 1, name: 'Tipo de item'},
        {id: 2, name: 'Tipo de operação puzl'},
      ],
      datetypes: [
        {id: 1, name: 'Entrada'},
        {id: 2, name: 'Emissão'},
        {id: 3, name: 'Lançamento'},
      ],
    };
  },
  computed: {
    ...mapGetters({
      $_companyPlants: "plant/activeItems",
      $_operation_sources: "operationSource/fetch",
      $_product_service_hub_types: "productServiceHubType/fetch",
    }),
  },
  methods: {
    openModal() {
      this.loadData();
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.filter.range = filter;
    },
    generatePdfReport() {
      if (!this.validateFilters()) {
        return;
      }
      const loader = this.$loading.show();
      this.$store
        .dispatch("entryLaunch/entryLaunchReportPdf", this.filter).then(() => {
          this.closeModal();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
    validateFilters() {
      if (! this.filter.grouping_type) {
        this.$notify({
          type: "warning",
          message: "Selecione um agrupamento.",
        });
        return false;
      }
      if (! this.filter.date_type) {
        this.$notify({
          type: "warning",
          message: "Selecione um tipo de data.",
        });
        return false;
      }
      if (! this.filter.company_plant_id) {
        this.$notify({
          type: "warning",
          message: "Selecione uma central.",
        });
        return false;
      }
      return true;
    },
    setAllProductServiceHubTypes() {
      const index = this.filter.product_service_hub_type_ids.findIndex((item) => item == 'all');
      if (this.filter.product_service_hub_type_ids.length > 1 && index === 0) {
        return this.filter.product_service_hub_type_ids.splice(0, 1)
      }
      if (this.filter.product_service_hub_type_ids.length > 1 && this.filter.product_service_hub_type_ids.includes("all")) {
        this.filter.product_service_hub_type_ids = []
        this.filter.product_service_hub_type_ids.push("all")
      }
    },
    setAllOperationSources() {
      const index = this.filter.operation_source_ids.findIndex((item) => item == 'all');
      if (this.filter.operation_source_ids.length > 1 && index === 0) {
        return this.filter.operation_source_ids.splice(0, 1)
      }
      if (this.filter.operation_source_ids.length > 1 && this.filter.operation_source_ids.includes("all")) {
        this.filter.operation_source_ids = []
        this.filter.operation_source_ids.push("all")
      }
    },
    loadData() {
      this.filter.product_service_hub_type_ids = ['all'];
      this.filter.operation_source_ids = ['all'];

      this.loadingPlants = true;
      const localPlants = localStorage.getItem('companyPlants');
      if (localPlants) {
        const plants = JSON.parse(localPlants);
        this.$store.commit('plant/SET_ACTIVE', plants.data);
        this.loadingPlants = false;
      } else {
        this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingPlants = false;
        });
      }

      this.loadingOperationSource = true;
      this.$store.dispatch("operationSource/fetchItems").then(() => {
        this.loadingOperationSource = false;
      });

      this.loadingProductServiceHubType = true;
      this.$store.dispatch("productServiceHubType/fetch").then(() => {
        this.loadingProductServiceHubType = false;
      });
    },
  },
}
</script>

<style>
/** header modal */
.text-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/** labels dados do usuário */
.data-label {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/** Botões salvar e cancelar */
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
}
</style>
