<template>
  <div v-if="!isFinding">
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/exchange-dollar.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>

      <StockLocationForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { StockLocationUpdateType, initStockLocationUpdateType } from "../../types";
import stockLocationStore from "../../store";
import { dialogs, progress } from "../../../../../helpers";
import StockLocationForm from "../shared/StockLocationForm.vue";

const isOpen = ref(false);
const title = "Local de estoque";
/** @type {StockLocationUpdateType} */
const formData = reactive(initStockLocationUpdateType());

/** Store Getters */
const isFinding = computed(() => stockLocationStore.getIsFinding());

/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  stockLocationStore.find(id)
    .then((data) => Object.assign(formData, data))
    .finally(() => progress.finish());
}

/** Atualizar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  stockLocationStore.update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}

/**
 * @typedef {Object} StockLocationEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
</script>

<style scoped></style>
