/**
 * Type para intervalo de valores
 *
 * @typedef {Object} RangeValueType
 * @property {number|null} max - Valor máximo da operação
 * @property {number|null} min - Valor mínimo da operação
 */
export const RangeValueType = {};

/**
 * Inicializar RangeValueType
 * 
 * @returns {RangeValueType} RangeValueType
 */
export const initRangeValueType = () => {
  return {
    max: null,
    min: null,
  };
}