import { AgentType } from "./AgentType";

/**
 * Type para item da listagem
 *
 * @typedef {Object} DosageAdjustmentByIdType
 * @property {?number} binder
 * @property {?number} water
 * @property {?number} content_mortar
 * @property {?number} total_air
 * @property {?Array<AgentType>} agent
 * @property {?string} observation
 */
export const DosageAdjustmentByIdType = {};

/**
 * Inicializar DosageAdjustmentByIdType
 *
 * @returns {DosageAdjustmentByIdType}
 */
export const initDosageAdjustmentByIdType = () => ({
  binder: null,
  water: null,
  content_mortar: null,
  total_air: null,
  agent: null,
  observation: null,
});