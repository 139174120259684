<template>
  <div>
    <div v-if="isActive" class="col-md-7 ml-n3">
      <a 
        class="button-active-success"
        @click="toggleActive(false)"
        style="color: #198754;"
      >
        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
        Ativo
      </a>
    </div>
    <div v-else class="col-md-7 ml-n3">
      <a
        class="button-inactive-danger"
        @click="toggleActive(true)"
        style="color: #dc3545;"
      >
        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
        Inativo
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    initialValue: {
      type: [Boolean, Number],
      required: true
    }
  },
  data() {
    return {
      isActive: this.convertToBoolean(this.initialValue)
    }
  },
  methods: {
    convertToBoolean(value) {
      return Boolean(value)
    },
    toggleActive(value) {
      this.isActive = value
      this.$emit('update:initialValue', value ? 1 : 0)
    }
  },
  watch: {
    initialValue(newValue) {
      this.isActive = this.convertToBoolean(newValue)
    }
  }
}
</script>