<template>
  <div>
    <AppFixedPageTitle
      title="Clientes e Fornecedores"
      icon="/img/icons/icons8/ios/settings-3--v1.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="loading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
        <AppSearchBarFilterSection
            name="dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
            >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.state"
                :items="$_states"
                customKey="letter"
                label="letter"
                :disableBoxShadow="true"
                placeholder="Estado"
                :multiple="false"
                @input="getCities(filter.state)"
                class="select-xl col-md-12 px-0 new-default-black-font"
              />
            </div>  
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.city"
                :items="$_cities"
                customKey="title"
                label="title"
                :disableBoxShadow="true"
                placeholder="Cidade"
                :multiple="false"
                :loading="loadingCities"
                :disabled="loadingCities || !filter.state"
                class="select-xl col-md-12 px-0 new-default-black-font"
              />
            </div>  
          </AppSearchBarFilterSection>
        <AppSearchBarFilterSection
            name="marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
            >
              <div class="col-12 px-0 mt-1">
                <BaseButtonHoverable
                  :active="filter.is_fuel"
                  @click="handleFilterFuel"
                  size="sm"
                  type="success"
                  icon="grand-master-key"
                  platform="ios"
                  style="height: 32px;"
                >
                  Fornecedor de Combustível
                </BaseButtonHoverable>
              </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="handleModalCreate"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        placeholder="ORDENAR"
        variant="text-only"
      />
    </AppTabSelect>
    <ListEntity
      :entities="$_entities"
      :listType="listType"
      :loadingSkeleton="loadingSkeleton"
    />
    <AppViewTrigger v-if="$_entities.length" @onIntersected="listItems(true)"/>
    <ModalCreateEntity ref="ModalCreateEntity"/>
  </div>
</template>
<script>
import {
  AppSearchBar,
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger
} from "../../../../components/AppGlobal";
import ListEntity from './Shared/_List';
import { mapGetters } from "vuex";;
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { EntityListFilterType, initEntityListFilterType } from "./types";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalCreateEntity from "./Shared/_Create.vue";
import { EntityListStatusEnum } from "./Shared/Enums/EntityListStatusEnum";
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "../../../../components/Utils/BaseButtonHoverable.vue";

export default {
  name: "Index",
  mixins: [cursorPaginate],
  components: {
    ListEntity,
    LoadingPagination,
    SkeletonPuzlGrid,
    AppFixedPageTitle,
    AppPageHeader,
    AppSearchBar,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSelect,
    AppTabSelect,
    AppViewTrigger,
    ModalCreateEntity,
    AppSearchBarFilterSection,
    PuzlSelect,
    BaseButtonHoverable
  },
  computed: {
    ...mapGetters({
      $_entities: "entity/fetch",
      $_states: 'localizations/states',
      $_cities: 'localizations/cities',
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.status === null && this.filter.physical_person === null && this.filter.legal_person === null,
        },
        {
          id: EntityListStatusEnum.PHYSICAL_PERSON, 
          name: 'P. Física', 
          selected: this.filter.physical_person === EntityListStatusEnum.PHYSICAL_PERSON,
        },
        {
          id: EntityListStatusEnum.LEGAL_PERSON, 
          name: 'P. Jurídica', 
          selected: this.filter.legal_person === EntityListStatusEnum.LEGAL_PERSON,
        },
        {
          id: EntityListStatusEnum.INACTIVE, 
          name: 'Bloqueados', 
          selected: this.filter.status === EntityListStatusEnum.INACTIVE,
        },
      ];
    },
  },
  data() {
    return {
      loading: true,
      loadingSkeleton: false,
      searchBarFilter: initSearchBarFilterType(),
      filter: initEntityListFilterType(),
      EntityListStatusEnum: EntityListStatusEnum,
      listType: 'cards',
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [{ 
              column: "entities.id", 
              is_desc: false,
            }],
          },
          {
            id: 1,
            name: "A-Z",
            selected_name: "A-Z",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "entities.entity_name", 
              is_desc: false,
            }],
          },
          {
            id: 2,
            name: "Z-A",
            selected_name: "Z-A",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "entities.entity_name", 
              is_desc: true,
            }],
          },
          {
            id: 3,
            name: "REGISTRO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "entities.created_at", 
              is_desc: false,
            }],
          },
          {
            id: 4,
            name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "entities.created_at", 
              is_desc: true,
            }],
          },
        ],
        selected: 0,
      },
      loadingStates: true,
      loadingCities: false,
    }
  },
  methods: {
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType()
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {EntityListFilterType}
     */
    defaultFilter() {
      return {
        ...initEntityListFilterType(),
      };
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.prepareFilter();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("entity/fetchItemsNew", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
          this.loading = false;
        });
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      this.orderBy.selected = 0;
      if (isRefreshList) {
        this.listItems();
      }
    },
    handleModalCreate() {
      this.$refs.ModalCreateEntity.openModal(true);
    },
    onTabSelectItemClick(selectedItem) {
      switch (selectedItem.id) {
        case EntityListStatusEnum.PHYSICAL_PERSON:
          this.filter.physical_person = EntityListStatusEnum.PHYSICAL_PERSON;
          this.filter.legal_person = null;
          this.filter.status = null;
        break;
        case EntityListStatusEnum.LEGAL_PERSON:
          this.filter.legal_person = EntityListStatusEnum.LEGAL_PERSON;
          this.filter.physical_person = null;
          this.filter.status = null;
        break;
        case EntityListStatusEnum.INACTIVE:
          this.filter.status = EntityListStatusEnum.INACTIVE;
          this.filter.physical_person = null;
          this.filter.legal_person = null;
        break;
        default:
          this.filter.status = null;
          this.filter.physical_person = null;
          this.filter.legal_person = null;
        break;
      }
      this.listItems();
    },
    /**
     * @param {string} type
     */
    handleViewTypeChange(type) {
      this.listType = type;
    },
    getStates() {
      this.$store.dispatch('localizations/fetchStates').then(() => this.loadingStates = false);
    },
    /**
     * @param {string} state
     */
    getCities(state) {
      this.loadingCities = true;
      this.filter.city = '';
      this.$store.dispatch('localizations/fetchCities', {uf: state})
        .finally(() => {
          this.loadingCities = false;
        });
    },
    handleFilterFuel() {
      this.filter.is_fuel = this.filter.is_fuel === null ? true : null;
    }
  },
  created(){
  },
  mounted() {
    this.clearFilter();
    this.getStates();
  }
};

</script>
<style></style>
