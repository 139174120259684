<template>
  <div id="login" style="background: white !important;">
    <!--    <div class="footer-custom" style="background: white">-->
    <!--      <p class="pl-2 numeric"> © {{ year }} Puzl Place. Todos os direitos reservados</p>-->
    <!--    </div>-->
    <div class="header py-2">

    </div>
    <div class="container pt-lg-5 mt-4">
      <div class="row d-flex justify-content-center align-items-center ">
        <div class="col-md-12 col-sm-12 col-lg-6 col-xl-5  mt-2 ">
          <div class="text-center">
              <span>
                   <img id="front-logo" src="/img/puzzle-work.jpg"
                        class="img-fluid" alt="Sample image">
              </span>
          </div>
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1 login-form mt-2">
          <div class="card" style="margin-top: 40px">
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="text-center">
                    <span>
                  <img style="width: 160px;margin-bottom: 30px"
                       src="/img/brand/puzl-mix.jpeg"
                       class="img-fluid text-center" alt="Sample image">
                    </span>
                </div>

                <!-- Email input -->
                <!--              <div class="form-outline mb-4">-->
                <!--                <label class="form-label">Endereço de e-mail</label>-->
                <!--                <input type="email" class="form-control"-->
                <!--                       placeholder="Digite um e-mail válido"/>-->
                <!--              </div>-->
                <div class="mb-3">
                  <label class="form-label">Endereço de e-mail</label>
                  <base-input
                    v-model="form.email"
                    autocomplete="autocomplete"
                    name="Email"
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    placeholder="Digite um e-mail válido"
                  >
                  </base-input>
                </div>

                <!-- Password input -->
                <!--              <div class="form-outline mb-3">-->
                <!--                <label class="form-label">Senha</label>-->
                <!--                <input type="password" class="form-control"-->
                <!--                       placeholder="Digite a sua senha"/>-->
                <!--              </div>-->
                <div class="mb-3">
                  <label class="form-label">Senha</label>
                  <base-input
                    v-model="form.password"
                    autocomplete="autocomplete"
                    name="Password"
                    type="password"
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="Digite a sua senha"
                  >
                  </base-input>
                  <div class="text-left">
                  <span class="small text-danger">
                    {{ message }}
                  </span>
                  </div>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <!-- Checkbox -->
                  <div class="form-check mb-0">
                    <input v-model="form.remember" class="form-check-input me-2" type="checkbox" value=""
                           id="form2Example3"/>
                    <label class="form-check-label">
                      Lembrar-me
                    </label>
                  </div>
                  <router-link :to="{
                    path:'/forgot-password',
                    name:'forgot.password',}">
                    <a href="#!" class="text-body">Esqueceu a senha?</a>
                  </router-link>
                </div>

                <div class="text-left mt-4 pt-2">
                  <base-button type="primary" :loading="loading" native-type="submit"
                               :disabled="loading">
                    Entrar
                  </base-button>
                  <!--                <p class="small fw-bold mt-2 pt-1 mb-0">Não possui uma conta? <a href="#!"-->
                  <!--                                                                                  class="link-danger">Register</a></p>-->
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Websocket from '@/helpers/ws';
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: false,
      },
      year: new Date().getFullYear(),
      loading: false,
      message: '',
    };
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store.dispatch('auth/login', this.form)
        .then(response => {
          this.$user = response.user
          Websocket.connect(this.$user.id)
          this.loading = false
          /* Caso seja motorista redirecionamos para tela de acesso do motorista. */
          if (response.user.internal_type === 0 && response.user.is_driver === true) {
            return this.$router.push('/driver/in-course')
          }
          this.$router.push('dashboard')

          // Carrega centrais no login para garantir acesso global rápido sem depender de novas consultas.
          this.$store.dispatch("plant/getPlantsSimplified").then((res) => {
            localStorage.setItem('companyPlants', JSON.stringify(res)); 
          });
        }).catch(response => {
          // if (response.request.status === 429) {
          //   this.message = "Você realizou muitas tentativas de login em um curto período de tempo. Por favor, tente novamente mais tarde."
          // }
          this.message = response.data.message
        }).finally(() => {
          this.loading = false
        });
    },
  }
};
</script>
<style>
.footer-custom {
  position: fixed;
  bottom: 0px !important;
}

#login {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden;
}

::placeholder {
  font-size: 0.9rem;
}

/*.divider:after,*/
/*.divider:before {*/
/*  content: "";*/
/*  flex: 1;*/
/*  height: 1px;*/
/*  background: #eee;*/
/*}*/

.h-custom {
  max-height: 896px !important;
  /*margin-top: 25%;*/
}

@media only screen and (max-width: 600px) {
  #front-logo {
    width: 200px !important;
    /*margin-bottom: 0px;*/
    /*display: none;*/
  }
}
@media only screen and (min-width: 600px) {
  #front-logo {
    width: 70vw !important;
    /*margin-bottom: 0px;*/
    /*display: none;*/
  }
}
</style>
