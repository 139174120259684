<template>
  <div class="row card-wrapper" v-if="requests.length">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in requests" :key="index">
      <div class="card main-card">
        <div class="pt-3 px-3 pb-0 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <base-dropdown menuOnRight class="d-flex justify-content-center dropdown-status">
                <div slot="title-container" class="dropdown-toggle rounded">
                  <div class="align-self-center card-status mr-3 mb-1 pointer d-flex align-items-center"
                    :class="getStatusClass(item.order_status)">
                    <img class="mr-1" ref="icon" height="17" :src="getStatusIcon(item.order_status)" />
                    <span style="font-size: 13px; margin: 3px 3px 0 0;">{{ getStatusText(item.order_status) }}</span>
                    <img class="mr-1" ref="icon" height="15" :src="getArrowIcon(item.order_status)" />
                  </div>
                </div>
                <div>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.OPEN"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.COMPLETED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/double-tick-primary.png" width="18" alt="">
                    Concluir
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.COMPLETED || item.order_status == PurchaseOrderStatusEnum.CANCELED"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.OPEN)" class="dropdown-item d-flex"
                    style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/circle-left_success.png" width="18" alt="">
                    Retomar
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.OPEN)" class="dropdown-item d-flex"
                    style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/thumb-up_success.png" width="18" alt="">
                    Aprovar
                  </a>
                  <div v-if="item.order_status == PurchaseOrderStatusEnum.OPEN"
                    style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.OPEN || item.order_status == PurchaseOrderStatusEnum.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.CANCELED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="/img/icons/icons8/ios/unavailable_danger.png">
                    Cancelar
                  </a>
                </div>
              </base-dropdown>
              <div style="margin-top: 3px;">
                <!-- ICONES -->
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div class="mr-2"
                    @click="showButtonsModal = false; handleShowModal({ ref: 'modalListItemsApproval', data: item })">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                        </div>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">
                {{ item.order_code || item.code }}
              </span>
            </div>
            <div class="d-flex">
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ item.deadline | parseDate("DD MMM YYYY") }}
              </span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ item.purchase_category_description }}
              </span>
            </div>
            <div>
              <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">
                {{ item.entity_name }}
              </span>
            </div>
          </div>
          <!-- Menu engrenagem -->
          <div v-if="item.order_status == PurchaseOrderStatusEnum.COMPLETED || item.order_status == PurchaseOrderStatusEnum.OPEN">
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a @click="downloadOrderResumePdf(item.id)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img src="/img/icons/icons8/ios/print-primary.png" width="22" alt="">
                  Imprimir
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div class="dropdown-divider p-0 mx-3" />
        <div class="mx-3">
          <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
            VALOR TOTAL
          </span>
        </div>
        <div class="mx-3 mb-3">
          <span style="font-size: 15px ;font-weight: 500; color: #2B2D32;">
            {{ item.total_value | currency() }}
          </span>
        </div>
      </div>
    </div>
    <ModalListItemsApproval ref="modalListItemsApproval" />
  </div>
</template>
<script>

import ModalListItemsApproval from "./Shared/_ModalListItemsApproval";
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";

export default {
  name: "PurchasesOrderCards",
  components: {
    ModalListItemsApproval
  },
  props: {
    requests: Array,
  },
  data() {
    return {
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    updateStatusOrder(purchaseOrder, status) {
      const permissions = this.checkPermission(purchaseOrder, status);

      if (!permissions.hasPermission) {
        this.$notify({ type: 'warning', message: permissions.text });
        return;
      }
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: `Tem certeza que deseja ${permissions.action} essa requisição?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            const purchaseData = {
              id: purchaseOrder.id,
              status,
              code: purchaseOrder.order_code
            };
            this.$store.dispatch('purchaseOrder/update', purchaseData)
              .then(() => {
                this.$emit('fetch');
                loader.hide();
                this.$notify({ type: 'success', message: 'Status atualizado' });
              })
              .catch(() => {
                loader.hide();
                this.$notify({ type: 'error', message: 'Erro ao atualizar o status!' });
              });
          }
        });
    },
    checkPermission(purchaseOrder, status) {
      if (purchaseOrder.limit_order_approval_value && purchaseOrder.total_value > purchaseOrder.max_approval_value) {
        this.$notify({ type: 'warning', message: 'Usuário não possui permissão para aprovar esse valor' });
        return 
      }
      switch (status) {
        case this.PurchaseOrderStatusEnum.COMPLETED:
          return {
            hasPermission: purchaseOrder.purchase_order_complete && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para concluir!",
            action: "Concluir"
          };
        case this.PurchaseOrderStatusEnum.OPEN:
          if (purchaseOrder.order_status === PurchaseOrderStatusEnum.COMPLETED ||
            purchaseOrder.order_status === PurchaseOrderStatusEnum.CANCELED) {
            return {
              hasPermission: purchaseOrder.purchase_order_resume && purchaseOrder.can_approve,
              text: "Usuário não tem permissão para retomar!",
              action: "Retomar"
            };
          }
          return {
            hasPermission: purchaseOrder.purchase_order_approve && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para aprovar!",
            action: "Aprovar"
          };
        case this.PurchaseOrderStatusEnum.CANCELED:
          return {
            hasPermission: purchaseOrder.purchase_order_cancel && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para cancelar!",
            action: "Cancelar"
          };
        default:
          return {
            hasPermission: false,
            text: "Ação inválida!",
            action: ""
          };
      }
    },
    getStatusText(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "Pendente";
        case this.PurchaseOrderStatusEnum.OPEN: return "Aberta";
        case this.PurchaseOrderStatusEnum.DENIED: return "Negado";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "Concluída";
        default: return "Cancelado";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "pending-status";
        case this.PurchaseOrderStatusEnum.OPEN: return "approved-status";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "completed-status";
        default: return "denied-status";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
        case this.PurchaseOrderStatusEnum.OPEN: return "/img/icons/icons8/ios/shopping-cart.png";
        case this.PurchaseOrderStatusEnum.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "/img/icons/icons8/ios/double-tick-primary.png";
        default: return "/img/icons/icons8/ios/cancel_danger.png";
      }
    },
    getArrowIcon(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "/img/icons/icons8/ios/arrow_down_warning.png";
        case this.PurchaseOrderStatusEnum.OPEN: return "/img/icons/icons8/ios/arrow_down_success.png";
        case this.PurchaseOrderStatusEnum.DENIED: return "/img/icons/icons8/ios/arrow_down_danger.png";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "/img/icons/icons8/ios/arrow_down_primary.png";
        default: return "/img/icons/icons8/ios/arrow_down_danger.png";
      }
    },
    downloadOrderResumePdf(orderId) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("purchaseOrder/getReportPdf", orderId)
        .then((res) => window.open(res.data, '_blank'))
        .finally(() => loader.hide());
    },
  }
};
</script>

<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.3rem 0.8rem;
  margin-top: 0.1rem;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.completed-status {
  background-color: #f2f4f9;
  color: #1A70B7;
}

.dropdown-status .dropdown-toggle::after {
  display: none;
}
</style>
