<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/ratings-white.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="contract_proposal_projection.company_plant_id"
                      :items="$_plants"
                      :loading="loadingPlants"
                      :disabled="loadingPlants"
                    />
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Mês
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <PuzlSelect
                    v-model="contract_proposal_projection.projection_month"
                    :items="MonthsEnum"
                    :loading="loadingPlants"
                    :disabled="loadingPlants"
                  />
                </validation-provider>
                <validation-provider rules="required">
                  <div class="pb-1 col-form-label form-control-label">
                    Ano
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="contract_proposal_projection.projection_year"
                      class="form-control form-control-sm"
                      input-group-classes="input-group-sm"
                      v-mask="['####']"
                    />
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Previsto
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="contract_proposal_projection.volume_prediction"
                      class="form-control form-control-sm"
                      input-group-classes="input-group-sm"
                      v-mask="[
                        '#',
                        '##',
                        '#,#',
                        '##,#',
                        '###,#',
                        '#.###,#',
                        '##.###,#',
                      ]"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        <span style="text-transform: none">m</span><sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :loading="loadingStore"
                v-bind:disabled="invalid"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

const {formatErrorValidation} = require("@/plugins");
import {MonthsEnum} from "@/enum/MonthsEnum";

export default {
  name: "ModalCreateProjection",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      title: "Nova Projeção",
      modal: {
        create: false,
      },
      loadingPlants: true,
      contract_proposal_projection: {
        contract_proposal_id: null,
        company_plant_id: null,
        projection_month: null,
        projection_year: null,
        volume_prediction: null,
      },
      loadingStore: false,
      MonthsEnum: MonthsEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(contractProposalId, companyPlantId, prediction = null, month = null) {
      this.contract_proposal_projection = {
        contract_proposal_id: contractProposalId,
        company_plant_id: companyPlantId,
        projection_month: month,
        projection_year: new Date().getFullYear(),
        volume_prediction: prediction ? prediction.toFixed(1).toString() : null,
      }
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch(
          "contractProposalProjection/add",
          this.contract_proposal_projection
        )
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit('update')
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingPlants = false;
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingPlants = false;
      });
    }
  },
};
</script>
