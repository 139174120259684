<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <card>
            <div class="row">
              <div class="col-md-6 pl-2 pr-2 pb-2 ">
                <label>Período do carregamento</label>
                <InputDatePicker
                  :filterable="4"
                  size="small"
                  :default-range="range"
                  ref="datePicker"
                  @handleFilterDate="handleFilterDate"
                />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Filtro de centrais
                </label>
                  <puzl-select
                    v-model="filter.company_plant_uuids"
                    :items="$_company_plants"
                    customKey="uuid"
                    @change="init"
                    multiple
                    placeholder="Centrais"
                  />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Contrato
                </label>
                  <puzl-select
                    v-model="filter.contract_proposal_id"
                    :items="$_contract_proposals"
                    @change="init"
                    :loading="loadingContractProposals"
                    placeholder="Contrato"
                  />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Traço
                </label>
                  <puzl-select
                    v-model="filter.mix_code"
                    :items="$_mix_codes"
                    @change="init"
                    :loading="loadingMixCodes"
                    placeholder="Traço"
                  />
              </div>
            </div>
          </card>
        </div>
      </div>
      <section >
        <div v-if="!loadingWidgets" class="row align-items-center mt-n4">
          <div class="col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p style="font-family: FreeMono, monospace;"
                       class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      VOLUME TOTAL</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape bg-success rounded-circle float-right"><i
                      class="text-white fa-solid fa-chart-line"></i></div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{ Number($_widgets.total_volume).toFixed(1).toString().replace('.', ',') }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-9" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      VOLUME MÉDIO POR BT</small></p>
                  </div>
                  <div class="col-3" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape bg-warning rounded-circle float-right"><i
                      class="text-white fa-solid fa-gauge-simple"></i></div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{ Number($_widgets.avg_volume).toFixed(1).toString().replace('.', ',') }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      Densidade média</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape rounded-circle float-right bg-primary"
                    ><i class="text-white fa-solid fa-scale-balanced"></i></div>
                  </div>
                  <div class="col-md-12 " style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{ Math.round(($_widgets.total_dry_weight_per_volume / $_widgets.total_travels) || 0) }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      Aglomerante</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape rounded-circle float-right"
                         style="background-color: #7e7e7e">
                      <i class="text-white fa-solid fa-ellipsis"></i>
                    </div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{ Math.round($_widgets.avg_binder) }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row align-items-center">
          <div v-for="item in 4" class="col-md-3 col-sm-6 p-1">
            <div class="mb-3 card" style="height: 80px">
              <div class="card-body">
                <div class="row">
                  <div class="col-9" style="margin-top: -10px">
                    <skeleton-puzl type="small"/>
                  </div>
                  <div class="col-2 text-right float-right" style="margin-top: -15px">
                    <skeleton-puzl type="circle"/>
                  </div>
                  <div class="col-5" style="margin-top: -22px">
                    <skeleton-puzl type="small"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="row pt-0 mt-n2">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div >
                <a href="#"
                  class="float-right mt-2"
                  @click.prevent="downloadDriverResumeExcel()"
                >
                  <img 
                    style="width: 25px"
                    src="/img/icons/excel.png" />
                </a>
              </div>
              <select-location @load="handleFilterDosageLocation" class="mb-3 "/>
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center">Categoria</th>
                      <th class="text-left">MCC</th>
                      <th v-show="!filter.dosage_location_agroup" class="text-left" style="text-transform: none">
                        kg/m<sup>3</sup>
                      </th>
                      <th v-show="filter.dosage_location_agroup" class="text-center">Local</th>
                      <th class="text-center">Teórico</th>
                      <th class="text-center">Real</th>
                      <th v-show="!filter.dosage_location_agroup" class="text-center">Real (peso seco)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 5" v-show="loadingSkeleton">
                      <th colspan="6">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_items.data" v-show="!loadingSkeleton && $_items.data.length">
                      <td class="text-center">{{ item.category }}</td>
                      <td class="text-left">
                        <b>{{ item.product_name }}</b> {{ item.name }}
                      </td>
                      <td v-show="!filter.dosage_location_agroup" class="text-center">
                        {{ Math.round(item.avg_kg_m3).toLocaleString('pt-BR') }}
                      </td>
                      <td v-show="filter.dosage_location_agroup" class="text-center">
                        {{ item.dosage_location_name }}
                      </td>
                      <td class="text-center">
                        {{ Math.round(item.theorical_individual).toLocaleString('pt-BR') }}
                      </td>
                      <td class="text-center">
                        {{ Math.round(item.real_individual).toLocaleString('pt-BR') }}
                      </td>
                      <td v-show="!filter.dosage_location_agroup" class="text-center">
                        {{ Math.round(item.outgoing_stock).toLocaleString('pt-BR') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <puzl-empty-data v-show="$_items.data && !$_items.data.length && !loadingSkeleton"/>
                </div>
                <div class="col-md-12">
                  <pagination @navegate="navegate" :source="$_items"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from '@/components/Utils/Pagination'
import SelectConcretePiece from "../Shared/_SelectConcretePiece"
import SelectLocation from "../Shared/_SelectLocation"
import SelectEquipment from "../Shared/_SelectEquipment"
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment';

export default {
  name: "ListResume",
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    Pagination,
    SelectConcretePiece,
    SelectLocation,
    SelectEquipment,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingWidgets: false,
      loadingSkeletonByCode: false,
      loadingSkeletonByContractProposal: false,
      loadingSkeletonService: false,
      loadingMoldingResume: false,
      loadingMixCodes: false,
      loadingContractProposals: false,
      hasEquipment: false,
      sourceMoldingResume: null,
      sourceService: null,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      params: {
        page: 1,
        per_page: 5,
      },
      filter: {
        company_plant_uuids: null,
        concrete_piece_agroup_customer: 0,
        concrete_piece_agroup_code: 0,
        service_agroup_equipment: 0,
        dosage_location_agroup: 0,
        contract_proposal_id: null,
        mix_code: null,
        hasEquipment: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items: "operationalStockResume/fetch",
      $_items_by_code: "operationalStockResume/fetchByCode",
      $_items_by_contract_proposal: "operationalStockResume/fetchByContractProposal",
      $_widgets: "operationalStockResume/fetchWidgets",
      $_mix_codes: "travels/getMixCodes",
      $_contract_proposals: "travels/getContractProposals",
      $_molding_resume: "moldingResume/fetch",
      $_services: "operationalStockResume/getServices",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
    } else {
      this.$store.dispatch('plant/getPlantsSimplified');
    }

    this.load()
  },
  methods: {
    handleCustomerFilterConcretePiece(value) {
      this.filter.concrete_piece_agroup_customer = +value
      this.getByProposal()
    },
    handleFilterDosageLocation(value) {
      this.filter.dosage_location_agroup = +value
      this.getResume()
    },
    handleCodeFilterConcretePiece(value) {
      this.filter.concrete_piece_agroup_code = +value
      this.getByCode()
    },
    handleServiceEquipmentFilter(value) {
      this.filter.service_agroup_equipment = +value
      this.getServices()
    },
    handleFilterEquipment(value) {
      this.filter.hasEquipment = +value
      this.hasEquipment = !this.hasEquipment
      this.getMoldingResune()
    },
    navegateMoldingResume(page) {
      this.params.page = page
      this.getMoldingResune()
    },
    navegateService(page) {
      this.params.page = page
      this.getServices()
    },
    navegateByProposal(page) {
      this.params.page = page
      this.getByProposal()
    },
    navegateByCode(page) {
      this.params.page = page
      this.getByCode()
    },
    navegate(page) {
      this.params.page = page
      this.getResume()
    },
    getWidgets() {
      this.loadingWidgets = true
      this.$store.dispatch("operationalStockResume/getWidgets", {filter: this.filter})
        .then(() => this.loadingWidgets = false)
    },
    load() {
      this.init()
    },
    getByProposal() {
      this.setDefaultParams()
      this.loadingSkeletonByContractProposal = true
      this.$store.dispatch("operationalStockResume/fetchItemsByContractProposal", this.params)
        .then(() => this.loadingSkeletonByContractProposal = false)
    },
    getByCode() {
      this.setDefaultParams()
      this.loadingSkeletonByCode = true
      this.$store.dispatch("operationalStockResume/fetchItemsByCode", this.params)
        .then(() => this.loadingSkeletonByCode = false)
    },
    setDefaultParams() {
      this.filter.range = this.range
      this.params.filter = this.filter;
    },
    getResume() {
      this.setDefaultParams()
      this.loadingSkeleton = true
      this.$store.dispatch("operationalStockResume/fetchItems", this.params)
        .then(() => this.loadingSkeleton = false)
    },
    getMoldingResune() {
      this.loadingMoldingResume = true
      this.$store.dispatch("moldingResume/fetchItemsPaginate", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.sourceMoldingResume = response;
          this.loadingMoldingResume = false;
        })
        .catch(() => {
          this.loadingMoldingResume = false;
        });
    },
    getServices() {
      this.setDefaultParams()
      this.loadingSkeletonService = true
      this.$store.dispatch("operationalStockResume/getServices", this.params)
        .then((response) => {
           this.sourceService = response;
           this.loadingSkeletonService = false
        })
    },
    init() {
      this.setDefaultParams()
      this.getResume()
      this.getWidgets()
      // this.getByProposal()
      // this.getByCode()
      this.getFilters()
      // this.getMoldingResune()
      // this.getServices()
    },
    getFilters() {
      this.loadingMixCodes = true
      this.loadingContractProposals = true
      this.$store.dispatch('travels/getMixCode', this.params)
        .then(() => this.loadingMixCodes = false)
      this.$store.dispatch('travels/getContractProposals', this.params)
        .then(() => this.loadingContractProposals = false)
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(),
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
      this.load({});
    },
    decimals(category, value) {
      if (value == 0) {
        return 0
      }
      value = parseFloat(value)
      switch (category) {
        case 2:
        case 3:
        case 6:
          return parseInt(value)
        case 1:
          return value.toFixed(1)
        default:
          return value.toFixed(2)
      }
    },
    downloadDriverResumeExcel() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("operationalStockResume/getReportExcel", { filter: this.filter })
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao processar arquivo excel!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
  },
}
</script>
