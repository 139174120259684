<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row pt-n1">
        <div class="col-md-12 p-1">
          <div v-if="$route.params.contract_uuid">
            <ContractProposalNavigation
              :contractUuid="$route.params.contract_uuid"
              :activeSalesResume="true"
            />
          </div>
          <MultiFilter
            ref="multi-filter"
            :filter="filter"
            :status="multi_filter"
            @fetch="load"
          >
            <template slot="beforeMainBarTop">
              <div class="row">
                <div class="col-md-4 pl-2 pr-2 pb-2 ">
                  <label>Data de Entrega</label>
                  <InputDatePicker
                    :filterable="4"
                    ref="date-picker"
                    :default-range="range"
                    size="small"
                    @handleFilterDate="handleFilterDate($event, true)"
                  />
                </div>
                <div class="col-md-4 pl-2 pr-2 pb-2">
                  <label>Filtro de centrais</label>
                  <PuzlSelect
                    v-model="filter.company_plants"
                    :items="$_plants"
                    :loading="loadingCompanyPlants"
                    customKey="id"
                    :multiple="true"
                    placeholder="Centrais"
                    @input="handleFilterCompanyPlants"
                  />
                </div>
                <div class="col-md-4 pl-2 pr-2 pb-2">
                  <label>Filtro de Vendedores</label>
                  <PuzlSelect
                    v-model="filter.sellers_uuid"
                    :items="$_sellers"
                    :loading="loadingSeller"
                    customKey="uuid"
                    multiple
                    placeholder="Vendedor"
                    @input="handleFilterSellers"
                  />
                </div>
              </div>
            </template>
          </MultiFilter>
        </div>
      </div>
      <div class="card-row row">
        <div class="col-md-3 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <p
                    class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                    style="font-family: FreeMono, monospace;"
                  >
                     <small class="card-text font-weight-bold">Volume de concreto</small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-primary rounded-circle float-right"><i
                    class="text-white fa fa-flask"></i></div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ Number($_widgets.formulation_volume).toFixed(1).toString().replace('.', ',') }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class=" card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">
                    <small class="card-text font-weight-bold">Volume de serviços</small>
                  </p>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-info rounded-circle float-right"><i
                    class="text-white fa fa-tasks"></i></div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ Number($_widgets.service_volume).toFixed(1).toString().replace('.', ',') }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class=" card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">
                    <small class="card-text font-weight-bold">Total</small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape rounded-circle float-right bg-success">
                    <i class="text-white fa-solid fa-coins"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h2 v-if="$_widgets.total_value" class="font-weight-semibold mb-0 pl-2 ">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ $_widgets.total_value | currency }}
                    </div>
                  </h2>
                  <h2 v-else class="font-weight-semibold mb-0 pl-2">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ 0 | currency }}
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class=" card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">
                    <small class="card-text font-weight-bold">
                      Pendente
                    </small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape rounded-circle float-right bg-warning"
                       style="background-color: #7e7e7e">
                    <i class="text-white fa-solid fa-coins"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h2 v-if="$_widgets.pending_value" class="font-weight-semibold mb-0 pl-2 ">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ $_widgets.pending_value | currency }}
                    </div>
                  </h2>
                  <h2 v-else class="font-weight-semibold mb-0 pl-2 ">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric" style="font-size: 13px">
                      {{ 0 | currency }}
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid" style="margin-top: -25px;">
      <div class="row" style="margin-bottom: -20px;">
        <div class="col-md-12 p-1">
          <ul role="tablist" class="nav nav-tabs nav-justified">
            <li role="presentation" class="nav-item">
              <a
                @click.prevent="listInvoices(), init()"
                role="tab"
                aria-selected="true"
                aria-setsize="4"
                aria-posinset="1"
                href="#"
                target="_self"
                class="nav-link"
                style="padding: 8px 0; border: 1px solid #DCDFE6"
                :style="
                  selectInvoices
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                "
                :class="selectInvoices && 'active'"
              >
                <b class="font-weight-500">
                  <img
                    :src="
                      selectInvoices
                        ? '/img/icons/check-white.png'
                        : '/img/icons/check-black.png'
                    "
                    width="23px"
                    height="23px"
                    class="mr-1"
                  />
                  Faturas
                </b>
              </a>
            </li>
            <li role="presentation" class="nav-item">
              <a
                @click.prevent="listConcretes(), init()"
                role="tab"
                aria-selected="true"
                aria-setsize="4"
                aria-posinset="1"
                href="#"
                target="_self"
                class="nav-link"
                style="padding: 8px 0; border: 1px solid #DCDFE6"
                :style="
                  selectConcretes
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                "
                :class="selectConcretes && 'active'"
              >
                <b class="font-weight-500">
                  <img
                    :src="
                      selectConcretes
                        ? '/img/icons/test-tube-white.png'
                        : '/img/icons/test-tube-dark.png'
                    "
                    width="22px"
                    height="22px"
                    class="mr-1"
                  />
                  Concreto
                </b>
              </a>
            </li>
            <li role="presentation" class="nav-item">
              <a
                @click.prevent="listServices(), init()"
                role="tab"
                tabindex="-1"
                aria-selected="false"
                aria-setsize="4"
                aria-posinset="2"
                href="#"
                target="_self"
                class="nav-link"
                style="padding: 8px 0; border: 1px solid #DCDFE6"
                :style="
                  selectServices
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                "
                :class="selectServices && 'active'"
              >
                <b class="font-weight-500">
                  <img
                    :src="
                      selectServices
                        ? '/img/icons/todo-list-white.png'
                        : '/img/icons/todo-list-dark.png'
                    "
                    width="22px"
                    height="22px"
                    class="mr-1"
                  />
                  Serviços
                </b>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <br><br>

      <!-- Faturas -->
      <div class="row card-row" v-if="selectInvoices">
        <div class="col-md-12 p-1">
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center default table-column-light-gray text-dark">
                        Competência
                      </th>
                      <th class="default text-center table-column-light-gray text-dark">Fatura</th>
                      <th class="default text-center text-truncate table-column-light-gray text-dark">NFs-e</th>
                      <th class="default table-column-light-gray text-dark">Status</th>
                      <th class="default table-column-light-gray text-dark">Contrato</th>
                      <th class="default table-column-light-gray text-dark">Cliente</th>
                      <th class="table-column-light-gray text-dark">Valor bruto</th>
                      <th class="table-column-light-gray text-dark table-column-light-gray text-dark">Valor pendente</th>
                      <th class="text-center default table-column-light-gray text-dark">Arquivos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 10" v-show="loadingInvoices">
                      <th colspan="12">
                        <SkeletonPuzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_invoices" v-show="!loadingInvoices">
                      <td class="numeric default text-center text-truncate">
                        <div class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.date_at | parseDate('DD/MMM') }}
                        </div>
                      </td>
                      <td class="numeric default text-center text-truncate">
                        <div class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.id }}
                        </div>
                      </td>
                      <td v-if="item.nfse" class="numeric default text-primary text-truncate text-center">
                        <div
                          class="text-truncate numeric font-weight-600 table-content-font-size"
                          style="width: 50px !important;"
                        >
                          {{item.nfse ? JSON.parse(item.nfse).nfse_number : '-'}}
                        </div>
                      </td>
                      <td v-else class="numeric default text-center">
                        -
                      </td>
                      <td class="font-weight-500 " :class="'text-' + class_status[item.payment_status]">
                        <a style="border: 1px solid black" href="#"
                           @click.prevent="handleOpenModalPaymentTerms(item)"
                           class="avatar avatar-sm-3 mr-2 bg-white pointer">
                          <i class="fas fa-credit-card text-default" style="font-size: 0.6rem"></i>
                        </a> {{ status[item.payment_status] }}
                      </td>
                      <td class="font-weight-500 table-content-font-size text-dark">
                        <el-popover class="p-0" placement="right" trigger="click">
                          <span>
                            <span class="text-center">{{ item.user_name }}</span>
                          </span>
                          <span slot="reference">
                            <i class="fa-solid fa-circle-user text-success" style="cursor: pointer;"/>
                          </span>
                        </el-popover>
                        {{ item.code }}
                      </td>
                      <td class="text-truncate">
                        <div
                          class="text-truncate font-weight-normal table-content-font-size text-dark"
                          style="width: 25vw">
                          {{ item.entity_name }}
                        </div>
                      </td>
                      <td class="numeric default text-grey2">
                        <small class="font-weight-normal table-content-font-size text-dark">
                          {{ item.net_value | currency() }}
                        </small>
                      </td>
                      <td class="numeric default" :class="'text-' + class_status[item.payment_status]">
                        <small class="font-weight-normal table-content-font-size">
                          {{ (item.net_value - item.paid_out) | currency() }}
                        </small>
                      </td>
                      <td class="default text-center">
                         <span
                          class="text-danger" title="IMPRIMIR FATURA"
                          @click.prevent="downloadInvoice(item.id)"
                        >
                          <i class="fa-solid custom-icon fa-file-pdf"/>
                        </span>
                        <span
                          v-if="item.nfse"
                          class=" text-primary mt-n1"
                          title="IMPRIMIR NFSE"
                          @click="nfseButtonClick(item)"
                        >
                          <img src="/img/icons/nfe_blue.png"
                            style="cursor: pointer; height: 31px;margin-top: -10px !important;">
                          </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <PuzlEmptyData v-show="$_invoices && !$_invoices.length && !loadingInvoices"/>
                </div>
              </div>
              <Pagination :loading="loadingInvoices" :source="source" @navegate="navegate"/>
            </div>
          </div>
          <ModalPaymentTerms ref="modalPaymentTerms" :show="showModalPaymentTerms"
                               @close="showModalPaymentTerms = false"
                               @fetch="handleUpdateAfterSendBankBillet"/>
        </div>
      </div>
      <!-- Concreto -->
      <div class="row card-row" v-if="selectConcretes">
        <div class="col-md-12 p-1">
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center default table-column-light-gray text-dark">Data</th>
                      <th class="text-center default table-column-light-gray text-dark">O.S.</th>
                      <th class="text-center default table-column-light-gray text-dark">Fatura</th>
                      <th class="default table-column-light-gray text-dark">Traço</th>
                      <th class="text-center default table-column-light-gray text-dark">Preço</th>
                      <th class="text-center default table-column-light-gray text-dark">Volume</th>
                      <th class="text-center default table-column-light-gray text-dark">Total</th>
                      <th class="text-center default table-column-light-gray text-dark">Adicionais</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 10" v-show="loadingConcretes">
                      <td colspan="12">
                        <SkeletonPuzl type="button"/>
                      </td>
                    </tr>
                    <tr v-for="item in $_concretes" v-show="!loadingConcretes">
                      <td class="numeric default text-center text-truncate">
                        <div class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.data_schedule | parseDate('DD/MMM') }}
                        </div>
                      </td>
                      <td class="text-center">
                        <span class="numeric table-content-font-size text-dark">
                          {{ item.sequencial_number_plant_number }}
                        </span>
                      </td>
                      <td class="text-center">
                        <small
                          class="numeric table-content-font-size text-dark"
                          :class="item.invoice_id ? 'font-weight-600' : 'text-muted'"
                        >
                          {{ item.invoice_id || 'Não faturado' }}
                        </small>
                      </td>
                      <td class="text-truncate table-content-font-size text-dark">
                        {{ item.feature }}
                      </td>
                      <td class="text-center">
                        <small class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.sale_price | currency }}
                        </small>
                      </td>
                      <td class="text-center">
                        <small class="numeric table-content-font-size text-dark">
                          {{ item.schedule_travel_volume.replace('.', ',') }}
                        </small>
                      </td>
                      <td class="text-center">
                        <small class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.total_invoice | currency }}
                        </small>
                      </td>
                      <td class="text-center">
                        <span class="numeric font-weight-600 table-content-font-size text-dark">
                          {{ item.additional_price ? item.additional_price : 0 | currency }}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <PuzlEmptyData v-show="$_concretes && !$_concretes.length && !loadingConcretes"/>
                </div>
              </div>
              <Pagination :loading="loadingConcretes" :source="sourceConcrete" @navegate="navegateConcrete"/>
            </div>
          </div>
        </div>
      </div>
      <!-- Serviços -->
      <div class="row card-row" v-if="selectServices">
        <div class="col-md-12 p-1">
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center default table-column-light-gray text-dark">Data</th>
                      <th class="text-center default table-column-light-gray text-dark">O.S.</th>
                      <th class="text-center default table-column-light-gray text-dark">Fatura</th>
                      <th class="text-center default table-column-light-gray text-dark">Descrição</th>
                      <th class="text-center default table-column-light-gray text-dark">Equipamento</th>
                      <th class="text-center default table-column-light-gray text-dark">Volume</th>
                      <th class="text-center default table-column-light-gray text-dark">Tempo</th>
                      <th class="text-center default table-column-light-gray text-dark">Cobrança</th>
                      <th class="text-center default table-column-light-gray text-dark">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 10" v-show="loadingServices">
                      <th colspan="12">
                        <SkeletonPuzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_services" v-show="!loadingServices">
                      <th class="text-center">
                        <span class="style-table numeric table-content-font-size text-dark">
                          {{ item.construction_exit_time  | parseDate('DD/MMM')}}
                        </span>
                      </th>
                      <th class="text-center">
                        <span class="style-table numeric table-content-font-size text-dark">
                          {{ item.schedule_service_order }}
                        </span>
                      </th>
                      <th class="text-center">
                        <span
                          class="numeric table-content-font-size text-dark"
                          :class="item.invoice_id ? 'font-weight-600' : 'text-muted'"
                        >
                          {{ item.invoice_id || 'Não faturado' }}
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="style-table font-weight-normal table-content-font-size text-dark">
                          {{ item.service_description }}
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="style-table font-weight-normal table-content-font-size text-dark">
                          {{ item.equipment_code }}
                        </span>
                      </th>
                      <th class="text-center">
                        <span class="style-table table-content-font-size text-dark numeric">
                          {{ item.volume.toString().replace('.', ',') }}
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="style-table font-weight-normal table-content-font-size text-dark">
                          {{ item.performed_cycle }}
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="style-table font-weight-normal table-content-font-size text-dark">
                          {{ item.type }}
                        </span>
                      </th>
                      <th class="text-center" >
                        <span class="style-table font-weight-600 numeric table-content-font-size text-dark">
                          {{ item.total | currency }}
                        </span>
                      </th>
                    </tr>
                    </tbody>
                  </table>
                  <PuzlEmptyData v-show="$_services && !$_services.length && !loadingServices"/>
                </div>
              </div>
              <Pagination :loading="loadingServices" :source="sourceService" @navegate="navegateService"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import Pagination from "@/components/Utils/Pagination";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ModalPaymentTerms from '../../../Financial/Billing/Shared/_ModalPaymentTerms.vue';
import {mapGetters} from "vuex";
import moment from 'moment';
import SpinnerPuzl from "@/components/SpinnerPuzl";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ListSalesResume",
  components: {
    PuzlSelect,
    InputDatePicker,
    SkeletonPuzl,
    MultiFilter,
    Pagination,
    ModalPaymentTerms,
    SpinnerPuzl,
    ContractProposalNavigation,
    PuzlEmptyData,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingInvoices: true,
      loadingWidgets: true,
      loadingSeller: true,
      loadingCompanyPlants: true,
      loadingConcretes: true,
      loadingServices: true,
      showModalPaymentTerms: false,
      status: {
        0: 'Em aberto',
        1: 'Recebido',
        2: 'Atrasado',
      },
      class_status: {
        0: 'warning',
        1: 'success',
        2: 'danger',
      },
      filter: {
        company_plants: null,
        contract_uuid: null,
        range: {
          start: new Date(),
          end: new Date(),
        }
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '1': {
          name: "Recebidos",
          type: "success"
        },
        '0': {
          name: "Pendentes",
          type: "warning"
        },
        '2': {
          name: "Atrasados",
          type: "danger"
        }
      },
      selectedCompanyPlants: [],
      selectedSellers: [],
      source: null,
      sourceConcrete: null,
      sourceService: null,
      params: {
        page: 1,
        per_page: 30,
      },
      range: {
        start: new Date(),
        end: new Date(),
        default_filterable: false,
      },
      selectInvoices: true,
      selectConcretes: false,
      selectServices: false,
    }
  },
  computed: {
    ...mapGetters({
      $_invoices: 'salesResume/fetchInvoices',
      $_widgets: 'salesResume/getWidgets',
      $_concretes: 'salesResume/fetchConcretes',
      $_services: 'salesResume/fetchServices',
      $_plants: "plant/activeItems",
      $_sellers: "user/getAllActiveSellersSimplified",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingCompanyPlants = false
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    }

    this.$store.dispatch("user/getAllActiveSellersSimplified").then(() => {
      this.loadingSeller = false;
    });
    this.$refs['multi-filter']._self.$forceUpdate();
    this.getWidgets();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.contract_uuid) {
        this.filter.contract_uuid = this.$route.params.contract_uuid;
      } else {
        // Define o filtro com a data padrão quando não tiver contract_uuid na url.
        this.range.default_filterable = true;
        this.handleFilterDate(this.range);
      }

      if (this.selectInvoices) {
        this.fetchItemsPaginateInvoices();
      } else if (this.selectConcretes) {
        this.fetchItemsPaginateConcretes();
      } else {
        this.fetchItemsPaginateServices();
      }
    },
    load(filter = {}) {
      this.params.page = 1;
      this.filter = filter;
      this.getWidgets();
      this.init();
    },
    handleFilterDate(filter, isLoadWidgets = false) {
      this.range = filter;
      this.filter.range = this.range;
      this.params.page = 1;

      if (isLoadWidgets) {
        this.getWidgets();
      }

      if (this.selectInvoices) {
        this.fetchItemsPaginateInvoices();
      } else if (this.selectConcretes) {
        this.fetchItemsPaginateConcretes();
      } else {
        this.fetchItemsPaginateServices();
      }
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store.dispatch("salesResume/getWidgets", {filter: this.filter})
        .then(() => {
          this.loadingWidgets = false;
        })
    },
    fetchItemsPaginateInvoices() {
      this.loadingInvoices = true;
      this.handleFilterSellersAndPlants();
      this.$store
        .dispatch("salesResume/fetchInvoices", {
          filter: this.filter,
          page: this.params.page
        })
        .then((response) => {
          this.source = response;
          this.loadingInvoices = false;
        })
        .catch((error) => {
          this.loadingInvoices = false;
        });
    },
    fetchItemsPaginateConcretes() {
      this.loadingConcretes = true;
      this.handleFilterSellersAndPlants();
      this.$store
        .dispatch("salesResume/fetchConcretes", {
          filter: this.filter,
          page: this.params.page
        })
        .then((response) => {
          this.sourceConcrete = response;
          this.loadingConcretes = false;
        })
        .catch((error) => {
          this.loadingConcretes = false;
        });
    },
    fetchItemsPaginateServices() {
      this.loadingServices = true;
      this.handleFilterSellersAndPlants();
      this.$store
        .dispatch("salesResume/fetchServices", {
          filter: this.filter,
          page: this.params.page
        })
        .then((response) => {
          this.sourceService = response;
          this.loadingServices = false;
        })
        .catch((error) => {
          this.loadingServices = false;
        });
    },
    handleUpdateAfterSendBankBillet(id) {
      this.$_invoices.map(function (item) {
        if (item.id === id) {
          item.is_pending = 0;
          item.has_any_pending = 1;
        }
      })
      this.showModalPaymentTerms = false
    },
    /**
     * Busca os pagamentos agrupados por condição de pagamento
     * @param invoice
     */
    async handleOpenModalPaymentTerms(invoice) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/getPaymentTerms',
        {invoice_id: invoice.id})
        .then(async response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          const contract_proposal = await this.$store.dispatch("contractProposal/show", invoice.contract_proposal_uuid)
          invoice.contract_proposal = contract_proposal.data
          this.$refs.modalPaymentTerms.setInvoice(invoice)
          this.showModalPaymentTerms = true
          loader.hide()
        })
        .catch(() => loader.hide())
    },
    downloadInvoice(id) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store.dispatch('billingInvoice/download', {
        id: id
      }).then(response => {
        let blob = new Blob([response], {
          type: 'application/pdf'
        })
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank');
        loader.hide();
        this.$Progress.finish();
      }).catch(error => {
        loader.hide()
        this.$Progress.finish();
      })
    },
    async refreshItemNfse(item) {
      let loader = this.$loading.show();
      item.nfse = await this.$store.dispatch('nfse/showInvoiceNfse', item.id).then(res => {
        return res.data.nfse
      }).catch(error => {
        this.$notify({type: error.data.error_type, message: error.data.message});
      })
      loader.hide();
    },
    handleOpenNfseModal(item) {
      // Impedir Emissão se contrato concluído ou inativo
      if ([0, 5].includes(item.contract_proposal_status)) {
        this.$notify({
          type: 'warning',
          message: 'Emissão não pode ser realizada. Contrato com status Concluído/Inativo.'
        })
        return;
      }
      this.$refs.ModalNfseCreate.handleCreateModal(item);
    },
    async openDanfseOnNewTab(pathFile) {
      let loader = this.$loading.show();
      const urlFromAmazon = pathFile.includes('s3');
      if (urlFromAmazon) {
        const url = this.base_url_ms + '/download-s3?url=' + pathFile
        let link = document.createElement('a')
        link.href = url
        link.setAttribute("download", 'key.pdf');
        await link.click();
      } else {
        await window.open(pathFile, '_parent');
      }
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
    async nfseButtonClick(item) {
      // Refresh nos dados da nota para evitar erro
      await this.refreshItemNfse(item);

      // Abrir modal de emissão da nfs-e
      if (!item.nfse) {
        this.handleOpenNfseModal(item)
        return;
      }

      // Informar Status da NFSe ou Abrir PDF
      switch (item.nfse.status) {
        case 'Pendente':
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          break;
        case 'Autorizada':
        case 'Cancelada':
          this.openDanfseOnNewTab(item.nfse.link_pdf);
          break;
        case 'Negada':
          this.$swal.fire({
            icon: 'error',
            title: 'NFS-e Negada!',
            text: item.nfse.status_reason,
          })
          break;
      }
    },
    handleFilterSellersAndPlants() {
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : [];
      this.filter.sellers = !this.selectedSellers.includes("all") ? this.selectedSellers : [];
    },
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.params.page = 1;

      this.getWidgets();

      if (this.selectInvoices) {
        this.fetchItemsPaginateInvoices();
      } else if (this.selectConcretes) {
        this.fetchItemsPaginateConcretes();
      } else {
        this.fetchItemsPaginateServices();
      }
    },
    handleFilterSellers() {
      const index = this.selectedSellers.findIndex((item) => item == "all");
      if (this.selectedSellers.length > 1 && index === 0) {
        return this.selectedSellers.splice(0, 1);
      }
      if (this.selectedSellers.length > 1 && this.selectedSellers.includes("all")) {
        this.selectedSellers = [];
        this.selectedSellers.push("all");
      }
      this.params.page = 1;

      this.getWidgets();

      if (this.selectInvoices) {
        this.fetchItemsPaginateInvoices();
      } else if (this.selectConcretes) {
        this.fetchItemsPaginateConcretes();
      } else {
        this.fetchItemsPaginateServices();
      }
    },
    getNfseNumber(code) {
      if (code.length <= 3) {
        return code;
      }
      return '...' + code.toString().slice(-3);
    },
    navegate(page) {
      this.params.page = page;
      this.fetchItemsPaginateInvoices();
    },
    navegateConcrete(page) {
      this.params.page = page;
      this.fetchItemsPaginateConcretes();
    },
    navegateService(page) {
      this.params.page = page;
      this.fetchItemsPaginateServices();
    },
    clearFilters() {
      this.filter = {};
      this.selectedCompanyPlants = [];
      this.selectedSellers = [];
      this.range = {
        start: new Date(),
        end: new Date(),
      };

      if (this.selectInvoices) {
        this.fetchItemsPaginateInvoices();
      } else if (this.selectConcretes) {
        this.fetchItemsPaginateConcretes();
      } else {
        this.fetchItemsPaginateServices();
      }
    },
    listInvoices() {
      this.selectInvoices = !this.selectInvoices;
      this.selectConcretes = false,
      this.selectServices = false;
    },
    listConcretes() {
      this.selectConcretes = !this.selectConcretes;
      this.selectInvoices = false,
      this.selectServices = false;
    },
    listServices() {
      this.selectServices = !this.selectServices;
      this.selectInvoices = false,
      this.selectConcretes = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.font-weight-semibold {
  font-size: 12px;
}

.font-weight-normal {
  font-size: 12px;
}

.doc-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    display: flex;
  }
}

.custom-icon {
  font-size: 22px;
  cursor: pointer;
  align-self: center;
}

.card-text {
  display: flex;
  flex-wrap: nowrap;
}

table thead th {
  letter-spacing: 0px;
}

.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}

.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.base-button:hover .text-dark {
  color: white !important;
}
</style>
