<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4 mb-1">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/icons8/ios/physics.png" class="mr-3" />
            Formulações
          </h2>
        </div>
        <div class="col-md-8 pr-3">
          <div class="row d-flex justify-content-end">
            <div class="col-md-3 px-3 mb-1">
              <router-link :to="{ name: 'Formulações Adicionar', path: '/technology/mix/mixdesigns' }">
                <base-button class="text-uppercase button-border" block type="success">
                  <img height="19" src="/img/icons/icons8/ios/plus-math--v1-white.png" alt="new" class="mr-1" />
                  NOVO
                </base-button>
              </router-link>
            </div>
            <div class="col-md-3 px-0 ml-n4 mr-md-n4 mb-1">
              <base-dropdown class="col-12">
                <base-button slot="title-container" block type="warning"
                  class="dropdown-toggle text-uppercase button-border">
                  <img src="/img/icons/product-documents.png" height="19" alt="register"> Cadastros
                </base-button>
                <div>
                  <router-link :to="{ path: '/technology/mix/type' }">
                    <a class="dropdown-item">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Tipo
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/subtype' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Subtipo
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/additional-classification' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Classificação Adicional
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/slumpflow' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Slump/Flow
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/maximum-size' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Dimensão Máxima
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/concrete-piece' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Peça a concretar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/puzl-admixture' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/banks/0.png" height="17px">
                      Puzl Admix
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>

                  <router-link :to="{ path: '/technology/mix/feature' }">
                    <a class="dropdown-item" href="#">
                      <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                      Responsabilidades
                    </a>
                  </router-link>

                </div>
              </base-dropdown>
            </div>
            <div class="col-md-3 px-0 mr-md-n4 mb-1">
              <base-dropdown class="col-12">
                <base-button slot="title-container" block type="dark" class="dropdown-toggle text-uppercase">
                  <img src="/img/icons/rating.png" height="19"> Referências
                </base-button>
                <div>
                  <a class="dropdown-item" href="#" @click.prevent="handleReference(1)">
                    <img src="/img/icons/icons8/ios/star-blue.png" height="17px" />
                    Técnica
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" href="#" @click.prevent="handleReference(2)">
                    <img src="/img/icons/icons8/ios/star-blue.png" height="17px" />
                    Comercial
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <router-link class="dropdown-item" :to="{ path: '/technology/mix/references'}">
                    <img src="/img/icons/icons8/ios/time-machine.png" height="17px" />
                    Histórico
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <router-link class="dropdown-item" :to="{ path: '/technology/mix/commercial-reference-resume'}">
                    <img src="/img/icons/icons8/ios/star-blue.png" height="17px" />
                    Referência Comercial
                  </router-link>
                </div>
              </base-dropdown>
            </div>
            <div class="col-md-3 px-0 mb-1">
              <base-dropdown class="col-12">
                <base-button slot="title-container" block type="primary" class="dropdown-toggle text-uppercase">
                  <img src="/img/icons/plus--v1.png" height="19"> Acréscimos
                </base-button>
                <div>
                  <a class="dropdown-item" @click="handleEdit">
                    <img src="/img/icons/icons8/ios/test-tube-blue.png" height="17px" />
                    Tipo de formulação
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" href="#" @click="handleCreateConcretedPiece">
                    <img src="/img/icons/icons8/ios/blockly-black.png" height="17px" />
                    Peça a concretar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" href="#" @click="handleCreatePuzlAdmixture">
                    <img src="/img/icons/banks/0.png" height="17px">
                    Puzl Admix
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" href="#" @click="handleCreateSlumpFinal">
                    <img src="/img/icons/icons8/ios/vlc.png" height="17px" />
                    Slump final
                  </a>
                </div>
              </base-dropdown>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div>
        <multi-filter
          :showSlotDates="false"
          :showSlotBookmark="false"
          :type="3"
          @clearFilter="clearFilter()"
          @fetch="init"
        >
          <template slot="data">
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">Tipo</label>
              <puzl-select
                :items="types"
                v-model="filter.type_ids"
                :multiple="true"
                label="technical_description"
                class="select-xl new-default-black-font"
                @input="handleFilterMixTypes"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">Dimensão máxima</label>
              <puzl-select
                :items="maximum_sizes"
                v-model="filter.maximum_size_ids"
                label="description"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterMaximumSizes"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">Slump/flow</label>
              <puzl-select
                :items="slump_flows"
                v-model="filter.slump_flow_ids"
                label="nomenclature"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterSlumpFlows"
              />
            </div>
          </template>

          <template slot="status">
            <div class="col-12">
              <BaseButtonHoverable
                size="sm"
                type="success"
                icon="thumb-up"
                platform="ios"
                :active="filter.status == status.active.value"
                @click="filter.status = filter.status === status.active.value ? null : status.active.value, init({})"
              >
                Ativo
              </BaseButtonHoverable>
            </div>
            <div class="col-12 my-1">
              <BaseButtonHoverable
                size="sm"
                type="danger"
                icon="thumbs-down"
                platform="ios"
                :active="filter.status == status.inactive.value"
                @click="filter.status = filter.status === status.inactive.value ? null : status.inactive.value, init({})"
              >
                Inativo
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mb-1">
              <BaseButtonHoverable
                size="sm"
                type="dark"
                icon="edit-file"
                platform="ios"
                :active="filter.status == status.draft.value"
                @click="filter.status = filter.status === status.draft.value ? null : status.draft.value, init({})"
              >
                Rascunho
              </BaseButtonHoverable>
            </div>
          </template>
        </multi-filter>
      </div>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <puzl-empty-data v-if="!mix_designs.length && !loadingSkeleton" />
      <div>
        <div class="row card-wrapper" v-if="!loadingSkeleton">
          <div class="col-lg-4" v-for="mix_design in mix_designs" :key="mix_design.id">
            <!-- Basic with action button -->
            <div class="card" style="overflow: hidden;">
              <div class="card-body">
                <div class="row align-items-center mb-2 mt-n3" v-if="mix_design">
                  <div class="col-9 d-flex">
                    <el-popover trigger="click" placement="bottom" class="p-0 col-1 mr-3">
                      <div v-if="mix_design.groups">
                        <div class="row align-items-center pb-0 mb-3"
                          v-for="(category_group, indexCategoryGroup) in mix_design.groups" :key="indexCategoryGroup">
                          <div class="col-md-12">
                            <div class="checklist-item checklist-item-primary">
                              <div class="checklist-info">
                                <h4 class="checklist-title mb-0">{{ category_group.category_name }}</h4>
                                <div v-for="(group, indexGroup) in category_group.groups" :key="indexGroup">
                                  <h5 class="checklist-title small">{{ group }}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                        <img class="pointer" src="/img/icons/icons8/ios/parallel-tasks-blue.png" width="26px" height="26px" />
                      </div>
                    </el-popover>
                    <el-popover v-if="!mix_design.last_updated.is_today" trigger="click" placement="bottom"
                      class="p-0 col-1 mr-1">
                      <div>
                        <div class="col-12">
                          <span>
                            <span class="h4">
                              {{ mix_design.last_updated.user }}
                            </span>
                            <br>
                            <span>
                              {{ mix_design.last_updated.updated_at | parseDate }}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                        <img class="pointer" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26px" height="26px" />
                      </div>
                    </el-popover>
                    <el-popover
                      v-if="mix_design.adjustment_blocks && mix_design.adjustment_blocks.length"
                      trigger="click"
                      placement="bottom" class="p-0 col-1 mr-1"
                    >
                      <div>
                        <h5 v-for="item in mix_design.adjustment_blocks_formatted">{{ item }}</h5>
                      </div>
                      <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                        <img class="pointer ml-2" src="/img/icons/icons8/ios/cancel-2_danger.png" width="26px" height="26px" />
                      </div>
                    </el-popover>
                    <el-popover
                      v-if="mix_design.last_updated.is_today"
                      trigger="click"
                      placement="bottom"
                      class="p-0 col-1 mr-1"
                    >
                      <div>
                        <div class="row" v-if="mix_design.last_updated.user">
                          <div class="col-lg-12" v-if="mix_design.last_updated.is_today">
                            <span class="h4">{{ mix_design.last_updated.user }}</span> -
                            <badge type="success">Hoje</badge>
                            <br>
                            <span style="color: #149E57;">{{ mix_design.last_updated.updated_at | parseDate }}</span>
                          </div>
                        </div>
                      </div>
                      <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                        <img class="pointer" src="/img/icons/icons8/ios/checked--v1.png" width="26px" height="26px" />
                      </div>
                    </el-popover>
                  </div>
                  <div class="col-2">
                    <base-dropdown menuOnRight style="display: block;">
                      <base-button
                        style="border: none !important; box-shadow: none !important;"
                        slot="title-container"
                        type="invisible"
                        class="mr-0 pr-0 float-right dropdown-toggle rounded"
                      >
                        <img src="/img/icons/icons8/ios/settings--v1.png" width="25px" height="25px" />
                      </base-button>
                      <router-link :to="{
                        name: 'Formulações Edição',
                        path: '/technology/mix/mixdesigns/edit',
                        params: { mix_design_uuid: mix_design.uuid }
                      }">
                        <a class="dropdown-item">
                          <img src="/img/icons/icons8/ios/create-new-yellow.png" width="21px" height="21px">
                          Editar
                        </a>
                      </router-link>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <router-link class="float-right mr-1" :to="{ path: '/technology/mix/resume/' + mix_design.uuid }">
                        <a class="dropdown-item">
                          <img src="/img/icons/icons8/ios/test-tube-blue.png" width="21px" height="21px">
                          Traços
                        </a>
                      </router-link>
                      <div class="dropdown-divider p-0 m-0"></div>

                      <a class="dropdown-item" @click.prevent="handleReplicate(mix_design)">
                        <img src="/img/icons/icons8/ios/copy--v1-green.png" width="21px" height="21px">
                        Replicar
                      </a>
                      <div v-if="mix_design.status == 2" class="dropdown-divider p-0 m-0"></div>

                      <a v-if="mix_design.status == 2"  class="dropdown-item mb-n1" @click="handleDeleteMixDesign(mix_design.uuid)">
                        <img src="/img/icons/icons8/ios/delete-red.png" width="21px" height="21px">
                        Excluir
                      </a>
                      <!-- <a class="dropdown-item" @click.prevent="handleAdjustment(mix_design)">
                        <i class="fas fa-wrench text-muted"></i>
                        Ajustes
                      </a> -->
                    </base-dropdown>
                  </div>
                </div>

                <div class="row mb-0">
                  <div class="col-md-12">
                    <h5 class="mb-0 font-weight-400">
                      {{ mix_design.plant.name }}
                      <div style="display: inline-flex;"
                        class="ml-2"
                        :class="getColor(mix_design.status)">
                        <h4 class="font-weight-normal m-0 p-0">
                          {{ getText(mix_design.status) }}
                        </h4>
                      </div>
                      <router-link class="float-right mr-1" :to="{ path: '/technology/mix/resume/' + mix_design.uuid }">
                        <img src="/img/icons/icons8/ios/health-graph-green.png" width="25px" height="25px">
                      </router-link>
                    </h5>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col-md-12">
                    <h5 class="h3 mb-0" v-if="mix_design.type">
                      {{ mix_design.type.technical_description || '' }}
                    </h5>
                    <span class=" mb-0 " v-if="mix_design.subtype">
                      {{ mix_design.subtype.technical_description || '' }}
                    </span>
                    <span class=" mb-0 " v-if="mix_design.classification">
                      | {{ mix_design.classification.technical_description || '' }}
                    </span>
                  </div>
                </div>

                <!-- Linha fina na horizontal -->
                <div class="col-12 horizontal-line mb-2"></div>

                <div class="mb-2">
                  <h5 class="h3 mb-1" v-for="(feature, index) in mix_design.feature" :key="index"
                    style="display: inline-block;">
                    <span class="mt-n1 ml-1 mr-1" v-if="feature.short_description && index !== 0"
                      style="font-size: 20px !important;"> +</span>
                    {{ feature.short_description }}
                    {{ format_mixe(feature.period, feature.period_type) }}
                    {{ feature.measured_unit && feature.measured_unit.letter }}
                  </h5>
                </div>

                <div class="row">
                  <div v-if="!mix_design.slump_final.length" class="col-6">
                    <div class="card mb-n1 align-items-center">
                      <div class="card-body">
                        <div class="row">
                          <b class="col-12 text-center responsive-font" style="white-space: nowrap;display: flex; align-items: center;">
                            <img class="mr-2" src="/img/icons/icons8/ios/vlc.png" height="23px">
                            {{ mix_design.slumpFlow.nomenclature }}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-6">
                    <div class="card mb-n2" style="overflow: hidden;">
                      <div class="card-body">
                        <div class="custom-card"></div>
                        <div class="row">
                          <b class="col-lg-8 col-7 text-center responsive-font" style="white-space: nowrap;">
                            <img class="mr-2" src="/img/icons/icons8/ios/vlc.png" height="24px">
                            {{ mix_design.slumpFlow.nomenclature }}
                          </b>
                          <div class="col-lg-4 col-5 text-center align-items-center">
                            <el-popover v-if="mix_design.slump_final.length" trigger="click" placement="bottom"
                              class="text-center">
                              <h5 class="text-center" v-for="item in mix_design.slump_final"> {{ item.nomenclature }}</h5>
                              <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                                <img class="ml-2 mb-n1 align-items-center" src="/img/icons/banks/0.png" height="23px">
                              </div>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card mb-n2 align-items-center">
                      <div class="card-body">
                        <div class="row">
                          <b class="col-12 responsive-font" style="white-space: nowrap;display: flex; align-items: center;">
                            <img src="/img/icons/icons8/ios/wheelbarrow.png" height="25px" class="mr-1 mt-n1">
                            {{ mix_design.maximum_size.description || '' }}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="'traits-' + mix_design.id" class="mt-4 mb-n3 traits-disabled">
                  <div class="card" style="overflow: hidden;">
                    <div class="card-header" style="padding: 0px !important;">
                      <div class="text-center text-white text-uppercase card-head" style="background: #2b2d32;">
                        Traços
                      </div>
                    </div>
                    <div class="card-body py-3 px-0">
                      <div v-for="(mixes, index) in mix_design.mix_design_mixes" :key="index">
                        <div class="row align-items-center pb-0">
                          <div class="col-md-12">
                            <div class="" :class="checkStatus(mixes)">
                              <h4 class="checklist-title" v-for="(mixe, indexItem, mixeIndex) in mixes"
                                v-if="mixe && indexItem != 'binder' && mixe.status === 1" :key="mixeIndex">
                                <div class="row">
                                  <div class="col-9">
                                    <span class="ml-4">
                                      {{ mixe.short_description }}
                                      {{ format_mixe(mixe.period, mixe.period_type) }}
                                      {{ mixe.item_description }}
                                      {{ mixe.letter }}
                                    </span>
                                  </div>
                                  <a v-if="mixeIndex === 0" @click.prevent="copy(`TR-${mixe.code_id}`)" href="#" class="col-3">
                                    <badge v-if="mixe.code_id" style="font-size: 12px !important;"
                                      title="Clique aqui para copiar" class="pointer font-weight-500 float-right mr-4" type="default">
                                      TR-{{ mixe.code_id }}
                                    </badge>
                                  </a>
                                </div>
                                <hr v-if="index !== mix_design.mix_design_mixes.length-1 && mixe.status === 1"
                                  class="col-12 mb-2 mt-2">
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mt-4" style="margin-bottom: -24px; margin-right: -24px;">
                  <div class="offset-6 col-md-6 text-center" style="text-align: right !important;padding: 0px">
                    <div class="card-blue-bottom">
                      <a style="height: 35px; display: block;" href="#" @click.prevent="showTraits(mix_design.id)">
                        <h3 style="text-transform: none;padding: 3px;padding-right: 10px;">
                          <span class="white-image">
                            <img src="/img/icons/icons8/ios/test-tube-white.png" width="19px" height="17px">
                          </span>
                          <small v-if="mix_design.traits" class="mr-3 text-white" style="font-size: 13px">
                            OCULTAR
                          </small>
                          <small v-else class="mr-3 text-white" style="font-size: 13px">
                            TRAÇOS
                          </small>
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton" />
    </div>
    <modal-reference ref="modalReference" />
    <modal-adjustment ref="ModalAdjustmentMixDesigns" :item="selected"></modal-adjustment>
    <modal-replicate ref="ModalReplicateMixDesigns" @editFormulation="editFormulation" :item="selected"></modal-replicate>
    <modal-create-mix-type></modal-create-mix-type>
    <modal-create-mix-concreted-pieces></modal-create-mix-concreted-pieces>
    <modal-create-mix-puzl-admixture></modal-create-mix-puzl-admixture>
    <modal-create-mix-slump-flow />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalReference from './Shared/_ModalReference';
import ModalReplicate from './Shared/_ModalReplicate';
import ModalAdjustment from './Shared/_ModalAdjustment';
import { period_types } from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "../../../../components/PuzlEmptyData";
import ModalCreateMixType from './Shared/_ModalCreateMixType.vue';
import ModalCreateMixConcretedPieces from './Shared/_ModalCreateMixConcretedPieces.vue';
import ModalCreateMixPuzlAdmixture from './Shared/_ModalCreateMixPuzlAdmixture.vue';
import ModalCreateMixSlumpFlow from './Shared/_ModalCreateMixSlumpFlow.vue';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalReference,
    ModalReplicate,
    ModalAdjustment,
    MultiFilter,
    LoadingPagination,
    ModalCreateMixType,
    ModalCreateMixConcretedPieces,
    ModalCreateMixPuzlAdmixture,
    ModalCreateMixSlumpFlow,
    BaseButtonHoverable,
    PuzlSelect
  },
  data() {
    return {
      format_mixe: period_types,
      listMixDesigns: {},
      selected: null,
      loadingSkeleton: true,
      loadingMaximumSize: true,
      loadingSlumpFlow: true,
      loadingType: true,
      filter: {
        type_ids: '',
        maximum_size_ids: '',
        slump_flow_ids: '',
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        0: {
          name: "Inativo",
          type: "danger"
        },
        1: {
          name: "Ativo",
          type: "success"
        },
        2: {
          name: "Rascunho",
          type: "default"
        },
      },
      selectedMixTypes: [],
      selectedMaximumSizes: [],
      selectedSlumpFlows: [],
      status: {
        inactive: { value: 0, name: "Inativo" },
        active: { value: 1, name: "Ativos" },
        draft: { value: 2, name: "Rascunho" },
      },
    }
  },
  computed: {
    ...mapGetters({
      'mix_designs': 'MixDesigns/fetch',
      'types': 'mixType/activeItems',
      'slump_flows': 'mixSlumpFlow/activeItems',
      'maximum_sizes': 'mixMaximumSize/activeItems',
    }),
  },
  methods: {
    getColor(status){
      switch(status){
        case 1:
          return 'mini-card-green';
        case 2:
          return 'mini-card-gray';
        default:
          return 'mini-card-red';
      }
    },
    getText(status){
      switch(status){
        case 1:
          return 'Ativo';
        case 2:
          return 'rascunho';
        default:
          return 'inativo';
      }
    },
    checkStatus(mixe) {
      let status = null
      if (mixe[0]) {
        status = mixe[0].status
      }
      switch (status) {
        case 2:
          return 'checklist-item-gray'
        case 1:
          return 'checklist-item-success'
        case 0:
          return 'checklist-item-danger'
        case 5:
          return 'checklist-item-purple'
        default:
          return 'checklist-item-info'
          break;
      }
    },
    editFormulation(params) {
      this.$router.push({
        name: 'Formulações Edição',
        path: '/technology/mix/mixdesigns/edit',
        params: { mix_design_uuid: params.uuid }
      })
    },
    handleReplicate(item) {
      this.selected = item
      this.$refs.ModalReplicateMixDesigns.handleReplicateModal(item)
    },
    handleAdjustment(item) {
      this.selected = item
      this.$refs.ModalAdjustmentMixDesigns.handleAdjustmentModal(item)
    },
    handleDeleteMixDesign(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('MixDesigns/destroy', uuid).then(response => {
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            })
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    copy(val) {
      this.$helper.copy(val)
      this.$notify({ type: 'success', message: 'Código do traço copiado com sucesso!' })
    },
    handleReference(type) {
      this.$refs.modalReference.handleCreate(type);
    },
    init(filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('MixDesigns/fetchItems', {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      }).then(response => {
        for (let item of this.mix_designs) {
          item.mix_design_mixes = Object.values(item.mix_design_mixes)
          item.mix_design_mixes = item.mix_design_mixes.sort((a, b) => a.binder - b.binder)
        }
        this.resolveCursor(response)
        this.loadingSkeleton = false;
      })
        .catch(() => {
          this.resolveCursor()
          this.loadingSkeleton = false;
        })
    },
    handleEdit() {
      EventBus.$emit("handleCreateMixTypePercentage");
    },
    handleCreateConcretedPiece() {
      EventBus.$emit("handleCreateMixConcretedPiecesPercentage");
    },
    handleCreatePuzlAdmixture() {
      EventBus.$emit("handleCreateMixPuzlAdmixturePercentage");
    },
    handleCreateSlumpFinal() {
      EventBus.$emit("handleCreateSlumpFinal");
    },
    clearFilter() {
      this.filter = {
      }
      this.init({})
    },
    showTraits(id) {
      this.$el.querySelector('.traits-' + id).classList.toggle('traits-disabled');
      const found = this.mix_designs.find((item) => item.id === id);
      found.traits = !found.traits;
      this.$forceUpdate();
    },
    handleFilterMixTypes(filter) {
      this.filter.type_ids = filter;
      this.selectedMixTypes = filter;
      this.init({});
    },
    handleFilterMaximumSizes(filter) {
      this.filter.maximum_size_ids = filter;
      this.selectedMaximumSizes = filter;
      this.init({});
    },
    handleFilterSlumpFlows(filter) {
      this.filter.slump_flow_ids = filter;
      this.selectedSlumpFlows = filter;
      this.init({});
    },
  },
  mounted() {
    this.init({})
    this.$store.dispatch('mixType/fetchItems').then(() => {
      this.loadingType = false
    })
    this.$store.dispatch('mixSlumpFlow/fetchItems').then(() => {
      this.loadingSlumpFlow = false
    })
    this.$store.dispatch('mixMaximumSize/fetchItems').then(() => {
      this.loadingMaximumSize = false
    })
  },
};
</script>

<style scoped>
.horizontal-line {
  border-bottom: 1px solid #1a70b7;
}

.horizontal-line-gray {
  border-bottom: 1px solid #dfdfdf;
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}

.card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
}

.card-head {
  width: 100%;
  border: none;
  font-size: .75rem;
  padding: 3px;
  padding-bottom: 4px;
  letter-spacing: .025em;
}

.custom-card {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: #d1e3f1;
  filter: blur(1px);
}

.traits-disabled {
  height: 0px;
  overflow: hidden;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}

/** Diminuí a fonte em telas medias e deixa tamanho padrão em telas grandes ou celular */
@media (min-width: 767px) and (max-width: 1317px) {
  .responsive-font {
    font-size: 11px;
  }
}
@media (max-width: 766px), (min-width: 1318px) {
  .responsive-font {
    font-size: 13px;
  }
}
</style>
