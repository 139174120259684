<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center pt-4 pb-2">
        <div class="col-md-6">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[1].title }}
          </h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[1].title }}
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-6">
          <div class="row d-flex justify-content-end">
            <div class="mb-2 col-md-3 px-1 mr-3">
              <base-button
                @click.prevent="handleModalSearchContract"
                class="text-uppercase d-flex justify-content-center"
                type="success"
                block
              >
                <img
                  width="20"
                  height="20"
                  src="https://img.icons8.com/ios/100/FFFFFF/plus-math--v1.png"
                  alt="plus-math--v1"
                  class="mr-2"
                />
                Avulso
              </base-button>
            </div>
            <div class="mb-2 col-md-3 px-1 mr-3">
              <base-button
                @click.prevent="handleShowModalReport"
                class="text-uppercase d-flex justify-content-center"
                type="default"
                block
              >
                <img class="mr-2" height="21" src="/img/icons/icons8/ios/graph-report.png" /> Relatório
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <list ref="list"/>
    <modal-search-contract @openCreateSingle="showModalCreateSingle" ref="modalSearchContract"/>
    <create-single
        :contractProposalId="contractProposalId"
        :contractProposalCode="contractProposalCode"
        :constructionName="constructionName"
        :customerConstructionName="customerConstructionName"
        @fetch="fetch"
        ref="createSingle"
    />
    <ModalReport ref="modalReport"/>
  </div>
</template>

<script>
import List from "./Shared/_List";
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";
import CreateSingle from '@/views/Modules/Financial/Billing/BillingProposal/Shared/_CreateSingle';
import ModalReport from './Shared/_ModalReport.vue'

export default {
  components: {
    List,
    ModalSearchContract,
    CreateSingle,
    ModalReport,
  },
  name: "Index",
  data() {
    return {
      contractProposalId: null,
      contractProposalCode: null,
      constructionName: null,
      customerConstructionName: null,
    }
  },
  methods: {
    handleShowModalReport() {
      this.$refs.modalReport.openModal()
    },
    handleModalSearchContract() {
      this.$refs.modalSearchContract.openModal();
    },
    /**
     * @param {number} contractProposalId
     * @param {string} contractProposalCode
     * @param {string} constructionName
     * @param {string} customerConstructionName
     */
    showModalCreateSingle(contractProposalId, contractProposalCode, constructionName, customerConstructionName) {
      this.contractProposalId = contractProposalId;
      this.contractProposalCode = contractProposalCode;
      this.constructionName = constructionName;
      this.customerConstructionName = customerConstructionName;
      this.$refs.createSingle.openModal();
    },
    fetch(){
      this.$refs.list.init({});
    }
  }
};
</script>

<style scoped></style>
