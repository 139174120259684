<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="form-control-label ml-3">Data de carregamento</label>
          <div class="col-md-12">
            <input-date-picker
              ref="input-date-picker"
              @handleFilterDate="handleFilterDate"
              :default-range="defaultRange"
              :filterable="4"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <label class="col-md-2 form-control-label mt-1"> Central </label>
          <div class="col-md-10">
            <PuzlSelect
              v-model="selectedCompanyPlants"
              :items="[{id: 'all', name: 'Todos'}, ...$_plants]" 
              customKey="uuid"  
              :multiple="true"
              :loading="loadingCompanyPlants"
              :disabled="loadingCompanyPlants"
              @input="setAll"
            />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button
          @click.prevent="close"
          type="secondary"
          class="text-capitalize"
        >
          Cancelar
        </base-button>
        <base-button
          @click.prevent="submitAndClose"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Download
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalResumeScheduleExcelReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Gerar Relatório Resumo de Viagens (Excel)",
      modal: false,
      filter: {
        range: null,
        company_plants: [],
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date( new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date( new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      selectedCompanyPlants: [],
      isLoading: false,
      loadingCompanyPlants: true,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    openModal() {
      this.loadData();
      this.modal = true;
    },
    close() {
      this.modal = false;
    },
    init(){
      this.range = this.defaultRange;
      this.selectedCompanyPlants = [];
    },
    async submitAndClose() {
      // Filtro de dados
      this.filter.range = this.range;
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") 
        ? this.selectedCompanyPlants 
        : []

      // Baixar Relatório em Excel
      let loader = this.$loading.show();
      this.isLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store
        .dispatch("scheduleResume/getResumeExcelReport", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de viagens.xlsx");
          await link.click();
          loader.hide();
          this.isLoading = false;
          this.close();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          loader.hide();
          this.isLoading = false;
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
    },
    setAll() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == 'all');
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
    },
    loadData() {
      this.init();

      const localPlants = localStorage.getItem('companyPlants');
      if (localPlants) {
        const plants = JSON.parse(localPlants);
        this.$store.commit('plant/SET_ACTIVE', plants.data);
        this.loadingCompanyPlants = false;
      } else {
        this.$store.dispatch('plant/getPlantsSimplified').then(() => {
          this.loadingCompanyPlants = false;
        });
      }
    },
  },
};
</script>

<style></style>
