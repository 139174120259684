import { PagerType, initPagerType } from "../../../../shared/types";
import { StockLocationListType } from "./StockLocationListType";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<StockLocationListType> }
 * )} StockLocationListPagerType
 */
export const StockLocationListPagerType = {};

/**
 * Inicializar StockLocationListPagerType
 * 
 * @returns {StockLocationListPagerType}
 */
export const initStockLocationListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

