export default [
  {
    path: "/technology/adjustment-histories",
    name: "Histórico de Alterações",
    meta: {
      permission: "technology.puzl-adjustment.index.view",
      description: "Histórico de Alterações",
    },
    component: () => import("../views/list/AdjustmentHistoryListPage.vue"),
  },
];