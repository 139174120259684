import { BaseListFilterType, PagerType } from "../types";

/**
 * Inclusão (Base) de recurso
 *
 * @param {Object} data - Dados do recurso a ser criado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setNewItemInList - Adiciona o novo item à lista existente.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para criar o recurso, recebendo os dados.
 * @returns {Promise<Object>} - O recurso criado.
 */
export const createResource = async (data, mutations, service) => {
  try {
    mutations.setIsSaving(true);

    const created = await service.execute(data);
    return mutations.setNewItemInList(created);
  } finally {
    mutations.setIsSaving(false);
  }
};

/**
 * Localizar (Base) por ID
 *
 * @param {number|string} id - O ID do recurso a ser localizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsFinding - Define o estado de busca (true/false).
 * @param {Function} mutations.setItem - Armazena o recurso encontrado.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para localizar o recurso pelo ID.
 * @returns {Promise<?Object>} - O recurso localizado ou null se não encontrado.
 */
export const findResource = async (id, mutations, service) => {
  try {
    mutations.setIsFinding(true);
    
    const found = await service.execute(id);
    return mutations.setItem(found);
  } finally {
    mutations.setIsFinding(false);
  }
};


/**
 * Listagem (Base) de recursos
 * 
 * @param {BaseListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @param {Object} mutations - Objeto de mutações
 * @param {Function} mutations.setIsListing - Define o estado de listagem (true/false).
 * @param {Function} mutations.setListed - Armazena a lista retornada na mutação.
 * @param {Function} mutations.setListMerge - Mescla a lista existente com a nova lista recebida.
 * @param {Object} getters - Objeto de getters
 * @param {Function} getters.getListed - Retorna a lista atual de itens listados.
 * @param {Object} service - Objeto de serviço
 * @param {Function} service.execute - Executa a chamada para listar recursos, recebendo um filtro e retornando os dados.
 * @returns {Promise<?PagerType>} - Retorno paginado
 */
export const listResources = async (filter, isAccumulateItems = false, mutations, getters, service) => {
  try {
    mutations.setIsListing(true);

    // Retorna a lista requisitada
    if (!isAccumulateItems) {
      const listed = await service.execute(filter);
      return mutations.setListed(listed);
    }

    // Retorna null quando não há próxima página disponível
    const existingListed = getters.getListed();
    if (!existingListed?.has_next_page) {
      return null;
    }

    // Retorna a lista existente combinada com a lista requisitada
    filter.page = existingListed.next_page;
    const newListed = await service.execute(filter);
    return mutations.setListMerge(newListed);
  } finally {
    mutations.setIsListing(false);
  }
};

/**
 * Deletar (Base) por ID
 *
 * @param {number} id - O ID do recurso a ser deletado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsRemoving - Define o estado de remoção (true/false).
 * @param {Function} mutations.setRemovedItem - Armazena o ID do recurso removido.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para deletar o recurso pelo ID.
 * @returns {Promise<void>} - Promise que resolve quando a remoção é concluída.
 */
export const removeResource = async (id, mutations, service) => {
  try {
    mutations.setIsRemoving(true);
    
    await service.execute(id);
    mutations.setRemovedItem(id);
  } finally {
    mutations.setIsRemoving(false);
  }
};

/**
 * Atualizar (Base) por ID
 *
 * @param {Object} data - Dados do recurso a serem atualizados.
 * @param {number} id - O ID do recurso a ser atualizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setUpdatedItem - Armazena o recurso atualizado.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para atualizar o recurso pelo ID, recebendo os dados.
 * @returns {Promise<Object>} - O recurso atualizado.
 */
export const updateResource = async (data, id, mutations, service) => {
  try {
    mutations.setIsSaving(true);
    
    const updated = await service.execute(data, id);
    return mutations.setUpdatedItem(updated);
  } finally {
    mutations.setIsSaving(false);
  }
};