import {
  BaseListFilterType,
  initBaseListFilterType,
} from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} NfeIssuedesListFilterExtType
 * @property {null|1|2|4|5} status - [null,1,2,4,5] Todos: null Autorizadas: 1 Rejeitadas: 2 Canceladas: 4 Duplicadas: 5
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {null|integer} company_plant_issuer_id - Id CNPJ emissor
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & NfeIssuedesListFilterExtType)} NfeIssuedesListFilterType
 */
export const NfeIssuedesListFilterType = {};

/**
 * Inicializar NfeIssuedesListFilterType
 *
 * @returns {NfeIssuedesListFilterType}
 */
export const initNfeIssuedesListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "xml_travels.id", is_desc: true }],
  custom_search: {
    columns: [
      "xml_travels.number",
      "customer_constructions.customer_name",
      "constructions.construction_name",
      "sequencial_number_plants.number",
    ],
    values: [],
  },
  status: null,
  range: {
    start: null,
    end: null,
  },
  company_plant_issuer_id: null,
});
