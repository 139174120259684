<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 mb-2">
          <base-button
            block
            :class="filter.by_measurement && 'active'"
            @click.prevent="handleFilterByMeasurment()"
            type="primary"
            native-type="submit"
            outline
            title="Faturamento por medição"
            class="btn-custom"
          >
            <img v-show="!filter.by_measurement" class="mr-2" src="/img/icons/icons8/date-span_primary.png" width="19"/>
            <img v-show="filter.by_measurement" class="mr-2" src="/img/icons/icons8/date-span.png" width="19"/>
            Faturamento por medição
          </base-button>
        </div>
        <div class="col-md-3 mb-3">
          <base-button
            block
            :class="invoice && 'active'"
            @click.prevent="handleInvoice()"
            type="success"
            native-type="submit"
            outline
            title="Faturamento por medição"
            class="btn-custom"
          >
            <img v-show="!invoice" class="mr-2" src="/img/icons/icons8/ios/choose_success.png" width="19"/>
            <img v-show="invoice" class="mr-2" src="/img/icons/icons8/ios/choose.png" width="19"/>
            Faturar em lote
          </base-button>
        </div>
      </div>
      <multi-filter :showSlotOrderBy="false" :onlyFilterMonth="!$_general_settings.allow_delivery_different_month"
                    :type="4" @clearFilter="clearFilter()" @handleFilterDate="handleFilterDate" @fetch="init">
      </multi-filter>
      <puzl-empty-data v-if="!loadingSkeleton && !contract_proposals.length"/>
      <div class="row card-wrapper">
        <SkeletonPuzlGrid size="lg" v-for="(index) in 3" v-show="loadingSkeleton" :key="index"/>
      </div>
      <div v-if="!loadingSkeleton && contract_proposals.length" class="row card-wrapper">
        <div class="col-md-4" v-for="(item, index) in contract_proposals" :key="index">
          <div class="card"
               :style="agroup_selecteds.length ? 'min-height: 252px !important;' : 'min-height: 287px !important;'">
            <div class="card-body">
              <div class="row mt-n2">
                <div class="col-10 px-2" v-show="!agroup_selecteds.length && !item.is_pending">
                  <div class="row px-2 show-md">
                    <div class="col-4 p-1">
                      <base-button type="light" block
                                   @click.prevent="setIss(item)"
                                   :class="item.iss_retain ? 'new-btn-danger' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!item.iss_retain" src="/img/icons/chemical-plant-black.png" width="14"/>
                        <img v-show="item.iss_retain" src="/img/icons/icons8/ios/name--v1_danger.png" width="14"/>

                        <span :class="item.iss_retain && 'text-danger fs-10'" class="fs-10">
                          {{ item.iss_retain ? 'ISS RETIDO' : 'ISS DEVIDO' }}
                        </span>
                      </base-button>
                    </div>
                    <div class="col-4 p-1">
                      <base-button type="light" block
                                   @click.prevent="showModalFederalTaxes(item)"
                                   :class="hasAnyFederalTaxRetained(item) ? 'new-btn-danger' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!hasAnyFederalTaxRetained(item)" src="/img/icons/ledger.png"
                             width="14"/>
                        <img v-show="hasAnyFederalTaxRetained(item)" src="/img/icons/icons8/ios/ledger_danger.png"
                             width="14"/>
                        <span :class="hasAnyFederalTaxRetained(item) && 'text-danger'" class="fs-10">
                           FEDERAL
                        </span>
                      </base-button>
                    </div>
                    <div class="col-4 p-1" v-if="item.balance > 0">
                      <base-button type="light" block
                                   @click.prevent="item.use_anticipation_balance = !item.use_anticipation_balance"
                                   :class="item.use_anticipation_balance ? 'new-btn-primary' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_black.png"
                             width="14"/>
                        <img v-show="item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
                             width="14"/>
                        <span :class="item.use_anticipation_balance && 'text-primary'" class="fs-10">
                           COMPENSAR
                        </span>
                      </base-button>
                    </div>
                  </div>
                  <div class="row px-2 show-mobile">
                    <div class="col-4 p-1">
                      <base-button type="light" block
                                   @click.prevent="setIss(item)"
                                   :class="item.iss_retain ? 'new-btn-danger' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!item.iss_retain" src="/img/icons/chemical-plant-black.png" width="14"/>
                        <img v-show="item.iss_retain" src="/img/icons/icons8/ios/name--v1_danger.png" width="14"/>

                        <span :class="item.iss_retain && 'text-danger fs-10'" class="fs-10">
                          {{ item.iss_retain ? 'ISS RETIDO' : 'ISS DEVIDO' }}
                        </span>
                      </base-button>
                    </div>
                    <div class="col-4 p-1">
                      <base-button type="light" block
                                   @click.prevent="showModalFederalTaxes(item)"
                                   :class="hasAnyFederalTaxRetained(item) ? 'new-btn-danger' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!hasAnyFederalTaxRetained(item)" src="/img/icons/ledger.png"
                             width="14"/>
                        <img v-show="hasAnyFederalTaxRetained(item)" src="/img/icons/icons8/ios/ledger_danger.png"
                             width="14"/>
                        <span :class="hasAnyFederalTaxRetained(item) && 'text-danger'" class="fs-10">
                           FEDERAL
                        </span>
                      </base-button>
                    </div>
                    <div class="col-4 p-1" v-if="item.balance > 0">
                      <base-button type="light" block
                                   @click.prevent="item.use_anticipation_balance = !item.use_anticipation_balance"
                                   :class="item.use_anticipation_balance ? 'new-btn-primary' : 'new-btn-light'"
                                   class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                   size="sm">
                        <img v-show="!item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_black.png"
                             width="14"/>
                        <img v-show="item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
                             width="14"/>
                        <span :class="item.use_anticipation_balance && 'text-primary'" class="fs-10">
                           COMPENSAR
                        </span>
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="col-2 pt-2 show-md text-center"
                     v-if="!item.is_pending && !agroup_selecteds.length && !invoice">
                  <base-dropdown-v2>
                    <div>
                      <base-dropdown-item @click="handleCreateModalBilling(item.uuid)">
                        <img src="/img/icons/create-new.png" width="22px" height="22px" class="mt-n2"/> EDITAR CONTRATO
                      </base-dropdown-item>
                      <base-dropdown-item @click="setToAgroup(item, index)">
                        <i class="fa-solid fa-layer-group text-primary" style="font-size: 18px;margin-right: 4px;"></i> AGRUPAR
                      </base-dropdown-item>
                      <router-link :to="{
                      name: 'commercial.contract-proposal.edit',
                      path: '/commercial/contract-proposal/edit',
                      params: { contract_proposal_uuid: item.uuid }
                    }">
                        <base-dropdown-item>
                          <img src="/img/icons/icons8/ios/external-link-squared.png" width="19" height="19"/>
                          IR PARA O CONTRATO
                        </base-dropdown-item>
                      </router-link>
                    </div>
                  </base-dropdown-v2>
                </div>
                <div class="col-2 pt-2 show-mobile p-0 pr-2 text-right"
                     v-if="!item.is_pending && !agroup_selecteds.length && !invoice">
                  <base-dropdown-v2>
                    <div>
                      <base-dropdown-item @click="handleCreateModalBilling(item.uuid)">
                        <img src="/img/icons/create-new.png" width="22px" height="22px" class="mt-n2"/> EDITAR CONTRATO
                      </base-dropdown-item>
                      <base-dropdown-item @click="setToAgroup(item, index)">
                        <i class="fa-solid fa-layer-group text-primary" style="font-size: 18px;margin-right: 4px;"></i> AGRUPAR
                      </base-dropdown-item>
                      <router-link :to="{
                      name: 'commercial.contract-proposal.edit',
                      path: '/commercial/contract-proposal/edit',
                      params: { contract_proposal_uuid: item.uuid }
                    }">
                        <base-dropdown-item>
                          <img src="/img/icons/icons8/ios/external-link-squared.png" width="19" height="19"/>
                          IR PARA O CONTRATO
                        </base-dropdown-item>
                      </router-link>
                    </div>
                  </base-dropdown-v2>
                </div>
                <div
                  v-if="contract_proposal_agroup == item.code && contract_proposal_agroup && !item.is_pending && !agroup_selecteds.includes(item.key) && agroup_selecteds.length > 0"
                  class="col-12 pt-2 text-center show-md">
                  <a href="#" @click.prevent="setToAgroup(item, index)"
                  >
                    <i class="fa-solid fa-layer-group fa-2x text-muted"
                       style="font-size: 24px;">
                    </i>
                    <i class="fa-regular fa-circle-check text-muted" style="font-size: 15px;"></i>
                  </a>
                </div>

                <div
                  v-if="contract_proposal_agroup == item.code && contract_proposal_agroup && !item.is_pending && !agroup_selecteds.includes(item.key) && agroup_selecteds.length > 0"
                  class="col-12 pt-2 text-center show-mobile">
                  <a href="#" @click.prevent="setToAgroup(item, index)"
                  >
                    <i class="fa-solid fa-layer-group fa-2x text-muted"
                       style="font-size: 18px;">
                    </i>
                    <i class="fa-regular fa-circle-check text-muted" style="font-size: 10px;"></i>
                  </a>
                </div>
                <div
                  v-if="!validPaymentTerm(item) && !item.is_pending && invoice && !item.has_pending_service && !item.has_pending_travels && item.payment_term_id && item.has_deduct && !item.is_pending && item.bank_account_id && item.payment_method_id && !Boolean(item.pending_message)"
                  class="col-2 pt-1 text-center">
                  <a href="#" @click.prevent="setInvoice(index, item.invoice)"
                  >
                    <img
                      :src="item.invoice ? '/img/icons/icons8/ios/checked-checkbox.png' : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
                      class="btn-send-emails" width="32"
                    >
                  </a>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 px-2">
                  <h3 style="font-size: 1.2rem;padding-top: -5px" class="font-weight-600">
                    {{ item.code }}
                    <el-popover trigger="click" placement="left" class="p-0 pr-0 mr-1">
                      <div class="row">
                        <div class="mt-n2 p-4">
                          <h3 slot="title">
                            <img class="mr-1" src="/img/icons/icons8/ios/chemical-plant-v1.png" width="23">
                            Central
                          </h3>
                          <div class="row align-items-center pb-0 mb-3">
                            <div class="col-md-12">
                              <h5 class="h5">
                                NOME: {{ item.company_plant_name }}
                              </h5>
                              <h5 class="h5 mt-n2">
                                CNPJ Emissor: {{ item.document }}
                              </h5>
                            </div>
                          </div>
                          <hr class="mt-n3 mb-2">
                          <h3 slot="title">
                            <img class="mr-1" src="/img/icons/salesman.png" width="23">
                            Vendedor
                          </h3>
                          <div class="row align-items-center pb-0 mb-3">
                            <div class="col-md-12">
                              <h5 class="h5"> NOME: {{ item.seller }}</h5>
                            </div>
                          </div>
                          <hr class="mt-n3 mb-2">
                          <h3 slot="title">
                            <img class="mr-1" src="/img/icons/icons8/ios/contract-job_primary.png" width="23">
                            Dados do cliente
                          </h3>
                          <div class="row align-items-center pb-0 mb-3">
                            <div class="col-md-12">
                              <h5 class="h5">
                                NOME: {{ item.customer_name }}
                              </h5>
                              <h5 class="h5 mt-n2">
                                CNPJ/CPF: {{ item.entity_document }}
                              </h5>
                              <h5 class="h5 mt-n2">
                                ENDEREÇO: {{ item.entity_addresses_address }}, {{ item.entity_addresses_number }} <br>
                                {{ item.entity_addresses_district }}, CEP: {{ item.entity_addresses_postal_code }},
                                {{ item.entity_addresses_city }}/{{ item.entity_addresses_state }}
                              </h5>
                            </div>
                          </div>
                          <hr class="mt-n3 mb-2">
                          <h3 slot="title" class="mt-2">
                            <img class="mr-1" src="/img/icons/icons8/ios/brick-wall_warning.png" width="23">
                            Dados da Obra
                          </h3>
                          <div class="row align-items-center pb-0">
                            <div class="col-md-12">
                              <h5 class="h5 mt-n2">
                                NOME: {{ item.construction_name }}
                              </h5>
                              <h5 class="h5 mt-n2">
                                ENDEREÇO:
                                {{ item.address }}, {{ item.number }} - {{ item.district }} <br>
                                {{ item.postal_code }}, {{ item.city }} / {{ item.state }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo">
                        <img src="/img/icons/info.png" width="22" style="margin-top: -5px">
                      </base-button>
                    </el-popover>
                    <el-popover v-if="item.has_pending_service || item.has_pending_travels" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                            <span v-if="item.has_pending_service && item.has_pending_travels" class="new-default-black-font font-weight-normal"> Há viagens e serviços em andamento </span>
                            <span v-if="item.has_pending_service && !item.has_pending_travels" class="new-default-black-font font-weight-normal"> Há serviços em andamento </span>
                            <span v-if="item.has_pending_travels && !item.has_pending_service" class="new-default-black-font font-weight-normal"> Há viagens em andamento </span>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo">
                        <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22" style="margin-top: -5px">
                      </base-button>
                    </el-popover>
                    <el-popover v-if="item.balance > 0" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                            <span  class="new-default-black-font font-weight-normal"> Saldo: {{ item.balance | currency() }} </span>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo">
                        <img src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png" width="22" style="margin-top: -5px">
                      </base-button>
                    </el-popover>
                  </h3>
                  <h5 class="font-weight-400 mt-n2 mb-n2">
                    <!--                    {{ item.situation_name }}-->
                  </h5>
                </div>
                <div class="col-10 px-2">
                  <h4 class="font-weight-400 ">
                    {{ item.customer_name }}
                  </h4>
                  <h4 class="font-weight-400 mt-n2">
                    {{ item.entity_document }}
                  </h4>
                </div>
                <div v-if="item.billing_guidelines" class="col-2 text-right">
                  <el-popover trigger="hover" placement="left" class="p-0 pr-0 mr-1">
                    <span>{{ item.billing_guidelines }}</span>
                    <base-button outline slot="reference" size="sm" type="secundary"
                                 class="p-0 m-0 shadow-none text-indigo">
                      <img style="width: 22px" src="/img/icons/icons8/ios/error--v1_danger.png"/>
                    </base-button>
                  </el-popover>
                </div>

                <div class="col-12 px-2">
                  <hr class="mt-n1 mb-1"/>
                  <h4 class="font-weight-400 mt-0">
                    {{ item.construction_name }}
                  </h4>
                  <h4 class="font-weight-400 mt-n2">
                    {{ item.city }} / {{ item.state }}
                  </h4>
                </div>
              </div>
              <div v-if="!agroup_selecteds.includes(item.key) && !item.is_pending" class="row px-0">
                <div class="col-md-4 px-2 pt-1">
                  <base-input style="font-size: 5px !important;" input-classes="form-control-sm">
                    <el-select style="font-size: 5px !important;" @change="getPaymentTerms(item)" size="mini"
                               v-model="item.payment_method_id"
                               :disabled="loadingMethod" placeholder="MÉTODO" filterable>
                      <el-option v-for="method in getPaymentMethods(item.payment_methods)" :key="method.id"
                                 :label="method.name"
                                 :value="method.id"></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-4 px-2 pt-1">
                  <base-input input-classes="form-control-sm">
                    <el-select @change="setRequireBalance(item)" size="mini" v-model="item.payment_term_id"
                               :disabled="loadingPaymentTerm || !item.payment_method_id" placeholder="CONDIÇÃO"
                               filterable>
                      <el-option v-for="term in payment_terms_by_method[item.id]" :key="term.id" :label="term.name"
                                 :value="term.id"></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-4 px-2 pt-1">
                  <base-input input-classes="form-control-sm">
                    <el-select size="mini" v-model="item.bank_account_id" :disabled="loadingBankAccounts"
                               placeholder="Conta" filterable>
                      <el-option v-for="item in getBankAccounts(item.bank_accounts)" :key="item.id"
                                 :label="formatAccountLabel(item)"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="row px-2 mt-n2 mb-3" v-if="!invoice && !item.is_pending && !agroup_selecteds.length">
              <div v-if="validRequiresBalance(item, 3) && item.payment_term_id" class="col-md-12 px-4 mt-n2 mb-2">
                <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige compensação igual ao crédito</span>
              </div>
              <div v-if="validRequiresBalance(item, 1) && item.payment_term_id" class="col-md-12 px-4 mt-n2 mb-2">
                <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige compensação total</span>
              </div>
              <div v-if="validRequiresBalance(item) && item.payment_term_id" class="col-md-12 px-4 mt-n2 mb-2">
                <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige crédito antecipado</span>
              </div>
              <template v-if="!item.has_deduct">
                <div class="col-12 px-4" >
                  <base-button type="light" block
                               class="fs-11 new-btn-light new-default-black-font"
                               style="padding: 0.45rem 0.5rem !important;text-transform: uppercase"
                               @click.prevent="handleCreateIss(item)"
                               size="sm">
                    <i class="fa-solid fa-triangle-exclamation text-danger"/>
                    {{ item.has_deduct_title }} (sem regra de iss cadastrada)
                  </base-button>
                </div>
              </template>
              <template v-else>
              <div class="col-12 px-4" v-if="!agroup_selecteds.length">
                <base-button v-if="!item.is_pending" :disabled="disableIndividualInvoice(item)" type="light" block
                             class="fs-11 new-btn-light new-default-black-font"
                             style="padding: 0.45rem 0.5rem !important;"
                             :style="disableIndividualInvoice(item) && 'opacity: 50%'"
                             @click.prevent="showModalIndividual(item)"
                             size="sm">
                  <img src="/img/icons/check.png" width="19"/> FATURAMENTO INDIVIDUAL
                </base-button>
              </div>
              <div v-else class="col-12 text-center" style="margin-top: -50px !important">
                <a href="#" @click.prevent="setToAgroup(item, index)" v-if="agroup_selecteds.includes(item.key)">
                  <i class="fa-solid fa-layer-group fa-2x"
                     style="font-size: 24px;">
                  </i>
                  <i class="fa-regular fa-circle-check " style="font-size: 15px;"></i>
                </a>
              </div>
              </template>
            </div>
            <div class="row px-2 mt-n2 mb-3" v-if="invoice && !item.is_pending">
              <template >
                <div class="col-12 px-4" >
                  <base-button v-if="!item.is_pending" :disabled="disableIndividualInvoice(item)" type="light" block
                               class="fs-11 new-btn-light new-default-black-font"
                               style="padding: 0.45rem 0.5rem !important;"
                               :style="disableIndividualInvoice(item) && 'opacity: 50%'"
                               @click.prevent="showModalIndividual(item, false)"
                               size="sm">
                    <img src="/img/icons/icons8/ios/choose_success.png" width="19"/> VISUALIZAR EM ABERTO
                  </base-button>
                </div>
              </template>
            </div>
            <div class="row mb-2" v-if="item.is_pending">
              <div class="col-12 mb-5 ml-n2 text-center">
                <i class="mb-2 fa-solid fa-robot fa-2x text-primary"></i>
                <br>
                <span class="new-default-black-font font-weight-400" style="opacity: 60%;">
                  Faturamento em segundo plano
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
    <div v-if="! agroup_selecteds.length && invoice" class="scrolling-box bg-white" style="margin-top: -2%">
      <base-button :disabled="invoiced === 0" @click.prevent="bill()" class="entry-launch-custom-float">
        <span class="btn-label"> <i class="far fa-file-alt"></i></span>
        <span style="font-size: 0.75rem">FATURAR SELECIONADOS</span> <span style="color: black !important;"
                                                                           class="badge badge-light text-dark">{{
          invoiced
        }} / {{
          contract_proposals.length
        }}</span>
      </base-button>
    </div>
    <div v-if="agroup_selecteds.length" class="scrolling-box bg-white" style="margin-top: -2%">
      <div class="agroup-float">
        <div class="container">
          <card>
            <div class="row">
              <div class="col-md-12 p-1">
                <base-button style="border-radius: 4px !important;" class="pointer"
                             :disabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !selected_agroup.has_deduct || !selected_agroup.bank_account_id"
                             block type="warning" @click.prevent="handleClickAgroup()">
                  <i class="fa-solid fa-layer-group"/>
                  <span style="font-size: 0.75rem">FATURAR AGRUPADOS</span> <span style="color: black !important;"
                                                                                  class="badge badge-light text-dark">{{
                    agroup_selecteds.length
                  }}</span>
                </base-button>
              </div>
              <div class="col-md-12 p-1">
                <base-button style="border-radius: 4px !important;" class="pointer"
                             :disabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !selected_agroup.has_deduct || !selected_agroup.bank_account_id"
                             block type="primary" @click.prevent="showModalIndividualWithFilter(selected_agroup)">
                  <i class="fa-solid fa-layer-group"/>
                  <span style="font-size: 0.75rem">VISUALIZAR AGRUPADOS</span>
                </base-button>
              </div>
              <div class="col-md-6 p-1">
                <base-input input-classes="form-control-sm " placeholder="CENTRAL">
                  <puzl-select
                    :clearable="false"
                    @change="getCompanyPlantIssuer();getBankAccountsByPlantId(filter_agroup.company_plant_id)"
                    placeholder="CENTRAL"
                    style="min-width: 200px"
                    v-model="filter_agroup.company_plant_id"
                    :items="$_plants"
                  />
                </base-input>
              </div>
              <div class="col-md-6 p-1">
                <base-input input-classes="form-control-sm">
                  <base-input input-classes="form-control-sm " placeholder="CNPJ EMISSOR">
                    <puzl-select
                      @change="getIss"
                      label="ein"
                      :clearable="false"
                      placeholder="CNPJ EMISSOR"
                      style="min-width: 200px"
                      v-model="filter_agroup.company_plant_issuer_id"
                      :items="$_company_plant_issuers"
                    />
                  </base-input>
                </base-input>
              </div>
              <div class="col-md-4 p-1">
                <base-input style="font-size: 5px !important;" input-classes="form-control-sm">
                  <el-select style="font-size: 5px !important;" @change="getPaymentTerms(selected_agroup)" size="mini"
                             v-model="selected_agroup.payment_method_id"
                             :disabled="loadingMethod" placeholder="MÉTODO" filterable>
                    <el-option v-for="method in getPaymentMethods(selected_agroup.payment_methods)" :key="method.id"
                               :label="method.name"
                               :value="method.id"></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-4 p-1">
                <base-input input-classes="form-control-sm">
                  <el-select @change="setRequireBalance(selected_agroup)" size="mini"
                             v-model="selected_agroup.payment_term_id"
                             :disabled="loadingPaymentTerm || !selected_agroup.payment_method_id" placeholder="CONDIÇÃO"
                             filterable>
                    <el-option v-for="term in payment_terms_by_method[selected_agroup.id]" :key="term.id"
                               :label="term.name"
                               :value="term.id"></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-4 p-1">
                <base-input input-classes="form-control-sm">
                  <el-select size="mini" v-model="selected_agroup.bank_account_id" :disabled="loadingBankAccounts"
                             placeholder="Conta" filterable>
                    <el-option v-for="item in agroup_bank_accounts" :key="item.id"
                               :disabled="!filter_agroup.company_plant_id"
                               :label="item.bank_account.name"
                               :value="item.bank_account_id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-12 px-2">
                <hr class="mb-1 mt-2">
              </div>
              <div class="col-12 px-2 pl-3 d-flex mt-2">
                <label
                  style="font-size: 11px;font-weight: 400 !important;"
                  class="label form-control-label"
                >
                  Reter ISS
                </label>
                <base-input class="ml-2">
                  <base-switch :disabled="!selected_agroup.has_deduct" v-model="selected_agroup.iss_retain"
                               type="danger"/>
                </base-input>
              </div>
              <div class="col-12 mt-n2 text-left">
                <label
                  style="font-size: 11px"
                  class="label form-control-label text-danger"
                  :class="hasAnyFederalTaxRetained(selected_agroup) ? 'text-danger' : 'text-gray'"
                >
                  Retenção federal
                </label>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none"
                  @click.prevent="showModalFederalTaxes(selected_agroup)"
                >
                  <i
                    class="fa-solid fa-hand-holding-dollar text-darkred"
                    :class="hasAnyFederalTaxRetained(selected_agroup) ? 'text-darkred' : 'text-gray'"
                  />
                </base-button>
              </div>
              <div style="margin-left: -32px" class="col-md-7 show-md px-2  text-right float-right"
                   v-if="selected_agroup.balance > 0">
                <div class="float-right text-right d-flex">
                  <label
                    style="font-size: 11px;font-weight: 400 !important;"
                    class="label form-control-label"
                  >
                    COMPENSAR ({{ selected_agroup.balance | currency() }})
                  </label>
                  <base-input class="ml-2">
                    <base-switch :disabled="requireBalance(selected_agroup)"
                                 v-model="selected_agroup.use_anticipation_balance"
                                 type="indigo"/>
                  </base-input>
                </div>
              </div>
              <div class="col-md-12 show-mobile px-2" v-if="selected_agroup.balance > 0">
                <div class="float-right text-right d-flex">
                  <label
                    style="font-size: 11px;font-weight: 400 !important;"
                    class="label form-control-label"
                  >
                    COMPENSAR ({{ selected_agroup.balance | currency() }})
                  </label>
                  <base-input class="ml-2">
                    <base-switch :disabled="requireBalance(selected_agroup)"
                                 v-model="selected_agroup.use_anticipation_balance"
                                 type="indigo"/>
                  </base-input>
                </div>
              </div>
              <div class="col-md-12 px-0" v-if="!selected_agroup.has_deduct">
                <base-button
                  block size="sm"
                  type="danger"
                  outline
                  class="text-uppercase"
                  @click.prevent="handleCreateIss(selected_agroup)"
                >
                  <i class="fa-solid fa-triangle-exclamation"/>
                  {{ selected_agroup.has_deduct_title }} (sem regra de iss cadastrada)
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal-individual @agrouped="handleClickAgroup" @closeAndUpdateInvoiced="handleCloseModalIndividual"
                      ref="modalIndividual"/>
    <modal-federal-taxes ref="modalFederalTaxes"/>
    <ModalBilling ref="modalBilling"/>
    <deduction-rule-creation-modal ref="DeductionRuleCreationModal" :updateObject="updateObject"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import {mapGetters} from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlSelect from "@/components/PuzlSelect";
import ModalIndividual from "../Shared/_ModalIndividual"
import LoadingPagination from "@/components/LoadingPagination";
import ModalBilling from "./_ModalBilling";
import ModalFederalTaxes from "./_ModalFederalTaxes";
import {strToNum, parseDate} from "@/helpers";
import DeductionRuleCreationModal
  from "@/views/Modules/Configuration/FeesAndTaxes/DeductionRules/Shared/_ModalCreateValidity.vue";
import BaseDropdownV2 from '@/components/Utils/BaseDropdown.vue'
import BaseDropdownItem from "@/components/Utils/BaseDropdownItem.vue";

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    ModalIndividual,
    LoadingPagination,
    ModalBilling,
    ModalFederalTaxes,
    DeductionRuleCreationModal,
    BaseDropdownV2,
    BaseDropdownItem
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      filter_agroup: {
        company_plant_id: null,
        company_plant_issuer_id: null,
      },
      agroup_bank_accounts: [],
      selected_agroup: null,
      contract_proposal_agroup: null,
      agroup_selecteds: [],
      invoiced: 0,
      invoice: 0,
      loadingSkeleton: false,
      loadingCompanyPlant: false,
      loadingPaymentTerm: false,
      loadingMethod: false,
      loadingPaymentIntermediaries: false,
      loadingPlant: false,
      loadingBankAccounts: false,
      filter: {
        by_measurement: 0
      },
      payment_terms_by_method: {},
      payment_intemerdiaries_selected: {},
      currentItem: null,
    }
  },
  async mounted() {
    await this.$store.dispatch('generalSetting/show');
    this.bankAccounts = true

    await this.$store.dispatch('bankAccount/fetchItems', {})
      .then(response => {
        this.bankAccounts = false
      })
    this.invoiced = 0
    this.load()
    this.loadingSkeleton = true
    this.$store.state.billingInvoice.open_billing = []
    this.init()
  },
  computed: {
    ...mapGetters({
      contract_proposals: "billingInvoice/getListOpenBilling",
      company_plants: "plant/activeItems",
      payment_terms: "paymentTerm/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      payment_methods: "paymentMethod/fetch",
      $_bank_accounts: 'bankAccount/fetch',
      $_plants: "plant/activeItems",
      $_company_plant_issuers: "plant/getCompanyPlantIssuers",
      $_general_settings: "generalSetting/show",
    }),
  },
  methods: {
    handleInvoice() {
      if (this.invoice) {
        this.contract_proposals.map((item) => item.invoice = false)
        this.invoiced = 0
        this.invoice = false
      } else {
        this.invoice = true
      }
    },
    handleFilterByMeasurment() {
      this.filter.by_measurement = +!this.filter.by_measurement
      this.init(this.filter)
    },
    setAnticipation(item) {
      if (!!this.requireBalance(item)) {
        return;
      }
      item.use_anticipation_balance = Boolean(!item.use_anticipation_balance)
    },
    getPaymentMethods(payment_methods) {
      const payment_method_splited = payment_methods.split(',').map(function (item) {
        return Number(item)
      })
      return this.payment_methods.filter((item) => payment_method_splited.includes(item.id))
    },
    setIss(item) {
      if (!item.has_deduct) {
        this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Não há regra de dedução cadastrada. Deseja cadastrar agora?',
          showCancelButton: true,
          confirmButtonText: 'Sim, cadastrar agora',
          cancelButtonText: 'Não, cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.handleCreateIss(item)
          }
        })
      }
      if (item.has_deduct && !item.iss_retain) {
        item.iss_retain = true
      } else {
        item.iss_retain = false
      }
    },
    getBankAccountsByPlantId(company_plant_id) {
      let loader = this.$loading.show()
      this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {company_plant_id: company_plant_id}).then((response) => {
        this.agroup_bank_accounts = response.data
        let default_bank_account = this.agroup_bank_accounts.find((item) => item.default)
        this.selected_agroup.bank_account_id = default_bank_account.bank_account_id
        loader.hide()
      })
    },
    getCompanyPlantIssuer() {
      let loader = this.$loading.show()
      this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
        id: this.filter_agroup.company_plant_id
      }).then(() => {
        let default_issuer = this.$_company_plant_issuers.find((item) => item.default)
        this.filter_agroup.company_plant_issuer_id = default_issuer.id
        this.getIss()
        loader.hide()
      })
    },
    handleCloseModalIndividual(key) {
      let index = this.contract_proposals.findIndex((item) => item.key === key)
      this.contract_proposals[index].is_pending = true
    },
    disableIndividualInvoice(item) {
      return (!item.has_deduct || !item.bank_account_id || item.has_pending_service || item.has_pending_travels || item.has_pending_service || item.has_pending_travels || item.is_pending || !item.payment_method_id || item.pending_message || !item.payment_term_id || this.validPaymentTerm(item))
    },
    async getIss() {
      let loader = this.$loading.show()
      this.$store.dispatch('FeesAndTaxes/existsISSQNBetweenEntityAndIssuer', {
        construction_id: this.selected_agroup.construction_id, issuerId: this.filter_agroup.company_plant_issuer_id
      }).then(response => {
        if (!response.data) {
          this.selected_agroup.has_deduct = false
        } else {
          this.selected_agroup.has_deduct = true
        }
      })
      let active_retained_rates = []
      await this.$store.dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuer", {
        entityId: this.selected_agroup.entity_id, issuerId: this.filter_agroup.company_plant_issuer_id
      }).then(async response => {
        if (!this.selected_agroup.block_iss) {
          if (!response.data.length) {
            this.selected_agroup.iss_retain = false
          } else {
            const has_issqn = response.data.some((item) => item.name = 'ISSQN')
            if (has_issqn) {
              this.selected_agroup.iss_retain = true
            }
          }
          active_retained_rates = response.data
          let tax_regime = this.$_company_plant_issuers.find((item) => item.id == this.filter_agroup.company_plant_issuer_id).tax_regime
          await this.getAllCurrentTaxes(this.selected_agroup.city, tax_regime, active_retained_rates)
        }
      })
      loader.hide()
    },
    getAllCurrentTaxes(
      constructionCity,
      issuerRegime,
      active_retained_rates
    ) {
      let form_data = {}
      let params = {
        constructionCity: constructionCity,
        issuerRegime: issuerRegime,
      };
       this.$store
        .dispatch("deductionRules/getAllCurrentTaxes", params)
        .then((response) => {
          response.data.forEach(federalTax => {
            this.selected_agroup[federalTax.name.toLowerCase() + '_rate'] = federalTax.retained_rate
              ? parseFloat(federalTax.retained_rate)
              : 0;
            let has_active_retained_rate = active_retained_rates.find((item) => item.name == federalTax.name);

            this.selected_agroup[federalTax.name.toLowerCase() + '_retained_flag'] = Boolean(has_active_retained_rate);
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    showModalIndividualWithFilter(item) {
      const plant_ids = []
      const plant_issuer_ids = []
      this.contract_proposals.forEach(function (contract_proposal) {
        if (contract_proposal.invoice === true) {
          plant_ids.push(contract_proposal.company_plant_id)
          plant_issuer_ids.push(contract_proposal.company_plant_issuer_id)
        }
      });
      let copy = {...item}
      let loader = this.$loading.show()
      const payment_method_selected = this.payment_methods.find((item) => item.id === copy.payment_method_id).name
      const payment_term_selected = this.payment_terms.find((item) => item.id === copy.payment_term_id).name
      const company_plant_selected = this.company_plants.find((item) => item.id === this.filter_agroup.company_plant_id).name
      const company_plant_issuer_selected = this.$_company_plant_issuers.find((item) => item.id === this.filter_agroup.company_plant_issuer_id).ein
      copy.company_plant = company_plant_selected
      copy.company_plant_issuer = company_plant_issuer_selected

      copy.payment_method = payment_method_selected
      copy.payment_term = payment_term_selected
      const key = copy.key.split('-')
      const date = {
        start: parseDate(this.range.start, 'YYYY-MM-DD'),
        end: parseDate(this.range.end, 'yyyy-MM-DD'),
      }
      copy.filter = {
        company_plant_ids: plant_ids,
        id: key[1],
        range: date,
        agrouped: 1,
        new_company_plant_id: this.filter_agroup.company_plant_id,
        new_company_plant_issuer_id: this.filter_agroup.company_plant_issuer_id,
        company_plant_issuer_ids: plant_issuer_ids,
      }
      this.$store
        .dispatch(
          "contractProposal/show",
          copy.uuid,
        )
        .then(() => {
          this.$refs.modalIndividual.handleCreate(copy)
          loader.hide()
        }).catch(() => {
        loader.hide()
      });
    },
    showModalIndividual(item, invoice = true) {
      if (!item.has_deduct) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: `${item.has_deduct_title} (sem regra de iss cadastrada)`,
        })
      }
      if (item.has_pending_service || item.has_pending_travels) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: `Existem entregas em andamento`,
        })
      }
      if (this.disableIndividualInvoice(item)) {
        return
      }
      let copy = {...item}
      let loader = this.$loading.show()
      const payment_method_selected = this.payment_methods.find((item) => item.id === copy.payment_method_id)?.name
      const payment_term_selected = this.payment_terms.find((item) => item.id === copy.payment_term_id)?.name
      copy.company_plant = item.company_plant_name
      copy.company_plant_issuer = copy.document
      copy.plant_issuer_id = copy.company_plant_issuer_id
      copy.payment_method = payment_method_selected
      copy.payment_term = payment_term_selected
      const key = copy.key.split('-')
      const date = {
        start: parseDate(this.range.start, 'YYYY-MM-DD'),
        end: parseDate(this.range.end, 'yyyy-MM-DD'),
      }
      copy.filter = {
        company_plant_id: key[0],
        id: key[1],
        range: date,
        company_plant_issuer_id: key[2],
      }
      this.$store
        .dispatch(
          "contractProposal/show",
          copy.uuid,
        )
        .then(() => {
          this.$refs.modalIndividual.handleCreate(copy, invoice)
          loader.hide()
        }).catch(() => {
        loader.hide()
      });
    },
    handleFilterSubmit(filter) {
      this.range = filter
      this.invoiced = 0
      this.init({})
    },
    filterHaveToday() {
      if (this.filter.range) {
        return moment(this.filter.range.end).format('y-M-D') === moment(new Date()).format('y-M-D') ||
          moment(this.filter.range.start).format('y-M-D') === moment(new Date()).format('y-M-D')
      }
      return false
    },
    /**
     * valida configurações de condição de pagamento
     */
    validPaymentTerm(item) {
      let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
      if (!payment_term) {
        return true
      }
      if (payment_term.requires_balance && payment_term.requires_balance == 2) {
        if (Number(item.balance) > 0 && !item.use_anticipation_balance) {
          return true
        }
        if (Number(item.balance) < 1) {
          return true
        }
      }
      return false
    },
    validRequiresBalance(item, requires_balance) {
      let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
      if (!payment_term) {
        return false
      }
      if (payment_term.requires_balance == requires_balance) {
        return true
      }
      return false
    },
    requireBalance(item) {
      let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
      if (!payment_term) {
        return false
      }
      return Boolean(payment_term.requires_balance)
    },
    setRequireBalance(item) {
      let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
      if (!item.use_anticipation_balance && payment_term.requires_balance) {
        item.use_anticipation_balance = 1
      }
    },
    /**
     * Recebe a fatura a ser agrupada
     * @param item
     * @param index
     */
    setToAgroup(item, index) {
      let invoice = this.contract_proposals[index].invoice
      this.contract_proposals[index].invoice = !invoice
      if (this.agroup_selecteds.length === 0) {
        this.filter_agroup.company_plant_id = null
        this.filter_agroup.company_plant_issuer_id = null
        this.filter_agroup.bank_account_id = null
        this.selected_agroup = null
      }
      if (this.agroup_selecteds.includes(item.key)) {
        const index = this.agroup_selecteds.indexOf(item.key);
        this.agroup_selecteds.splice(index, 1);
        if (this.agroup_selecteds.length === 0) {
          this.contract_proposal_agroup = null
          this.filter_agroup.company_plant_id = null
          this.filter_agroup.company_plant_issuer_id = null
          this.selected_agroup = null
        }
        return
      }
      if (!this.selected_agroup) {
        this.selected_agroup = {...item}
        this.selected_agroup.bank_account_id = null
      }
      this.contract_proposal_agroup = item.code
      this.agroup_selecteds.push(item.key)
    },
    setInvoice(index, value) {
      this.contract_proposals[index].invoice = !value
      !value ? this.invoiced += 1 : this.invoiced -= 1
    },
    handleClickAgroup(params) {
      this.$Swal.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          const items = this.contract_proposals.filter(function (item) {
            if (item.invoice === true) {
              item.is_pending = true
            }
            return item.invoice === true
          });
          // const cofinsRate = this.selected_agroup.cofins_retained_flag && strToNum(this.selected_agroup.cofins_rate) > 0
          //   ? strToNum(this.selected_agroup.cofins_rate)
          //   : 0;
          // const csllRate = this.selected_agroup.csll_retained_flag && strToNum(this.selected_agroup.csll_rate) > 0
          //   ? strToNum(this.selected_agroup.csll_rate)
          //   : 0;
          // const inssRate = this.selected_agroup.inss_retained_flag && strToNum(this.selected_agroup.inss_rate) > 0
          //   ? strToNum(this.selected_agroup.inss_rate)
          //   : 0;
          // const irRate = this.selected_agroup.ir_retained_flag && strToNum(this.selected_agroup.ir_rate) > 0
          //   ? strToNum(this.selected_agroup.ir_rate)
          //   : 0;
          // const pisRate = this.selected_agroup.pis_retained_flag && strToNum(this.selected_agroup.pis_rate) > 0
          //   ? strToNum(this.selected_agroup.pis_rate)
          //   : 0;
          // this.selected_agroup.cofins_rate = cofinsRate
          // this.selected_agroup.csll_rate = csllRate
          // this.selected_agroup.inss_rate = inssRate
          // this.selected_agroup.ir_rate = irRate
          // this.selected_agroup.pis_rate = pisRate
          // const filtered = {
          //   id: items[0].id,
          //   iss_retain: items[0].iss_retain,
          //   cofins_rate: cofinsRate,
          //   csll_rate: csllRate,
          //   inss_rate: inssRate,
          //   ir_rate: irRate,
          //   pis_rate: pisRate,
          //   payment_term_id: items[0].payment_term_id,
          //   payment_method_id: items[0].payment_method_id,
          //   payment_intermediary_id: items[0].payment_intermediary_id,
          //   bank_account_id: items[0].bank_account_id,
          //   considere_prepayment: items[0].considere_prepayment,
          //   use_anticipation_balance: items[0].use_anticipation_balance,
          //   company_plant_id: items[0].company_plant_id,
          //   company_plant_issuer_id: items[0].company_plant_issuer_id,
          // }
          // if (params) {
          //   filtered.concreteds = params.concreteds
          //   filtered.services = params.services
          //   filtered.additionals = params.additionals
          //   filtered.single = params.single
          // }
          //
          const selecteds = items.map(function (item) {
            const cofinsRate = this.selected_agroup.cofins_retained_flag && strToNum(this.selected_agroup.cofins_rate) > 0
              ? strToNum(this.selected_agroup.cofins_rate)
              : 0;
            const csllRate = this.selected_agroup.csll_retained_flag && strToNum(this.selected_agroup.csll_rate) > 0
              ? strToNum(this.selected_agroup.csll_rate)
              : 0;
            const inssRate = this.selected_agroup.inss_retained_flag && strToNum(this.selected_agroup.inss_rate) > 0
              ? strToNum(this.selected_agroup.inss_rate)
              : 0;
            const irRate = this.selected_agroup.ir_retained_flag && strToNum(this.selected_agroup.ir_rate) > 0
              ? strToNum(this.selected_agroup.ir_rate)
              : 0;
            const pisRate = this.selected_agroup.pis_retained_flag && strToNum(this.selected_agroup.pis_rate) > 0
              ? strToNum(this.selected_agroup.pis_rate)
              : 0;
            const filtered = {
              id: item.id,
              iss_retain: item.iss_retain,
              cofins_rate: cofinsRate,
              csll_rate: csllRate,
              inss_rate: inssRate,
              ir_rate: irRate,
              pis_rate: pisRate,
              payment_term_id: item.payment_term_id,
              payment_method_id: item.payment_method_id,
              payment_intermediary_id: item.payment_intermediary_id,
              bank_account_id: item.bank_account_id,
              considere_prepayment: item.considere_prepayment,
              use_anticipation_balance: item.use_anticipation_balance,
              company_plant_id: item.company_plant_id,
              company_plant_issuer_id: item.company_plant_issuer_id,
            }
            if (params) {
              filtered.concreteds = params.concreteds
              filtered.services = params.services
              filtered.additionals = params.additionals
              filtered.single = params.single
            }
            return filtered
          }, this)

          this.$forceUpdate()
          const date = {
            start: parseDate(this.range.start, 'YYYY-MM-DD'),
            end: parseDate(this.range.end, 'yyyy-MM-DD'),
          }
          this.$store.dispatch('billingInvoice/postSetAgroup', {
            items: selecteds,
            selected_agroup: this.selected_agroup,
            filter_agroup: this.filter_agroup,
            range: date
          }).then(response => {
            this.$notify({type: response.error_type, message: response.message});
            this.invoiced = 0
            this.$refs.modalIndividual.close()
            this.agroup_selecteds = []
            loader.hide()
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
            loader.hide()
          })
        }
      });
    },
    getBankAccounts(bank_accounts) {
      const bank_accounts_splited = bank_accounts.split(',').map(function (item) {
        return Number(item)
      })
      return this.$_bank_accounts.filter(item => {
        return bank_accounts_splited.includes(item.id)
      })
    },
    load() {
      this.loadingPaymentTerm = true;
      this.$store.dispatch("paymentTerm/fetchItems").then(() => {
        this.loadingPaymentTerm = false;
      });
      this.loadingCompanyPlant = true;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingCompanyPlant = false;
      });
      this.loadingPaymentIntermediaries = true
      this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
        this.loadingPaymentIntermediaries = false
      })
      this.loadingMethod = true
      this.$store.dispatch("paymentMethod/fetchItems")
        .then(() => {
          this.loadingMethod = false;
        }).catch(() => {
      })
    },
    bill() {
      this.$Swal.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          const items = this.contract_proposals.filter(function (item) {
            if (item.invoice === true) {
              item.is_pending = true
            }
            return item.invoice === true
          });
          this.$forceUpdate()
          const selecteds = items.map(function (item) {
            const cofinsRate = item.cofins_retained_flag && strToNum(item.cofins_rate) > 0
              ? strToNum(item.cofins_rate)
              : 0;
            const csllRate = item.csll_retained_flag && strToNum(item.csll_rate) > 0
              ? strToNum(item.csll_rate)
              : 0;
            const inssRate = item.inss_retained_flag && strToNum(item.inss_rate) > 0
              ? strToNum(item.inss_rate)
              : 0;
            const irRate = item.ir_retained_flag && strToNum(item.ir_rate) > 0
              ? strToNum(item.ir_rate)
              : 0;
            const pisRate = item.pis_retained_flag && strToNum(item.pis_rate) > 0
              ? strToNum(item.pis_rate)
              : 0;
            return {
              id: item.id,
              company_plant_id: item.company_plant_id,
              iss_retain: item.iss_retain,
              cofins_rate: cofinsRate,
              csll_rate: csllRate,
              inss_rate: inssRate,
              ir_rate: irRate,
              pis_rate: pisRate,
              payment_term_id: item.payment_term_id,
              payment_method_id: item.payment_method_id,
              payment_intermediary_id: item.payment_intermediary_id,
              bank_account_id: item.bank_account_id,
              considere_prepayment: item.considere_prepayment,
              use_anticipation_balance: item.use_anticipation_balance,
              company_plant_issuer_id: item.company_plant_issuer_id,
            }
          })
          const date = {
            start: parseDate(this.range.start, 'YYYY-MM-DD'),
            end: parseDate(this.range.end, 'yyyy-MM-DD'),
          }
          this.$store.dispatch('billingInvoice/generateInvoices', {
            items: selecteds,
            range: date
          }).then(response => {
            this.$notify({type: response.error_type, message: response.message});
            this.invoiced = 0
            loader.hide()
          }).catch(error => {
            loader.hide()
          }).finally(() =>
            this.invoice = false
          )
        }
      });
    },
    getPaymentTerms(item) {
      const payment_terms_splited = item.payment_terms.split(',').map(function (item) {
        return Number(item)
      })
      item.payment_term_id = null
      item.payment_intermediary_id = null
      let paymentMethod = this.payment_methods.find((self) => self.id === item.payment_method_id)
      this.payment_terms_by_method[item.id] = this.payment_terms.filter(function (selected) {
        let has_method = selected.payment_methods.find((method) => method.uuid === paymentMethod.uuid)
        if (has_method && payment_terms_splited.includes(selected.id)) {
          return selected
        }
      })
      // BUSCA MEDIADOR DE PAGAMENTO
      this.payment_intemerdiaries_selected[item.id] = paymentMethod.payment_intermediaries
      item.payment_intermediaries_exists = paymentMethod.payment_intermediaries.length > 0
      if (this.payment_terms_by_method[item.id].length === 1) {
        item.payment_term_id = this.payment_terms_by_method[item.id][0].id
      }
      this.$forceUpdate()
    },
    formatAccountLabel(item) {
      return item.name
    },
    init(filter = null) {
      if (filter && filter.hasOwnProperty('global')) {
        this.invoiced = 0
        this.invoice = 0
        this.agroup_selecteds = []
      }
      this.startCursor(filter)
      this.$store.dispatch('billingInvoice/getAllProposalsWithOpenBilling', {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      }).then(response => {
        this.resolveCursor(response)
        this.contract_proposals.map(function (item, index) {
          if (! item.payment_methods.includes(',')) {
            this.contract_proposals[index].payment_method_id = Number(item.payment_methods)
          }
          if (! item.payment_terms.includes(',')) {
            this.contract_proposals[index].payment_term_id = Number(item.payment_terms)
          }
          this.$forceUpdate()
        }, this)
        this.contract_proposals.map(function (item) {
          if (item.payment_methods.length === 1) {
            this.getPaymentTerms(item)
          }
        }, this)
        this.contract_proposals.map(function (item, index) {
          if (item.payment_term_id) {
            let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
            if (!item.use_anticipation_balance && payment_term.requires_balance) {
              this.contract_proposals[index].use_anticipation_balance = 1
            }
          }
          // Impostos Federais Padrão
          item.default_cofins_rate = item.cofins_rate;
          item.default_csll_rate = item.csll_rate;
          item.default_inss_rate = item.inss_rate;
          item.default_ir_rate = item.ir_rate;
          item.default_pis_rate = item.pis_rate;
        }, this)
      }).catch(error => {
        this.resolveCursor()
      })
    },
    /**
     * Abre modal de faturamento do contrato
     *
     * @param {string} uuid - contract uuid
     */
    handleCreateModalBilling(uuid) {
      this.$refs.modalBilling.handleCreateModal(uuid);
    },
    handleFilterDate(filter, default_filter = 1) {
      this.filter_by_default = default_filter
      this.range = filter
      this.init({})
    },
    clearFilter() {
      this.filter = {
        range_nfse_number: {
          min: null,
          max: null
        },
        range_invoice_value: {
          min: null,
          max: null
        },
        range_invoice_number: {
          min: null,
          max: null
        },
        order_by: null,
        without_car: 0,
        nfse_pending_issue: 0,
        email_dont_sended: 0,
        with_iss: 0,
      }
      this.filter.canceled = 0
      this.filter.not_canceled = 1
      this.filter.verify_status = 0
      this.filter.filter_by_default = 1
      this.init({})
    },
    showModalFederalTaxes(item) {
      let loader = this.$loading.show();
      const key = item.key.split('-');
      const date = {
        start: parseDate(this.range.start, 'YYYY-MM-DD'),
        end: parseDate(this.range.end, 'yyyy-MM-DD'),
      }
      const filter = {
        company_plant_id: key[0],
        id: key[1],
        range: date,
        company_plant_issuer_id: key[2],
      }
      this.$store
        .dispatch(
          "contractProposal/show",
          item.uuid,
        )
        .then(() => {
          this.$refs.modalFederalTaxes.handleCreate(item, filter)
          loader.hide()
        }).catch(() => {
        loader.hide()
      });
    },
    hasAnyFederalTaxRetained(item) {
      return (item.cofins_retained_flag && strToNum(item.cofins_rate) > 0) ||
        (item.csll_retained_flag && strToNum(item.csll_rate) > 0) ||
        (item.inss_retained_flag && strToNum(item.inss_rate) > 0) ||
        (item.ir_retained_flag && strToNum(item.ir_rate) > 0) ||
        (item.pis_retained_flag && strToNum(item.pis_rate) > 0);
    },
    handleCreateIss(item) {
      this.currentItem = item;
      const ISSQN_ID = 14;
      const COUNTRY = 'BR';
      const MODAL_DETAIL = `${item.state} - ${item.city} - ${item.tax_regime}`;
      const params = {
        commercialTaxId: ISSQN_ID,
        state: item.state,
        city: item.city,
        regime: item.tax_regime,
      };
      const loader = this.$loading.show();
      this.$store
        .dispatch("TaxLocality/getByData", params)
        .then((response) => {
          // Se já existir regra, cadastrar apenas a alíquota
          const alreadyExists = response.data !== null;
          if (alreadyExists) {
            const tax = response.data;
            this.$refs.DeductionRuleCreationModal.handleCreateModal(tax, MODAL_DETAIL);
            loader.hide();
            return;
          }
          // Se não existir regra, cadastra a mesma e também a alíquota
          const payload = {
            commercial_tax_id: ISSQN_ID,
            status: true,
            allow_issqn_retention_of_legal_person: true,
            allow_issqn_retention_of_natural_person: false,
            allow_issuance_without_customer_municipal_registration: true,
            state: item.state,
            city: item.city,
            country: COUNTRY,
            regime: item.tax_regime,
            expiration_day: 0,
          };
          this.$store
            .dispatch("TaxLocality/add", payload)
            .then((response) => {
              const tax = response.data;
              this.$refs.DeductionRuleCreationModal.handleCreateModal(tax, MODAL_DETAIL);
              loader.hide();
            })
            .catch((error) => {
              const errors = error && error.response && error.response.status === 422
                ? formatErrorValidation(error.response.data.errors)
                : error.data.message;
              this.$notify({type: "danger", message: errors});
              loader.hide();
            });
        })
        .catch((error) => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.errors)
            : error.data.message;
          this.$notify({type: "danger", message: errors});
          loader.hide();
        });
    },
    updateObject() {
      this.init({});
    },
  }
}
</script>

<style scoped>
label {
  font-size: 0.8rem;
}

.entry-launch-custom-float {
  position: fixed;
  bottom: 150px;
  right: 40px;
  background-color: #1a70b7 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}


@media only screen and (min-width: 600px) {
  .agroup-float {
    z-index: 1 !important;
    position: fixed;
    top: 2% !important;
    right: 15px;
    width: 500px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .agroup-float {
    z-index: 1 !important;
    position: fixed;
    bottom: 30% !important;
    width: 100vw !important;
    text-align: center;
  }
}
</style>
