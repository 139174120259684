export const AppIconVariants = Object.freeze({
  defaultVariants: Object.freeze({
    color: "black",
    icon: "default.svg",
  }),
  variants: Object.freeze({
    color: Object.freeze({
      dark: "dark",
      white: "white",
      primary: "primary",
      success: "success",
      warning: "warning",
      danger: "danger",
      muted: "muted",
    }),
    svg: Object.freeze({
      user: "/svgs/user.svg"
    }),
    png: Object.freeze({
      default: "/pngs/default.svg.png", //Deixei so pra caso tenha que fazer com PNG tambem
    }),
  }),
});