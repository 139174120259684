<template>
  <div>
    <AppFixedPageTitle
      title="Referência comercial"
      icon="/img/icons/physics-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isListing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        />
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    
    <AppTabSelect
      :items="[]"
      :isShowViewTypes="false"
    >
      <a href="#" class="float-right mt-n2 ml-n4" @click.prevent="downloadExcel">
        <img
          style="width: 28px"
          src="/img/icons/excel.png" 
        />
      </a>
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid mt-4">
      <CmcListPageTable/>
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" /> <br>
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import cmcStore from '../../store';
import { initCmcListFilterType, CmcListFilterType, CmcExcelFilterType, initCmcExcelFilterType} from '../../types';
import { dialogs, progress } from '../../../../../helpers';
import CmcListPageTable from './CmcListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  AppSearchBarFilterSection
} from '../../../../../components/AppGlobal';

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {CmcListFilterType} - Filtro principal */
const filter = reactive(initCmcListFilterType());

/** @type {CmcExcelFilterType} - Filtro principal */
const filterExcel = reactive(initCmcExcelFilterType());

/** Store Getters */
const listed = computed(() => cmcStore.getListed());
const isListing = computed(() => cmcStore.getIsListing());

const { proxy } = getCurrentInstance();
//const $_companyPlants = ref(null);
const localPlants = localStorage.getItem('companyPlants');
if (localPlants) {
  const plants = JSON.parse(localPlants);
  proxy.$store.commit('plant/SET_ACTIVE', plants.data);
  //$_companyPlants.value = proxy.$store.getters['plant/activeShortItems'];
} else {
  proxy.$store.dispatch("plant/getPlantsSimplified").then((response) => {
    proxy.$store.commit('plant/SET_ACTIVE', response.data);
    //$_companyPlants.value = proxy.$store.getters['plant/activeShortItems'];
  });
}
    
const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [{
        column: "cmcs.id",
        is_desc: false,
      }],
    },
    {
      id: 1,
      name: "A - Z (Categoria)",
      selected_name: "Mais novo",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmc_categories.name",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "Z - A (Categoria)",
      selected_name: "Mais velho",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmc_categories.name",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "Preço maior para o menor",
      selected_name: "Preço maior",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmc_prices.price",
        is_desc: true,
      }],
    },
    {
      id: 4,
      name: "Preço menor para o maior",
      selected_name: "Preço menor",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmc_prices.price",
        is_desc: false,
      }],
    },
    {
      id: 5,
      name: "Data maior para o menor",
      selected_name: "Data maior",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmcs.created_at",
        is_desc: true,
      }],
    },
    {
      id: 6,
      name: "Data menor para o maior",
      selected_name: "Data menor",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmcs.created_at",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  cmcStore.resetStates();
  cmcStore.setPageState({ filter, searchBarFilter });
});

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {

  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? cmcStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {CmcListFilterType}
 */
function defaultFilter() {
  return {
    ...initCmcListFilterType(),
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {  
  prepareFilter();
  cmcStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
};

function downloadExcel() {
  filterExcel.filter.company_plant_id = searchBarFilter.company_plant_selected;
  progress.start();
  cmcStore.downloadExcel(filterExcel)
    .then(() => {
      dialogs.notify('success', 'Solicitação realizada com sucesso! O relatório será exportado em segundo plano.');
    })
    .finally(() => {
      progress.finish();
    });
};

</script>

<style scoped>
</style>