<template>
  <div>
    <base-nav
      container-classes="container-fluid"
      class="navbar-top border-bottom navbar-expand"
      :class="{ 'bg-primary navbar-dark': type === 'default' }"
    >
      <!-- Search form -->
      <form
        class="navbar-search form-inline mr-sm-3"
        :class="{
          'navbar-search-light': type === 'default',
          'navbar-search-dark': type === 'light'
        }"
        id="navbar-search-main"
      >
        <!--        antigo pesquisar programas-->

        <!--        <div class="form-group mb-0">-->
        <!--          <div class="input-group input-group-alternative input-group-merge">-->
        <!--            <autocomplete-->
        <!--              class="auto-complete-search"-->
        <!--              :search="search"-->
        <!--              aria-label="Pesquisar programas"-->
        <!--              placeholder="Pesquisar programas"-->
        <!--              default-value=""-->
        <!--              :value="searchBar"-->
        <!--              @submit="handleSubmitSearch"-->
        <!--            >-->
        <!--            </autocomplete>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <button-->
        <!--          type="button"-->
        <!--          class="close"-->
        <!--          data-action="search-close"-->
        <!--          data-target="#navbar-search-main"-->
        <!--          aria-label="Close"-->
        <!--        >-->
        <!--          <span aria-hidden="true">×</span>-->
        <!--        </button>-->
      </form>
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center mr-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div
            class="pr-3 sidenav-toggler"
            :class="{
              active: $sidebar.showSidebar,
              'sidenav-toggler-dark': type === 'default',
              'sidenav-toggler-light': type === 'light'
            }"
            @click="toggleSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>

        <div class="row" v-if="this.$route.meta.global_filter">
          <div class="col-12">
            <AppViewTrigger @onIntersected="setFilterPlantId" :delayOnMounted="0"/>
            <puzl-select
              placeholder="Selecionar central"
              style="min-width: 200px"
              @input="setGlobalFilter()"
              v-model="global_filter.plant_id"
              :items="$_plants"
              :disabled="filter_disabled"/>
          </div>
        </div>
      </ul>
      <ul class="navbar-nav align-items-center ml-auto mr-n2" style="z-index: 3 !important;">
        <div class="d-flex mr-n2">
          <a @click.prevent="showExportModal()" href="#" class="nav-item" :style="$_completed_exports ? 'margin-top: 9px' : 'margin-top: 2px'">
            <img src="/img/icons/icons8/ios/download-from-cloud--v1.png" width="27px"/>
          </a>
          <span class="badge text-white badge-default"
                v-show="$_completed_exports"
                style="margin-left: -4px;font-size: 9px;margin-top: 25px;background: red;z-index: 4 !important;">{{formatNotificationLength($_completed_exports)}}</span>
        </div>
        <div class="d-flex">
          <base-dropdown-icon
            style="z-index: 3"
            :style="notifications.length && 'margin-top: 4px'"
            class="nav-item"
            tag="li"
            title-classes="nav-link"
            title-tag="a"
            ref="dropdown"
            :custom-icon="'/img/icons/icons8/ios/alarm--v1.png'"
            menu-classes="dropdown-menu-xl dropdown-menu-right py-0 mt-2"
          >
            <div style="cursor: auto;z-index: 3 !important;margin-top: 10px"
                 class="px-3 py-3 d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-sm text-muted m-0">
                  Você possui <strong class="text-danger"> {{ notifications.length }}</strong> notificações não lidas.
                </h6>
              </div>
              <div v-if="notifications.length">
                <el-popover trigger="hover" placement="bottom" class="p-0">
                  <span>Marcar todos como lido</span>
                  <div @click.prevent="readAllNotifications" slot="reference">
                <span class="pointer avatar avatar-sm-2 rounded-circle bg-white">
                  <img style="width: 15px" src="/img/icons/double-tick.png">
                </span>
                  </div>
                </el-popover>
              </div>
            </div>
            <!-- List group -->
            <div style="overflow: auto;max-height: 70vh;cursor: auto;z-index: 3 !important;" class="px-4">
              <div @click.prevent="readNotification(index)" v-for="(item, index) in notifications" class="row p-2">
                <div v-if="item" class="col-md-12 px-0"
                     :style="index !== notifications.length - 1 ? 'margin-bottom: -2.7rem' : 'margin-bottom: -1.8rem'">
                  <card>
                    <div style="cursor: pointer" class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mt-n1" :class="'text-'+($_types[item.type]?.type || 'default-class')">
                          {{ $_types[item.type]?.name || '' }}
                        </h4>
                      </div>
                    </div>
                    <h4 v-for="data in item.details.data" class="mt-n1 mb-0 text-sm font-weight-400">{{ data }}</h4>
                    <div class="mt-n1 d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0 text-sm font-weight-400">{{ item.details.user_name }}</h4>
                      </div>
                    </div>
                    <div class="mt-n1 d-flex justify-content-between align-items-center">
                      <div style="margin-bottom: -10px">
                        <small class="">{{ item.details.date_at }}</small>
                      </div>
                      <div class="d-flex">
                        <el-popover v-if="item.details.observations" trigger="hover" placement="bottom" class="p-0">
                          <h4 class="mt-n1 mb-0 text-sm font-weight-400" style="max-width: 14vw;">
                            {{ item.details.observations }}</h4>
                          <img slot="reference" class="pr-2" src="/img/icons/speech-bubble-with-dots--v1-black.png"
                               height="22px"/>
                        </el-popover>
                        <el-popover trigger="hover" placement="bottom" class="p-0">
                          <h4 v-for="detail in item.details.details" class="mt-n1 mb-0 text-sm font-weight-400">
                            {{ detail }}</h4>
                          <img slot="reference" src="/img/icons/info.png" height="22px"/>
                        </el-popover>
                      </div>
                    </div>
                  </card>
                </div>
                <!--                          <div class="col-auto">-->
                <!--                            &lt;!&ndash; Avatar &ndash;&gt;-->
                <!--                            <img-->
                <!--                              style="background: red"-->
                <!--                              alt="Image placeholder"-->
                <!--                              src="/img/icons/finance-white.png"-->
                <!--                              class="avatar rounded-circle"-->
                <!--                            />-->
                <!--                          </div>-->
              </div>
            </div>
            <!-- View all -->
            <div class="row mt-4">
              <div class="col-md-12 px-4 text-center">
                <router-link to="/configuration/notification">
                  <a href="#" @click.passive="closeDropdown">
                    <h5>Ver todas</h5>
                  </a>
                </router-link>
              </div>
            </div>
          </base-dropdown-icon>
          <span class="badge text-white badge-default"
                v-show="notifications.length"
                style="font-size: 9px;margin-top: 25px;background: red;z-index: 4 !important;margin-left: -20px;">
            {{formatNotificationLength(notifications.length)}}
          </span>
        </div>
        <base-dropdown
          menu-on-right
          class="nav-item"
          tag="li"
          title-tag="a"
          title-classes="nav-link pr-0"
        >
          <a
            href="#"
            class="nav-link pr-0"
            @click.prevent
            slot="title-container"
          >
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="/img/theme/no-img-profile.png"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">
                  {{ shortFirstLastName }}
                </span>
              </div>
            </div>
          </a>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bem Vindo!</h6>
            </div>
            <a class="dropdown-item" @click="handleUserUpdateProfile(user)">
              <i class="ni ni-single-02"></i>
              <span>Meus Dados</span>
            </a>
            <router-link to="/support/logs" class="dropdown-item">
              <i class="fa-brands fa-algolia"></i>
              <span>Registro de atividade</span>
            </router-link>
            <router-link v-if="has_totvs" to="/totvs/report" class="dropdown-item">
              <i class="fas fa-download text-primary"></i>
              <span>Integrações Totvs</span>
            </router-link>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Suporte</span>
            </a>
            <router-link v-if="$hasPrivilege(1)" :to="{ path:'/support/deploy'}">
              <a v-if="$hasPrivilege(1)" href="#!" class="dropdown-item">
                <i class="fa-solid fa-rocket text-indigo"></i>
                <span>Deploy</span>
              </a>
            </router-link>
            <router-link to="/tutorial" class="dropdown-item">
              <i class="fa-brands fa-youtube text-danger"></i>
              <span>Tutorial</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>
                Sair
              </span>
            </router-link>
          </template>
        </base-dropdown>
      </ul>
    </base-nav>
    <!--    todo layout-->
    <!--  <div class="row">-->
    <!--    <div class="container-fluid ml-3">-->
    <!--      <div class="row align-items-center py-4">-->
    <!--        <div class="col-lg-6 col-7">-->
    <!--          <h6 class="h2 text-gray d-inline-block mb-0">-->
    <!--            {{route.meta.breadcrumbs[1].title}}-->
    <!--          </h6>-->
    <!--          <nav-->
    <!--            aria-label="breadcrumb"-->
    <!--            class="d-none d-md-inline-block ml-md-4 text-gray"-->
    <!--          >-->
    <!--            <ol class="breadcrumb">-->
    <!--              <li class="breadcrumb-item">-->
    <!--                <a href="#"><i class="fa fa-home"></i></a>-->
    <!--              </li>-->
    <!--              <li class="breadcrumb-item">-->
    <!--                <a href="#">{{ route.meta.breadcrumbs[0].title }}</a>-->
    <!--              </li>-->
    <!--              <li class="breadcrumb-item" aria-current="page">-->
    <!--                {{ route.meta.breadcrumbs[1].title }}-->
    <!--              </li>-->
    <!--            </ol>-->
    <!--          </nav>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <UserCard ref="userUpdateProfile"/>
    <ModalTableExport ref="ModalTableExport"/>
  </div>
</template>

<script>
import {BaseNav} from "@/components";
import {mapGetters} from "vuex";
import UserCard from "../Pages/UserProfile/UserCard";
import PuzlSelect from "@/components/PuzlSelect";
import popDown from '/public/sounds/happy-pop.mp3'
import notificationExported from '/public/sounds/notification_exported.mp3'
import emptyTrash from '/public/sounds/empty_trash.mp3'
import BaseDropdownIcon from "@/components/BaseDropdownIcon.vue";
import {hasTotvs} from "@/plugins/microservices/totvs";
import TableExport from './Shared/_ModalTableExport.vue'
import ModalTableExport from "@/views/Layout/Shared/_ModalTableExport.vue";
import * as Websocket from '@/helpers/ws';
import { AppViewTrigger } from "../../components/AppGlobal";

export default {
  components: {
    ModalTableExport,
    BaseNav,
    UserCard,
    PuzlSelect,
    BaseDropdownIcon,
    AppViewTrigger,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)"
    }
  },
  computed: {
    route() {
      return this.$route
    },
    routeName() {
      const {name} = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    shortFirstLastName() {
      const names = this.user.short_name.split(' ');
      if (names.length === 1) {
        return names[0];
      } else {
        return `${names[0]} ${names[names.length - 1]}`;
      }
    },
    ...mapGetters({
      user: "auth/getUser",
      tags: "tag/fetch",
      menus: "menu/fetch",
      $_plants: "plant/activeItems",
      $_types: "notification/getTypes",
      $_completed_exports: "exports/getCompleted",
    })
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchBar: null,
      notifications: [],
      notificationSound: new Audio(popDown),
      emptyTrashSound: new Audio(emptyTrash),
      notificationExportedSound: new Audio(notificationExported),
      msg: '',
      global_filter: {
        plant_id: null,
      },
      filter_disabled: false,
      has_totvs: false,
    };
  },
  beforeMount() {
    this.setFilterPlantId();
  },
  beforeUpdate() {
    this.sortPlants();
  },
  methods: {
    setFilterPlantId() {
      const companyPlantId = localStorage.getItem('company_plant_id');
      const sessionCompanyPlantId = sessionStorage.getItem('company_plant_id');
      if (companyPlantId || sessionCompanyPlantId) {
        this.global_filter.plant_id = sessionCompanyPlantId ? +sessionCompanyPlantId : +companyPlantId;
        this.$filterable = sessionCompanyPlantId ? +sessionCompanyPlantId : +companyPlantId;
      }
    },
    showExportModal() {
      this.$refs.ModalTableExport.openModal()
    },
    /**
     * Retorna as notificações formatadas
     * @param length
     */
    formatNotificationLength(length) {
      if (length <= 99) {
        return length
      }
      return '99+'
    },
    closeDropdown() {
      this.$refs.dropdown.closeDropDown()
    },
    readNotification(index) {
      let loader = this.$loading.show()
      this.$store.dispatch('notification/putReadNotification', this.notifications[index].id)
        .then(() => {
          this.emptyTrashSound.play()
          this.notifications.splice(index, 1)
          loader.hide()
        })
    },
    readAllNotifications() {
      let loader = this.$loading.show()
      let notification_ids = this.notifications.map((item) => item.id)
      this.$store.dispatch('notification/putReadAllNotifications', {ids: notification_ids})
        .then(() => {
          this.notifications = []
          loader.hide()
        })
    },
    handleUserUpdateProfile(user) {
      this.$refs.userUpdateProfile.handleCreateModal(user)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async setGlobalFilter() {
      if (this.global_filter.plant_id === null) {
        localStorage.removeItem('company_plant_id')
        sessionStorage.removeItem('company_plant_id')
        return this.$filterable = ''
      }
      localStorage.setItem('company_plant_id', this.global_filter.plant_id)
      sessionStorage.setItem('company_plant_id', this.global_filter.plant_id)
      this.$filterable = this.global_filter.plant_id
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    handleSubmitSearch(result) {
      let menu = this.menus.filter(item => item.name == result);
      if (menu.length > 0) {
        this.$router.push("/" + menu[0].link);
      }
      this.toggleSearch();
      this.searchBar = null;
    },
    search(input) {
      this.searchBar = input;
      // caso input esteja vazio o retornamos.
      if (input.length < 1) {
        return [];
      }
      // filtramos todas as tags com o valor correspondente ao input do usuario e armazenamos em tags
      let tags = this.tags.filter(tag => {
        return tag.name.toLowerCase().includes(input.toLowerCase());
      });
      let menus = this.menus.filter(menu => {
        return menu.name.toLowerCase().includes(input.toLowerCase());
      });
      let menu_titles = [];
      menus.forEach(function (item, index) {
        if (!menu_titles.includes(item.name)) menu_titles.push(item.name);
      });
      // percorrendo todos os menus e inserindo no array
      tags.forEach(function (item, index) {
        item.menus.forEach(function (menu, index_menu) {
          if (!menu_titles.includes(menu.name)) menu_titles.push(menu.name);
        });
      });
      return menu_titles;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSearch() {
      var x = document.getElementById("mobileSearch");
      if (x.style.visibility === "hidden" || window.getComputedStyle(x).getPropertyValue('visibility') === "hidden") {
        x.style.removeProperty("height");
        x.style.visibility = "visible";
        x.style.opacity = "1";
      } else {
        x.style.visibility = "hidden";
        x.style.opacity = "0";
        x.style.height = "0px";
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    getCompanyPlants() {
      this.filter_disabled = true;
      this.$store.dispatch("plant/getCompanyPlantForGlobalFilter").then((response) => {
        localStorage.setItem('companyPlants', JSON.stringify(response));
        this.$store.commit('plant/SET_ACTIVE', response);
        this.$store.commit('plant/SET', response);
        this.filter_disabled = false;
      });
    },
    sortPlants() {
      this.$_plants.sort(function (obj1, obj2) {
        return obj1.name < obj2.name ? -1 : (obj1.name > obj2.name ? 1 : 0);
      });
    },
    getCompleted(notify = true) {
      this.$store.dispatch('exports/getCompleted').then(response => {
        if (notify) {
          this.notificationExportedSound.play()
        }
      })
    }
  },
  mounted() {
    let host = window.location.host
    let subdomain = host.split('.')[0]
    let user_id = this.$user.id
    const channel = `${subdomain}_${user_id}`
    const exported_channel = `${subdomain}_${user_id}_exported`
    if (Websocket.socket) {
      Websocket.socket.onmessage = (event) => {
          const response = JSON.parse(event.data)
          switch (response.channel) {
            case channel:
              this.notifications.unshift(response.data)
              this.notificationSound.play()
              break;
            case exported_channel:
              this.getCompleted()
              break;
          }
      }
    }
  },
  created() {
    // Tenta carregar centrais do local storage
    // para restaurar dados globais do Vuex no caso de um recarregamento da página com F5.
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.$store.commit('plant/SET', plants.data);
    } else {
      this.getCompanyPlants();
    }

    this.$store.dispatch("user/getUserNotifications", this.$user.id)
      .then((response) => {
        let notifications = response.data || []
        this.notifications = notifications.concat(this.notifications)
      })
    this.global_filter.plant_id = ''
    EventBus.$on("logout", () => {
      this.$store.dispatch("auth/signOut");
      this.$router.push("/login");
    });
    this.$store.dispatch("tag/fetchItems");
    this.$store.dispatch("menu/fetchItems");
    this.getCompleted(false)
    // informa se cliente possui integração totvs
    this.has_totvs = hasTotvs();
  }
};
</script>

<style>
.d-smx-none {
  display: none !important;
}

#mobileSearch {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 1000ms, visibility 1000ms;
  transition: opacity 1000ms, visibility 1000ms;
}

.autocomplete-input,
.autocomplete-result-list {
  font-size: 0.875rem !important;
}

.autocomplete-input {
  background-color: transparent !important;
  border: none !important;
}

.autocomplete-input:focus,
.autocomplete-input {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  #mobileSearch {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .navbar-search {
    display: none !important;
  }

  .d-smx-none {
    display: initial !important;
  }
}

#vs1__combobox {
  background: whitesmoke;
}

</style>
