<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <card>
            <div class="row">
              <div class="col-md-6 pl-2 pr-2 pb-2 ">
                <label>Período do carregamento</label>
                <input-date-picker :filterable="4" size="small" :default-range="range" ref="date-picker"
                                   @handleFilterDate="handleFilterDate"/>
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Filtro de centrais
                </label>
                <puzl-select
                  v-model="filter.company_plant_uuids"
                  :items="$_company_plants"
                  customKey="uuid"
                  @change="init"
                  multiple
                  placeholder="Centrais"
                />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Contrato
                </label>
                <puzl-select
                  v-model="filter.contract_proposal_id"
                  :items="$_contract_proposals"
                  @change="init"
                  :loading="loadingContractProposals"
                  placeholder="Contrato"
                />
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Traço
                </label>
                <puzl-select
                  v-model="filter.mix_code"
                  :items="$_mix_codes"
                  @change="init"
                  :loading="loadingMixCodes"
                  placeholder="Traço"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
      <section>
        <div v-if="!loadingWidgets" class="row align-items-center mt-n4">
          <div class="col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p style="font-family: FreeMono, monospace;"
                       class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      VOLUME TOTAL</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape bg-success rounded-circle float-right"><i
                      class="text-white fa-solid fa-chart-line"></i></div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{
                          Number($_widgets.total_volume).toFixed(1).toString().replace('.', ',')
                        }} </span>
                      <br>
                      &nbsp;
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      Aglomerante</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape rounded-circle float-right"
                         style="background-color: #7e7e7e">
                      <i class="text-white fa-solid fa-ellipsis"></i>
                    </div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{ Math.round($_widgets.avg_binder) }}
                      <br>
                      &nbsp;
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      PUZL ADJUST</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape rounded-circle float-right"
                         style="background-color: #7e7e7e">
                      <i class="text-white fa-solid fa-ellipsis"></i>
                    </div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span
                        :class="($_widgets.total_theorical_adjusted_mcc - $_widgets.total_theorical_not_adjusted) > 0 ? 'text-danger' : 'text-success'"
                        class="numeric">
                         {{
                          $_widgets.total_theorical_adjusted_mcc - $_widgets.total_theorical_not_adjusted | currency()
                        }}
                        <br>
                      {{
                          (($_widgets.total_theorical_adjusted_mcc / $_widgets.total_volume) - ($_widgets.total_theorical_not_adjusted / $_widgets.total_volume) || 0) | currency()
                        }}
                      <span class="text-lowercase">/m<sup>3</sup></span>
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets col-md-3 col-sm-6 p-1">
            <div class="mb-3 card">
              <div class="p-3 card-body">
                <div class="row">
                  <div class="col-8" style="margin-top: -10px">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">
                      MCC Teórico</small></p>
                  </div>
                  <div class="col-4" style="margin-top: -5px">
                    <div class="text-center shadow icon icon-shape rounded-circle float-right"
                         style="background-color: #7e7e7e">
                      <i class="text-white fa-solid fa-ellipsis"></i>
                    </div>
                  </div>
                  <div class="col-md-12" style="margin-top: -25px">
                    <h4 class="font-weight-semibold mb-0 pl-2" style="font-family: 'Gill Sans', sans-serif;">
                      <span class="numeric">{{
                          (($_widgets.total_theorical_adjusted_mcc / $_widgets.total_volume) || 0) | currency()
                        }} <span class="text-lowercase">/m<sup>3</sup></span></span>
                      <br>
                      &nbsp;
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row align-items-center">
          <div v-for="item in 4" class="col-md-3 col-sm-6 p-1">
            <div class="mb-3 card" style="height: 80px">
              <div class="card-body">
                <div class="row">
                  <div class="col-9" style="margin-top: -10px">
                    <skeleton-puzl type="small"/>
                  </div>
                  <div class="col-2 text-right float-right" style="margin-top: -15px">
                    <skeleton-puzl type="circle"/>
                  </div>
                  <div class="col-5" style="margin-top: -22px">
                    <skeleton-puzl type="small"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="row pt-0 mt-n2">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <select-concrete-piece @load="handleCodeFilterConcretePiece" class="mb-3"/>
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-left">Traço</th>
                      <th v-show="filter.concrete_piece_agroup_code" class="text-left">Peça a concretar</th>
                      <th class="text-center">Volume</th>
                      <th class="text-center">Aglomerante</th>
                      <th class="text-center">Mcc teórico</th>
                      <th class="text-center">Puzl Adjust</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 5" v-show="loadingSkeletonByCode">
                      <th colspan="6">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_items_by_code.data"
                        v-show="!loadingSkeletonByCode && $_items_by_code.data.length">
                      <td width="60%" class="text-left">{{ item.code }}</td>
                      <td v-show="filter.concrete_piece_agroup_code" class="text-left">{{ item.description }}</td>
                      <td width="30%" class="text-center">{{ item.total_volume }}</td>
                      <td width="10%" class="text-center">{{ Math.round(item.avg_binder) }}</td>
                      <td width="10%" class="text-center numeric">{{ item.theorical_mcc | currency() }} <span
                        class="text-lowercase">/m<sup>3</sup></span></td>
                      <td
                        :class="(item.total_theorical_adjusted_mcc - item.total_theorical_not_adjusted) > 0 ? 'text-danger' : 'text-success'"
                        width="10%" class="text-center numeric">
                        {{ item.total_theorical_adjusted_mcc - item.total_theorical_not_adjusted | currency() }}
                        ({{
                          ((item.total_theorical_adjusted_mcc / item.total_volume) - (item.total_theorical_not_adjusted / item.total_volume) || 0) | currency()
                        }}
                        <span class="text-lowercase">/m<sup>3</sup></span>)
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <puzl-empty-data
                    v-show="$_items_by_code.data && !$_items_by_code.data.length && !loadingSkeletonByCode"/>
                </div>
                <div class="col-md-12">
                  <pagination @navegate="navegateByCode" :source="$_items_by_code"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <th colspan="7" class="text-centr"
                        style="font-weight: 900 !important;background: rgb(201 201 201 / 37%);color: white;"><h4
                      style="padding: 0px !important; margin-bottom: 0px; font-weight: 500 !important; font-size: 0.85rem;">
                      <img data-v-5ae7d093="" src="/img/icons/icons8/puzzle.png" class="pointer"
                           style="height: 1.5rem;"> PUZL ADJUST</h4></th>
                    <tr class="fixed">
                      <th class="text-left">PUZL ADJUST</th>
                      <th class="text-center">Aglomerante</th>
                      <th class="text-center">Água</th>
                      <th class="text-center">T.Argamassa</th>
                      <th class="text-center">Ar total</th>
                      <th class="text-center">Aditivo A</th>
                      <th class="text-center">Aditivo B</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 5" v-show="loadingSkeletonAdjustments">
                      <th colspan="7">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_adjustments"
                        v-show="!loadingSkeletonAdjustments && $_adjustments.length">
                      <td class="numeric">{{ item.title }} ({{ item.count }})</td>
                      <td :class="getClassAdjustment(item.binder)" class="text-center numeric">
                        {{ toFixed(item.binder, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.water)" class="text-center numeric">{{
                          toFixed(item.water, 1)
                        }}
                      </td>
                      <td :class="getClassAdjustment(item.content_mortar)" class="text-center numeric">
                        {{ toFixed(item.content_mortar, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.total_air)" class="text-center numeric">
                        {{ toFixed(item.total_air, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.aditive_1)" class="text-center numeric">
                        {{ toFixed(item.aditive_1, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.aditive_2)" class="text-center numeric">
                        {{ toFixed(item.aditive_2, 1) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <puzl-empty-data
                    v-show="!$_adjustments.length && !loadingSkeletonAdjustments"/>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <th colspan="7" class="text-centr"
                        style="font-weight: 900 !important;background: rgb(201 201 201 / 37%);color: white;"><h4
                      style="padding: 0px !important; margin-bottom: 0px; font-weight: 500 !important; font-size: 0.85rem;">
                      <img data-v-5ae7d093="" src="/img/icons/icons8/puzzle.png" class="pointer"
                           style="height: 1.5rem;"> PUZL ADJUST POR MCC</h4></th>
                    <tr class="fixed">
                      <th class="text-left">MCC</th>
                      <th class="text-center">Aglomerante</th>
                      <th class="text-center">Água</th>
                      <th class="text-center">T.Argamassa</th>
                      <th class="text-center">Ar total</th>
                      <th class="text-center">Aditivo A</th>
                      <th class="text-center">Aditivo B</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 5" v-show="loadingSkeletonMccAdjustments">
                      <th colspan="7">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="item in $_mcc_adjustments"
                        v-show="!loadingSkeletonMccAdjustments && $_mcc_adjustments.length">
                      <td class="numeric">{{ item.title }} ({{ item.count }})</td>
                      <td :class="getClassAdjustment(item.binder)" class="text-center numeric">
                        {{ toFixed(item.binder, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.water)" class="text-center numeric">{{
                          toFixed(item.water, 1)
                        }}
                      </td>
                      <td :class="getClassAdjustment(item.content_mortar)" class="text-center numeric">
                        {{ toFixed(item.content_mortar, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.total_air)" class="text-center numeric">
                        {{ toFixed(item.total_air, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.aditive_1)" class="text-center numeric">
                        {{ toFixed(item.aditive_1, 1) }}
                      </td>
                      <td :class="getClassAdjustment(item.aditive_2)" class="text-center numeric">
                        {{ toFixed(item.aditive_2, 1) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <puzl-empty-data
                    v-show="!$_mcc_adjustments.length && !loadingSkeletonMccAdjustments"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from '@/components/Utils/Pagination'
import SelectConcretePiece from "../Shared/_SelectConcretePiece"
import SelectLocation from "../Shared/_SelectLocation"
import SelectEquipment from "../Shared/_SelectEquipment"
import PuzlSelect from "@/components/PuzlSelect";
import {toFixed} from "@/plugins";

export default {
  name: "ListResume",
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    Pagination,
    SelectConcretePiece,
    SelectLocation,
    SelectEquipment,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingWidgets: false,
      loadingSkeletonByCode: false,
      loadingSkeletonMccAdjustments: false,
      loadingSkeletonAdjustments: false,
      loadingMixCodes: false,
      loadingContractProposals: false,
      hasEquipment: false,
      sourceMoldingResume: null,
      sourceService: null,
      toFixed: toFixed,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      params: {
        page: 1,
        per_page: 5,
      },
      filter: {
        company_plant_uuids: null,
        concrete_piece_agroup_customer: 0,
        concrete_piece_agroup_code: 0,
        service_agroup_equipment: 0,
        dosage_location_agroup: 0,
        contract_proposal_id: null,
        mix_code: null,
        hasEquipment: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items_by_code: "MCCResume/fetchByCode",
      $_adjustments: "MCCResume/fetchAdjustments",
      $_mcc_adjustments: "MCCResume/fetchMccAdjustments",
      $_widgets: "MCCResume/fetchWidgets",
      $_mix_codes: "travels/getMixCodes",
      $_contract_proposals: "travels/getContractProposals",
    }),
  },
  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
    } else {
      this.$store.dispatch('plant/getPlantsSimplified');
    }

    this.load()
  },
  methods: {
    /**
     * Retorna a classe de cor com base no valor de ajuste.
     * @param {number} adjustment - O valor de ajuste a ser avaliado.
     * @returns {string} - A classe de cor correspondente.
     */
    getClassAdjustment(adjustment) {
      switch (true) {
        case !adjustment:
          return 'text-default';
        case adjustment > 0:
          return 'text-success';
        default:
          return 'text-danger';
      }
    },
    handleCustomerFilterConcretePiece(value) {
      this.filter.concrete_piece_agroup_customer = +value
      this.getByProposal()
    },
    handleFilterDosageLocation(value) {
      this.filter.dosage_location_agroup = +value
      this.getResume()
    },
    handleCodeFilterConcretePiece(value) {
      this.filter.concrete_piece_agroup_code = +value
      this.getByCode()
    },
    handleServiceEquipmentFilter(value) {
      this.filter.service_agroup_equipment = +value
      this.getServices()
    },
    handleFilterEquipment(value) {
      this.filter.hasEquipment = +value
      this.hasEquipment = !this.hasEquipment
      this.getMoldingResune()
    },
    navegateMoldingResume(page) {
      this.params.page = page
      this.getMoldingResune()
    },
    navegateService(page) {
      this.params.page = page
      this.getServices()
    },
    navegateByProposal(page) {
      this.params.page = page
      this.getByProposal()
    },
    navegateByCode(page) {
      this.params.page = page
      this.getByCode()
    },
    navegate(page) {
      this.params.page = page
      this.getResume()
    },
    getWidgets() {
      this.loadingWidgets = true
      this.$store.dispatch("MCCResume/getWidgets", {filter: this.filter})
        .then(() => this.loadingWidgets = false)
    },
    load() {
      this.init()
    },
    getByCode() {
      this.setDefaultParams()
      this.loadingSkeletonByCode = true
      this.$store.dispatch("MCCResume/fetchItemsByCode", this.params)
        .then(() => this.loadingSkeletonByCode = false)
    },
    getAdjustments() {
      this.setDefaultParams()
      this.loadingSkeletonAdjustments = true
      this.$store.dispatch("MCCResume/fetchAdjustments", this.params)
        .then(() => this.loadingSkeletonAdjustments = false)
    },
    getMccAdjustments() {
      this.setDefaultParams()
      this.loadingSkeletonMccAdjustments = true
      this.$store.dispatch("MCCResume/fetchMccAdjustments", this.params)
        .then(() => this.loadingSkeletonMccAdjustments = false)
    },
    setDefaultParams() {
      this.filter.range = this.range
      this.params.filter = this.filter;
    },
    init() {
      this.setDefaultParams()
      this.getWidgets()
      this.getByCode()
      this.getAdjustments()
      this.getMccAdjustments()
      this.getFilters()
    },
    getFilters() {
      this.loadingMixCodes = true
      this.loadingContractProposals = true
      this.$store.dispatch('travels/getMixCode', this.params)
        .then(() => this.loadingMixCodes = false)
      this.$store.dispatch('travels/getContractProposals', this.params)
        .then(() => this.loadingContractProposals = false)
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.load({});
    },
    decimals(category, value) {
      if (value == 0) {
        return 0
      }
      value = parseFloat(value)
      switch (category) {
        case 2:
        case 3:
        case 6:
          return parseInt(value)
        case 1:
          return value.toFixed(1)
        default:
          return value.toFixed(2)
      }
    },
  },
}
</script>
