import { StockLocationCreateType, StockLocationListFilterType, StockLocationListPagerType, StockLocationUpdateType, StockLocationViewType } from "../types";
import { stockLocationCreateService, stockLocationDeleteService, stockLocationFindService, stockLocationListService, stockLocationUpdateService } from "../services";
import mutations from "./stockLocationStoreMutations";
import getters from "./stockLocationStoreGetters";
import { createResource, findResource, listResources, removeResource, updateResource } from "../../../../shared/store";

/**
 * Incluir
 *
 * @param {StockLocationCreateType} data
 * @returns {Promise<StockLocationViewType>}
 */
const create = async (data) => await createResource(data, mutations, stockLocationCreateService);

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?StockLocationViewType>}
 */
const find = async (id) => await findResource(id, mutations, stockLocationFindService);

/**
 * Listar
 *
 * @param {StockLocationListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?StockLocationListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(filter, isAccumulateItems, mutations, getters, stockLocationListService);
};

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => await removeResource(id, mutations, stockLocationDeleteService);

/**
 * Atualizar por ID
 *
 * @param {StockLocationUpdateType} data
 * @param {number} id
 * @returns {Promise<StockLocationViewType>}
 */
const update = async (data, id) => await updateResource(data, id, mutations, stockLocationUpdateService);

export default {
  create,
  find,
  list,
  remove,
  update,
};
