<template>
  <div
    v-if="isSvgResult"
    v-bind="$attrs"
    v-on="$listeners"
    v-html="svgContent"
    :style="{ width: computedWidth, height: computedHeight }"
    :class="[color, { 'invert-on-hover': props.isHover }]"
    class="d-inline-flex app-icon"
  ></div>

  <img
    v-else
    :src="iconSrc"
    :width="width"
    :height="height"
    :class="{ 'invert-on-hover': props.isHover }"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { AppIconVariants } from "./AppIconVariants";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Boolean} isHover - Inverte a cor do icon PNG para branco no hover.
 * @property {String|null} icon - Nome da chave do icone em AppIconVariants.variants.icon
 * @property {Number|String|null} width - Largura do icone
 * @property {Number|String|null} height - Altura do icone
 * @property {String|null} color - Nome da chave da cor em AppIconVariants.variants.color
 */
/** @type {Props} */
const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: AppIconVariants.defaultVariants.icon,
  },
  width: {
    type: [Number, String],
    default: 16,
    required: false,
  },
  height: {
    type: [Number, String],
    default: "auto",
    required: false,
  },
  color: {
    type: String,
    default: AppIconVariants.defaultVariants.color,
    required: false,
    validator: (value) => {
      const isValid = value in AppIconVariants.variants.color;
      if (!isValid) {
        console.error(
          `Invalid fill color: ${value}. It should be a one of the following:
          \n ${Object.keys(AppIconVariants.variants.color).join(", ")}`
        );
      }
      return isValid;
    },
  },
  isHover: {
    type: Boolean,
  },
});

const isSvgResult = ref(false);
const svgContent = ref("");

const iconSrc = computed(() => {
  // TODO: Validar caminho do computed, se nao existir, joga o default que vai ser uma exclamacao
  const isIconPng = props.icon in AppIconVariants.variants.png;
  if (isIconPng) {
    return `/img/icons${AppIconVariants.variants.png[props.icon]}`;
  }

  console.error(
    `Invalid icon name: ${props.icon}. It should be a key in AppIconVariants.js.`
  );
  return `/img/icons/icons8/ios/svgs/${AppIconVariants.defaultVariants.icon}`;
});

const computedWidth = computed(() => `${props.width}px`);
const computedHeight = computed(() =>
  props.height !== "auto" ? `${props.height}px` : "auto"
);

async function loadSvg() {
  try {
    const baseUrl = "/img/icons";
    const fullUrl = `${baseUrl}${AppIconVariants.variants.svg[props.icon]}`;
    const response = await fetch(fullUrl);
    if (response.ok) {
      svgContent.value = await response.text();
    } else {
      console.error("Error loading SVG:", response.statusText);
    }
  } catch (error) {
    console.error("Error loading SVG:", error);
  }
}

onBeforeMount(async () => {
  const isSvgIcon = props.icon in AppIconVariants.variants.svg;
  isSvgResult.value = isSvgIcon;
  if (isSvgIcon) {
    await loadSvg();
  }
});
</script>

<style scoped lang="scss">
@import "./AppIcon.scss";

.invert-on-hover:hover {
  filter: brightness(0) invert(1);
  color: white;
}
</style>
