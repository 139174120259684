import { stockLocationUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';
import { StockLocationUpdateType, StockLocationViewType } from '../types';

/**
 * Atualizar por ID
 * 
 * @param {StockLocationUpdateType} data
 * @param {number} id
 * @returns {Promise<StockLocationViewType>}
 */
const execute = async (data, id) => {
  const url = `${stockLocationUrls.BASE}/${id}`;
  const res = await httpClient.put(url, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;  
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };