/**
 * Type para incluir
 *
 * @typedef {Object} StockLocationCreateType
 * @property {?number} id
 * @property {string} name
 * @property {boolean} status - [false-INATIVO, true-ATIVO]
 * @property {string} observation
 */
export const StockLocationCreateType = {};

/**
 * Inicializar StockLocationCreateType
 * 
 * @returns {StockLocationCreateType}
 */
export const initStockLocationCreateType = () => {
  return {
    name: '',
    status: true,
    observation: '',
  }
};
