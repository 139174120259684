<template>
  <div>
    <modal size="lg" :show.sync="modal.create" class="user-edit">
      <template slot="header">
        <img src="/img/icons/create-new.png" class="img-fluid" loading="lazy" />
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>

      <div class="row m-0 p-0" v-show="loadingModal">
        <div class="col-md-4 m-0 p-0">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-8">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-4 m-0 p-0">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-8">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-4 m-0 p-0">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-8">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-4 m-0 p-0">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
        <div class="col-md-8">
          <skeleton-puzl type="button"></skeleton-puzl>
        </div>
      </div>

      <div class="p-2" v-show="!loadingModal">
        <div class="modal-body-title col-12">
          <img
            src="/img/icons/icons8/ios/info-squared.png"
            class="img-fluid"
            alt=""
            title=""
            loading="lazy"
          />
          <h5>IDENTIFICAÇÃO</h5>
          <div class="hr"></div>
        </div>

        <div class="badge-row col-12">
          <div class="badge-wrapper col-md-3">
            <label>status</label>
            <a
              href="#"
              @click.prevent="changeStatus('status')"
              class="d-inline-block p-0"
            >
              <badge
                class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                :style="
                  user.status
                    ? 'color: #149E57; background-color: #F2F7F3;'
                    : 'color: #DB4539; background-color: #FCF3F3;'
                "
              >
                <img
                  :src="
                    user.status
                      ? '/img/icons/ok--v1.png'
                      : '/img/icons/shutdown.png'
                  "
                  class="img-fluid mr-2"
                  alt=""
                  title=""
                  loading="lazy"
                  width="16px"
                />
                {{ user.status ? "Ativo" : "Inativo" }}
              </badge>
            </a>
          </div>
          <div class="badge-wrapper col-md-3">
            <label> Usuário </label>
            <a
              href="#"
              @click.prevent="changeStatus('is_user')"
              class="d-inline-block p-0"
            >
              <badge
                class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                :style="
                  user.is_user
                    ? 'color: #149E57; background-color: #F2F7F3;'
                    : 'color: #DB4539; background-color: #FCF3F3;'
                "
              >
                <img
                  :src="
                    user.is_user
                      ? '/img/icons/ok--v1.png'
                      : '/img/icons/shutdown.png'
                  "
                  class="img-fluid mr-2"
                  alt=""
                  title=""
                  loading="lazy"
                  width="16px"
                />
                {{ user.is_user ? "Ativo" : "Inativo" }}
              </badge>
            </a>
          </div>
          <div class="badge-wrapper col-md-3">
            <label> Intermediador </label>
            <a
              href="#"
              @click.prevent="changeStatus('is_intermediary')"
              class="d-inline-block p-0"
            >
              <badge
                class="px-3 py-2 d-flex align-items-center justify-content-center"
                :style="
                  user.is_intermediary
                    ? 'color: #149E57; background-color: #F2F7F3;'
                    : 'color: #DB4539; background-color: #FCF3F3;'
                "
              >
                <img
                  :src="
                    user.is_intermediary
                      ? '/img/icons/ok--v1.png'
                      : '/img/icons/shutdown.png'
                  "
                  class="img-fluid mr-2"
                  alt=""
                  title=""
                  loading="lazy"
                  width="16px"
                />
                {{ user.is_intermediary ? "Ativo" : "Inativo" }}
              </badge>
            </a>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <!-- IDENTIFICAÇÃO -->

            <!-- NOME -->
            <div class="app-form-group">
              <label for="name" class="col-md-4"> Nome completo </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    id="name"
                    class="input"
                    type="text"
                    v-model="user.name"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- EMAIL -->
            <div class="app-form-group">
              <label for="email" class="col-md-4"> email </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <input
                    id="email"
                    class="input"
                    type="text"
                    v-model="user.email"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- REDEFINIR SENHA -->
            <div class="app-form-group">
              <label for="password" class="col-md-4"> Redefinir Senha </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider :rules="'min:6'" v-slot="{ errors }">
                  <input
                    id="password"
                    class="input"
                    type="password"
                    v-model="user.reset_password"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- CELULAR -->
            <div class="app-form-group">
              <label for="phone" class="col-md-4"> celular (trabalho) </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider v-slot="{ errors }">
                  <input
                    id="phone"
                    class="input"
                    type="numeric"
                    v-mask="['(##) # ####-####']"
                    v-model="user.phone"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- CÓDIGO EXTERNO -->
            <div class="app-form-group mb-32">
              <label for="code" class="col-md-4"> CÓDIGO EXTERNO </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider v-slot="{ errors }">
                  <input
                    id="code"
                    class="input"
                    type="text"
                    maxlength="10"
                    v-model="user.external_code"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- OPERACIONAL -->
            <div class="modal-body-title col-12">
              <img
                src="/img/icons/chemical-plant.png"
                class="img-fluid"
                alt=""
                title=""
                loading="lazy"
              />
              <h5>OPERACIONAL</h5>
              <div class="hr"></div>
            </div>

            <!-- MOTORISTA -->
            <div
              class="app-form-group justify-content-between mb-24 badge-reset"
            >
              <label class="col-md-4"> MOTORISTA </label>
              <div class="wrapper col-md-3 justify-content-end d-flex">
                <a
                  href="#"
                  @click.prevent="changeDriveStatus('driver')"
                  class="d-inline-block p-0 w-100"
                >
                  <badge
                    class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.driver
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.driver
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.driver ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
            </div>

            <!-- OPERACIONAL BADGE-ROW  -->
            <div class="badge-row col-12" v-show="user.driver">
              <!-- CONCRETO -->
              <div class="badge-wrapper col-md-3">
                <label> CONCRETO </label>
                <a
                  href="#"
                  @click.prevent="changeStatus('is_concrete')"
                  class="d-inline-block p-0"
                >
                  <badge
                    class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_concrete
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_concrete
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_concrete ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
              <!-- SERVIÇO -->
              <div class="badge-wrapper col-md-3">
                <label> serviço </label>
                <a
                  href="#"
                  @click.prevent="changeStatus('is_service')"
                  class="d-inline-block p-0"
                >
                  <badge
                    class="px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_service
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_service
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_service ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
              <!-- TERCEIRO -->
              <div class="badge-wrapper col-md-3">
                <label> terceiro </label>
                <a
                  href="#"
                  @click.prevent="changeStatus('is_external_service')"
                  class="d-inline-block p-0"
                >
                  <badge
                    class="px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_external_service
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_external_service
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_external_service ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
              <!-- INSUMO -->
              <div class="badge-wrapper col-md-3">
                <label> Insumo </label>
                <a
                  href="#"
                  @click.prevent="changeStatus('is_entry_driver')"
                  class="d-inline-block p-0"
                >
                  <badge
                    class="px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_entry_driver
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_entry_driver
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_entry_driver ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
            </div>

            <!-- CATEGORIA -->
            <div class="app-form-group" v-show="user.driver">
              <label for="email" class="col-md-4">
                Categoria de equipamento
              </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider v-slot="{ errors }">
                  <puzl-select
                    v-model="user.equipment_category_id"
                    :items="$_equipmentCategorys"
                    label="category_name"
                    :loading="loadingEquipmentsCategorys"
                    :disabled="loadingEquipmentsCategorys || !user.driver"
                    @input="getEquipmentByCategory"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- EQUIPAMENTO -->
            <div class="app-form-group mb-24" v-show="user.driver">
              <label for="email" class="col-md-4"> Equipamento padrão </label>
              <div class="wrapper col-md-8 flex-wrap">
                <validation-provider v-slot="{ errors }">
                  <puzl-select
                    v-model="user.equipment_default"
                    :items="$_equipmentsByCategory"
                    customKey="uuid"
                    :labelMask="true"
                    :loading="loadingEquipmentsCategorys"
                    :disabled="
                      loadingEquipmentsCategorys || !user.equipment_category_id
                    "
                    label="code$ | $equipment_types_description"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- COMERCIAL -->
            <div class="modal-body-title col-12 mt-2">
              <img
                src="/img/icons/icons8/ios/briefcase-green.png"
                class="img-fluid"
                alt=""
                title=""
                loading="lazy"
              />
              <h5>COMERCIAL</h5>
              <div class="hr"></div>
            </div>

            <!-- VENDEDOR -->
            <div
              class="app-form-group justify-content-between mb-24 badge-reset"
            >
              <label class="col-md-4"> VENDEDOR/REPRESENTANTE </label>
              <div class="wrapper col-md-3 justify-content-end d-flex">
                <a
                  href="#"
                  @click.prevent="checkSellerStatus('is_seller')"
                  class="d-inline-block p-0 w-100"
                >
                  <badge
                    class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_seller
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_seller
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_seller ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
            </div>

            <!-- RECEBER EMAIL -->
            <div
              class="app-form-group justify-content-between mb-24 badge-reset"
              v-show="user.is_seller"
            >
              <label class="col-md-5"> RECEBER E-MAILS DE FATURAMENTO </label>
              <div class="wrapper col-md-3 justify-content-end d-flex">
                <a
                  href="#"
                  @click.prevent="changeStatus('is_invoicing_emails')"
                  class="d-inline-block p-0 w-100"
                >
                  <badge
                    class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                    :style="
                      user.is_invoicing_emails
                        ? 'color: #149E57; background-color: #F2F7F3;'
                        : 'color: #DB4539; background-color: #FCF3F3;'
                    "
                  >
                    <img
                      :src="
                        user.is_invoicing_emails
                          ? '/img/icons/ok--v1.png'
                          : '/img/icons/shutdown.png'
                      "
                      class="img-fluid mr-2"
                      alt=""
                      title=""
                      loading="lazy"
                      width="16px"
                    />
                    {{ user.is_invoicing_emails ? "Ativo" : "Inativo" }}
                  </badge>
                </a>
              </div>
            </div>

            <!-- REGRA DE COMISSÃO -->
            <div class="app-form-group justify-content-between">
              <label for="code" class="col-md-4"> REGRA DE COMISSÃO </label>
              <div class="wrapper col-md-3">
                <validation-provider v-slot="{ errors }">
                  <input
                    id="code"
                    class="input"
                    v-model="others_commission.commision"
                    inputmode="numeric"
                    v-mask="['##']"
                  />
                  <small
                    class="input-append d-flex justify-content-center align-items-center"
                  >
                    %
                  </small>
                </validation-provider>
              </div>
            </div>

            <!-- COMERCIAL -->
            <div v-show="selected === 2">
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Vendedor / Representante comercial
                  </div>
                  <base-switch
                    v-model="user.is_seller"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    @input="checkSellerStatus()"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Receber E-mails de faturamento
                  </div>
                  <base-switch
                    v-model="user.is_invoicing_emails"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="!user.is_seller"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Regra de Comissão
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="others_commission.commision"
                      inputmode="numeric"
                      v-mask="['##']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <hr class="mt-3 mb-3 border-settings" />

              <div class="row align-items-center mb-3 mt-1">
                <div class="col-9">
                  <h5 class="h4 m-0">Comissão sobre outros colaboradores</h5>
                </div>
                <div class="col-3">
                  <div class="pb-1 col-form-label form-control-label">
                    <span
                      class="btn btn-sm btn-primary p-1 ml-1"
                      @click.prevent="addOtherCommission()"
                    >
                      <img
                        src="/img/icons/plus-2.png"
                        width="15px"
                        height="15px"
                      />
                      adicionar
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Usuário
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="others_commission.user_uuid_commission"
                      :items="filteredUsers"
                      customKey="uuid"
                      :loading="loadingUsers"
                      :disabled="loadingUsers"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Comissão
                </label>
                <div class="col-md-7 mb-1 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="others_commission.commision"
                      inputmode="numeric"
                      v-mask="['##']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div
                class="m-2 mt-3"
                v-for="(itemOtherCommision, index) in user.others_commissions"
                :key="index"
              >
                <card class="mb-1 border card--hover card-body-molde">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <h4>{{ itemOtherCommision.user_name }}</h4>
                    </div>
                    <div class="col">
                      <h4>{{ itemOtherCommision.commision | percent(0) }}</h4>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-sm rounded-circle btn-danger"
                        @click="removeOtherCommission(index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </card>
              </div>
            </div>

            <div class="modal-footer">
              <button class="danger button" @click="closeModal('create')">
                <img
                  src="/img/icons/cancel-red.png"
                  class="img-fluid"
                  loading="lazy"
                  width="16px"
                />
                Cancelar
              </button>

              <button
                class="success button"
                type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                <img
                  src="/img/icons/save-green.png"
                  class="img-fluid"
                  width="16px"
                  loading="lazy"
                />
                Salvar
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSetPlant from "./_ModalSetPlant";
import ModalSetUserPermission from "./_ModalSetUserPermission";
import ModalSetUserRole from "./_ModalSetUserRole";
import CreateJobDescription from "../JobDescription/Shared/_Create.vue";
import CreateContractRegime from "../ContractRegime/Shared/_Create.vue";
import ModalSetSellers from "./_ModalSetSellers";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
import { seller as $_totvs } from "@/plugins/microservices/totvs";
import ModalUserPermission from "./_ModalUserPermission";
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  name: "ModalEditUser",
  components: {
    ModalSetPlant,
    ModalSetUserPermission,
    ModalSetUserRole,
    CreateJobDescription,
    CreateContractRegime,
    ModalSetSellers,
    PuzlSelect,
    SkeletonPuzl,
    ModalUserPermission,
  },
  data() {
    return {
      modal: {
        title: "Editar Usuário",
        create: false,
      },
      selected: 0,
      loadingPlant: true,
      loadingSave: false,
      loadingModal: true,
      invalid: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      user: {
        status: true,
        is_user: true,
        is_intermediary: false,
        is_seller: false,
        is_concrete: false,
        is_service: false,
        is_external_service: false,
        is_entry_driver: false,
        is_invoicing_emails: false,
        name: null,
        short_name: null,
        email: null,
        password: null,
        reset_password: null,
        document: null,
        phone: null,
        external_code: null,
        national_id_card: null,
        birth_date: null,
        marital_status: null,
        registration: null,
        job_description_uuid: null,
        contract_regime_uuid: null,
        admission_date: null,
        cost_center_uuid: null,
        chart_account_uuid: null,
        company_plant_uuid: null,
        company_plant_issuer_id: null,
        driver: false,
        equipment_type: null,
        equipment_category_id: null,
        equipment_default: null,
        driver_number: null,
        driver_validity: null,
        commission_uuid: null,
        others_commissions: [],
      },
      others_commission: {
        user_name: null,
        user_uuid_commission: null,
        commision: null,
      },
      loadingStore: false,
      loadingUsers: true,
      loadingCompanyIssuers: true,
      loadingEquipmentsCategorys: true,
      loadingEquipmentDefault: false,
      company_issuers: [],
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      users: "user/fetchForModalList",
      commissions: "commission/fetch",
      $_equipmentCategorys: "equipmentCategory/fetch",
      $_equipmentsByCategory: "equipment/fetchEquipmentsByCategory",
    }),
    filteredUsers() {
      return this.users.filter((item) => item.is_seller === 1);
    },
  },
  watch: {
    "user.company_plant_uuid": function () {
      this.changePlantIssuer(this.user.company_plant_uuid);
    },
  },
  methods: {
    changePlantIssuer(company_plant_uuid) {
      this.loadingCompanyIssuers = true;
      let [issuer] = this.plants.filter(
        (item) => item.uuid === company_plant_uuid
      );
      this.company_issuers = issuer.company_issuers;
      this.loadingCompanyIssuers = false;
    },
    addOtherCommission() {
      let user_uuid_commission = this.others_commission.user_uuid_commission;
      let others_commissions = this.user.others_commissions;

      let commissions = { ...this.others_commission };
      let [user] = this.users.filter(
        (item) => item.uuid === user_uuid_commission
      );
      commissions.user_name = user.name;
      commissions.user_uuid_commission = user_uuid_commission;
      commissions.commision = this.others_commission.commision;

      if (others_commissions.length > 0) {
        let [check_user] = others_commissions.filter(
          (item) => item.user_uuid_commission === user_uuid_commission
        );
        if (
          check_user &&
          check_user.user_uuid_commission === user_uuid_commission
        ) {
          this.$notify({
            type: "warning",
            message: "Este usuário já foi selecionado!",
          });
          return;
        }
      }

      this.user.others_commissions.push(commissions);
    },
    removeOtherCommission(index) {
      this.user.others_commissions.splice(index, 1);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("user/update", this.user)
        .then((response) => {
          this.loadingStore = false;
          this.$Progress.finish();

          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.user.is_seller && $_totvs.update(this.user);
          this.$emit("refreshPage");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status) {
            this.$Progress.finish();
            this.loadingStore = false;
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            }
          }
        });
    },
    fetch(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loadingModal = true;

      this.$store.dispatch("user/show", uuid).then(({ data }) => {
        this.user = {
          id: data.id,
          uuid: data.uuid || "",
          status: data.status,
          is_user: data.is_user,
          is_intermediary: data.is_intermediary,
          is_seller: data.is_seller,
          is_concrete: data.is_concrete,
          is_service: data.is_service,
          is_external_service: data.is_external_service,
          is_entry_driver: data.is_entry_driver,
          is_invoicing_emails: data.is_invoicing_emails,
          name: data.name || "",
          short_name: data.short_name || "",
          email: data.email || "",
          password: data.password,
          reset_password: data.reset_password,
          document: data.detail ? data.detail.document : "",
          phone: data.phone || "",
          external_code: data.external_code || "",
          national_id_card: data.detail ? data.detail.national_id_card : "",
          birth_date: data.detail ? data.detail.birth_date : "",
          marital_status: data.detail ? data.detail.marital_status : "",
          registration: data.detail ? data.detail.registration : "",
          admission_date: data.detail ? data.detail.admission_date : "",
          job_description_uuid:
            data.detail && data.detail.job_description
              ? data.detail.job_description.uuid
              : "",
          contract_regime_uuid:
            data.detail && data.detail.contract_regime
              ? data.detail.contract_regime.uuid
              : "",
          cost_center_uuid: data.financial
            ? data.financial.cost_center_uuid
            : "",
          chart_account_uuid: data.financial
            ? data.financial.chart_account_uuid
            : "",
          company_plant_uuid: data.financial
            ? data.financial.company_plant_uuid
            : "",
          company_plant_issuer_id: data.financial
            ? data.financial.company_plant_issuer_id
            : "",
          driver: data.operational ? data.operational.driver : false,
          equipment_type: data.operational
            ? data.operational.equipment_type
            : "",
          equipment_default: data.operational
            ? data.operational.equipment_default
            : "",
          equipment_category_id: data.operational
            ? data.operational.equipment_category_id
            : "",
          driver_type: data.operational ? data.operational.driver_type : "",
          driver_number: data.operational ? data.operational.driver_number : "",
          driver_validity: data.operational
            ? data.operational.driver_validity
            : "",
          commission_uuid: data.user_commercial
            ? data.user_commercial.commission_uuid
            : "",
          others_commissions: data.user_commercial_commision,
        };
        this.loadingModal = false;
        if (this.user.equipment_category_id) {
          this.$store.dispatch(
            "equipment/getEquipmentByCategory",
            this.user.equipment_category_id
          );
        }
      });
    },
    getEquipmentByCategory() {
      this.loadingEquipmentDefault = true;
      this.$store
        .dispatch(
          "equipment/getEquipmentByCategory",
          this.user.equipment_category_id
        )
        .then(() => {
          this.loadingEquipmentDefault = false;
        });
      this.user.equipment_default = null;
    },
    changeDriveStatus(driver) {
      this.changeStatus(driver);
      this.checkDriverStatus();
    },
    checkDriverStatus() {
      if (!this.user.driver) {
        this.user.equipment_category_id = null;
        this.user.is_concrete = false;
        this.user.is_service = false;
        this.user.is_external_service = false;
        this.user.is_entry_driver = false;
      } else {
        this.user.is_concrete = true;
        this.user.is_service = false;
        this.user.is_external_service = false;
        this.user.is_entry_driver = false;
      }
      if (!this.user.equipment_category_id) {
        this.user.equipment_default = null;
      }
    },
    checkSellerStatus(status) {
      this.changeStatus(status);

      if (!this.user.is_seller) {
        this.user.is_invoicing_emails = false;
      }
    },
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(uuid) {
      this.fetch(uuid);
      this.loadData();
      this.modal.create = true;
    },
    loadData() {
      this.loadingPlant = true;
      this.$store.dispatch("plant/fetchItemsActive", 0).then(() => {
        this.loadingPlant = false;
      });
      this.loadingUsers = true;
      this.$store.dispatch("user/fetchItemsForModalList").then(() => {
        this.loadingUsers = false;
      });
      this.loadingCommissions = true;
      this.$store.dispatch("commission/fetchItems").then(() => {
        this.loadingCommissions = false;
      });
      this.loadingEquipmentsCategorys = true;
      this.$store.dispatch("equipmentCategory/fetchItems").then(() => {
        this.loadingEquipmentsCategorys = false;
      });
    },
    changeStatus(userKey) {
      this.user[userKey] = +!this.user[userKey];
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped>
.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 991px) {
  .base-button {
    font-size: 6px;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}
</style>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

// MODAL
.modal.user-edit {
  label {
    @include rhythm(font-size, 16);
    margin-bottom: 0;
    font-weight: 400;
  }

  //BADGE
  .badge {
    border-radius: 100px;
    min-height: 28px !important;
    min-width: 135px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $warning-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    img {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  // PUZL-SELECT RESET
  .vs--single > div.vs__dropdown-toggle {
    height: 32px !important;
  }

  // MODAL-BODY
  .modal-body {
    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      img {
        width: 24px;
        height: 24px;
      }

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }

      .hr {
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        transform-origin: top;
        background-color: $muted-light;
      }
    }
    .app-form-group {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;

      @media (max-width: 991.98px) {
        &:not(.badge-reset) {
          flex-wrap: wrap;
        }
      }

      label {
        @include rhythm(font-size, 16);
        margin-bottom: 0;
        font-weight: 400;

        @media (max-width: 991.98px) {
          padding-bottom: 4px;
          width: 100%;
        }
      }

      .wrapper > span {
        display: flex;
        flex-wrap: inherit;
        width: 100%;

        & .v-select {
          width: 100%;

          .vs__search {
            box-shadow: none !important;
          }
        }
      }

      .input-append {
        text-wrap: nowrap;
        padding: 4px 8px;
        line-height: 1;
        border: 1px solid $muted-light;
        background-color: white;
        @include rhythm(font-size, 14);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .input {
        display: block;
        border: none;
        @include rhythm(font-size, 14);
        box-shadow: 0 0 0 0.5px #e8e8e8, 0px 5px 10px 0px #0000000d !important;
        border-radius: 4px;
        padding: 4px 8px;
        box-sizing: border-box;
        height: 32px;
        width: 100%;

        &:disabled {
          background-color: $muted-light;
        }

        &.is-invalid {
          box-shadow: 0 0 0 1px $danger, 0px 5px 10px 0px #0000000d !important;
          animation: shake 0.3s ease-in-out;

          &:focus {
            outline: none;
          }
        }

        &.is-valid {
          box-shadow: 0 0 0 1px $success, 0px 5px 10px 0px #0000000d !important;

          &:focus {
            outline: none;
          }
        }

        &.radius-right-0 {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        /* Shake animation */
        @keyframes shake {
          0% {
            transform: translateX(0);
          }
          25% {
            transform: translateX(-5px);
          }
          50% {
            transform: translateX(5px);
          }
          75% {
            transform: translateX(-5px);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
    }
    //BADGE-ROW
    .badge-row {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 24px;
      padding: 0;

      @media (max-width: 991.98px) {
        flex-wrap: wrap;
      }

      .badge-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 4px;

        @media (max-width: 991.98px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: start;
        }

        .label {
          @media (max-width: 991.98px) {
            max-width: 50%;
          }
        }

        a {
          width: 100%;
          @media (max-width: 991.98px) {
            padding-left: 15px !important;
            max-width: 50%;
          }
        }
        .badge {
          width: 100%;

          @media (max-width: 991.98px) {
            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }

  // MODAL-FOOTER
  .modal-footer {
    gap: 10px;
    padding-top: 1rem !important;

    .button {
      all: unset;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      padding: 8px 20px;
      display: flex;
      gap: 10px;
      background-color: white;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 0.3s;
      @include rhythm(font-size, 14);

      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }

      &.danger {
        border: 1px solid $danger;
        color: $danger;

        &:hover {
          background-color: $danger;
          color: white;
        }
      }

      &.success {
        border: 1px solid $success;
        color: $success;

        &:disabled {
          opacity: 0.5;

          &:hover {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        &:hover {
          background-color: $success;
          color: white;
        }
      }
    }
  }
}

.vs__selected {
  align-items: center;
  background-color: var(--vs-selected-bg);
  border: var(--vs-selected-border-width) var(--vs-selected-border-style)
    var(--vs-selected-border-color);
  border-radius: var(--vs-border-radius);
  color: var(--vs-selected-color);
  display: flex;
  line-height: var(--vs-line-height);
  margin: 4px 2px 0;
  padding: 0 0.25em;
  z-index: 0;
}
</style>
