<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <!-- CAMPO CENTRAL -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Central
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="adjustmentLevelResponsibility.plants"
                      :items="$_plants"
                      multiple
                      :loading="loadingCompanyPlants"
                      :disabled="loadingCompanyPlants"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- CAMPO NÍVEL DE RESPONSABILIDADE -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Nível de aplicação.
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="adjustmentLevelResponsibility.level_feature"
                      :items="$_feature_levels"
                      label="label"
                      customKey="value"
                      :loading="loadingFeatureLevels"
                      :disabled="loadingFeatureLevels"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- CAMPO OBSERVAÇÃO -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Observação
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text"
                          rows="3"
                          class="form-control form-control-sm"
                          v-model="adjustmentLevelResponsibility.observation"
                        >
                        </textarea>
                    </base-input>
                </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "ModalCreate",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Ajuste por nível de aplicação",
        create: false,
      },
      adjustmentLevelResponsibility: {
        plants: [],
        level_feature: null,
        observation: '',
      },
      loadingCompanyPlants: true,
      loadingFeatureLevels: true,
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_feature_levels: 'mixConcretePiece/feature_levels',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
      this.adjustmentLevelResponsibility = {
        plants: [],
        level_feature: null,
        observation: '',
      };
    },
    handleCreateModal() {
      this.loadData();
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("adjustmentLevelResponsibility/add", this.adjustmentLevelResponsibility)
        .then((response) => {
          this.$emit("createdLevelResponsibilityAdjustments");
          this.$notify({ type: response.error_type, message: response.message });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    loadData() {
      this.loadingCompanyPlants = true;
      const localPlants = localStorage.getItem('companyPlants');
      if (localPlants) {
        const plants = JSON.parse(localPlants);
        this.$store.commit('plant/SET_ACTIVE', plants.data);
        this.loadingCompanyPlants = false;
      } else {
        this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
        });
      }

      this.loadingFeatureLevels = true;
      this.$store.dispatch("mixType/fetchItems").then(() => {
        this.loadingFeatureLevels = false;
      });
    },
  },
};
</script>

<style scoped></style>
