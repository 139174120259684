<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <multi-filter
            :filter="filter"
            :showSlotData="true"
            :showSlotDates="false"
            :showSlotBookmark="false"
            :showSlotStatus="false"
            :defaultRange="defaultRange"
            @clearFilters="clearFilters"
            @fetch="load"
            @handleFilterDate="handleFilterDate"
            ref="multi-filter"
          >
            <template slot="data">
              <div class="col-md-12 mt-3 text-left">
                <label class="form-control-label">Centrais</label>
                  <puzl-select
                    v-model="filter.company_plants"
                    :items="$_plants"
                    :loading="loadingCompanyPlants"
                    :multiple="true"
                    placeholder="Centrais"
                    class="select-xl new-default-black-font"
                    @input="handleFilterCompanyPlants" />
              </div>
              <div class="col-md-12 mt-3 mb-1 text-left">
                <label class="form-control-label">Vendedores</label>
                <puzl-select
                  v-model="filter.sellers_uuid"
                  :items="$_sellers"
                  :loading="loadingSeller"
                  :multiple="true"
                  customKey="uuid"
                  class="select-xl new-default-black-font"
                  placeholder="Vendedor"
                  @input="handleFilterSellers" />
              </div>
              <div class="col-md-12 mt-3 mb-1 text-left">
                <label class="form-control-label">Tipo de venda</label>
                <puzl-select
                  v-model="filter.delivery_type"
                  :items="delivery_types"
                  :multiple="true"
                  class="select-xl new-default-black-font"
                  placeholder="Tipo de venda"
                  @input="handleDeliveryType" />
              </div>
              <div class="col-md-12 mt-3 mb-1 text-left">
                <label class="form-control-label">Retira</label>
                <puzl-select
                  v-model="filter.concrete_removal"
                  :items="concrete_removals"
                  class="select-xl new-default-black-font"
                  placeholder="Selecione"
                  @input="handleConcreteRemoval" />
              </div>
              <div class="col-12 mt-3 mb-7 text-left">
                <base-button
                  @click="openModalSearchContract"
                  active
                  outline
                  size="lg"
                  type="success"
                  class="w-100"
                  style="margin-top: 5px;"
                >
                  <img src="/img/icons/icons8/ios/identify_success.png" width="23px" class="invert-on-hover mr-1" />
                  PESQUISAR CONTRATO
                </base-button>
              </div>
            </template>
          </multi-filter>
        </div>
      </div>
      <!-- cards totais -->
      <div v-if="!loadingWidgets" class="row row-cols-1 row-cols-md-3 row-cols-xl-3 mb-1 p-1 mt-n2">
        <div class="col card-margin-top card-margin-top-mobile p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/icons8/ios/financial-dynamic-presentation.png" width="26px" height="26px" class="mr-2" />
              <span class="card-title text-dark">Volume e entregas</span>
              <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
                Concreto
                <b class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                    {{
                      Number($_widgets.total_concrete)
                        .toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                    }}
                    m³
                </b>
              </div>
              <div class="text-dark card-text-border font-size-card">
                Serviços
                <b class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  {{
                    Number($_widgets.total_services_volume)
                      .toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                  }}
                  m³
                </b>
              </div>
              <el-popover
                trigger="hover"
                placement="top"
                class="p-0"
              >
                <div>
                  Convencional:
                  <span style="color: #808080">
                    {{ getConcretePercent().percent }}
                  </span>
                </div>
                <div>
                  Bombeado:
                  <span style="color: #0d6efd">
                     {{ getServicePercent().percent }}
                  </span>
                </div>
                <div
                  class="row mx-1 mt-n4 w-100"
                  slot="reference"
                  v-if="getConcretePercent().percent || getServicePercent().percent"
                >
                  <div :style="{ 'width': getServicePercent().percent }">
                    <small class="mt-3" style="position: absolute; font-size: 10.2px;">
                      {{ getServicePercent().percent }}
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #0d6efd',
                        'border-top-right-radius': getConcretePercent().border,
                        'border-bottom-right-radius': getConcretePercent().border
                      }"
                    >
                  </div>
                  <div :style="{ 'width': getConcretePercent().percent }">
                    <small class="mt-3" style="position: absolute; right: 19px; font-size: 10.2px;">
                      {{ getConcretePercent().percent }}
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #808080',
                        'border-top-left-radius': getServicePercent().border,
                        'border-bottom-left-radius': getServicePercent().border
                      }"
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="col card-margin-top card-margin-top-mobile p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/icons8/ios/sandbox2_warning.png" width="26px" height="26px" class="mr-2" />
              <span class="card-title text-dark">Mcc e margem</span>
              <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
                Mcc
                <b v-if="$_widgets.margin" class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  {{ $_widgets.margin | currency }} / M³
                </b>
                <b v-else class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  R$ 0,00 / M³
                </b>
              </div>
              <div class="text-dark card-text-border font-size-card">
                Margem total
                <b v-if="$_widgets.total_margin" class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  {{ totalCalculatedMargin | currency }} / M³
                </b>
                <b v-else class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  R$ 0,00 / M³
                </b>
              </div>
              <el-popover
                trigger="hover"
                placement="top"
                class="p-0"
              >
                <div>
                  Mcc:
                  <span style="color: #dc3545">
                    {{ getMccPercent().percent }}
                  </span>
                </div>
                <div>
                  Margem Total:
                  <span style="color: #28a745">
                     {{ getMarginPercent().percent }}
                  </span>
                </div>
                <div
                  class="row mx-1 mt-n3 w-100"
                  v-if="getMccPercent().percent || getMarginPercent().percent"
                  slot="reference"
                >
                  <div :style="{ 'width': getMccPercent().percent }">
                    <small class="mt-3" style="position: absolute; font-size: 10.2px;">
                      {{ getMccPercent().percent }}
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #dc3545',
                        'border-top-right-radius': getMarginPercent().border,
                        'border-bottom-right-radius': getMarginPercent().border
                      }"
                    >
                  </div>
                  <div :style="{ 'width': getMarginPercent().percent }">
                    <small class="mt-3" style="position: absolute; right: 19px; font-size: 10.2px;">
                      {{ getMarginPercent().percent }}
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #28a745',
                        'border-top-left-radius': getMccPercent().border,
                        'border-bottom-left-radius': getMccPercent().border
                      }"
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="col card-margin-top card-margin-top-mobile p-1">
          <div class="card height-card">
            <div class="card-body">
              <img src="/img/icons/icons8/ios/calculator.png" width="26px" height="26px" class="mr-2" />
              <span class="card-title text-dark">Total</span>
              <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
                Receita
                <span v-if="$_widgets.total > 0" class="float-right mt-n1">
                  <el-popover trigger="hover" placement="bottom">
                    <div class="mt-2 border-left border-3 border-indigo p-2">
                      <div>
                        <h5 class="mb-0">
                          <span>
                            Concreto
                            {{ $_widgets.total_contract_proposal_formulation_value | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Serviços {{ $_widgets.total_services | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Adicionais
                            {{ $_widgets.total_schedule_additional_price | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Avulsos
                            {{ $_widgets.total_single_additional_invoice_value | currency }}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <span slot="reference">
                      <img src="/img/icons/icons8/ios/financial-dynamic-presentation.png"width="20px" class="mr-1" />
                      <b class="font-size-card" style="font-size: 11.5px;">
                        {{ $_widgets.total | currency }}
                      </b>
                    </span>
                  </el-popover>
                </span>
                <b v-else class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  R$ 0,00
                </b>
              </div>
              <div class="text-dark card-text-border font-size-card">
                Preço médio
                <span v-if="$_widgets.total / $_widgets.total_concrete > 0" class="mr-1 float-right mt-n1">
                  <el-popover trigger="hover" placement="bottom">
                    <div class="mt-2 border-left border-3 border-indigo p-2">
                      <div>
                        <h5 class="mb-0">
                          <span>
                            Concreto {{ $_widgets.sale_price | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Serviços {{ $_widgets.service_price | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Adicionais {{ $_widgets.additional_price | currency }}
                          </span>
                        </h5>
                        <h5 class="mb-0">
                          <span>
                            Avulsos {{ $_widgets.single_price | currency }}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <span slot="reference">
                      <img
                        src="/img/icons/icons8/ios/financial-dynamic-presentation.png"
                        width="20px"
                        class="mr-1" />
                      <b class="font-size-card" style="font-size: 11.5px;">
                        {{ $_widgets.total / $_widgets.total_concrete | currency }} / M³
                      </b>
                    </span>
                  </el-popover>
                </span>
                <b v-else class="mr-1 float-right font-size-card" style="font-size: 11.5px;">
                  R$ 0,00 / M³
                </b>
              </div>
              <el-popover
                trigger="hover"
                placement="top"
                class="p-0"
              >
                <div>
                  Concreto:
                  <span style="color: #0d6efd" v-if="getAverageConcretePercent().percent > 0">
                    {{ getAverageConcretePercent().percent.toFixed(1) }}%
                  </span>
                </div>
                <div>
                  Serviço:
                  <span style="color: #808080" v-if="getAverageServicePercent().percent > 0">
                     {{ getAverageServicePercent().percent.toFixed(1) }}%
                  </span>
                </div>
                <div>
                  Adicional:
                  <span style="color: #ffc107" v-if="getAverageAdditionalPercent().percent > 0">
                    {{ getAverageAdditionalPercent().percent.toFixed(1) }}%
                  </span>
                </div>
                <div>
                  Avulso:
                  <span style="color: #6610f2" v-if="getAverageSinglePercent().percent > 0">
                     {{ getAverageSinglePercent().percent.toFixed(1) }}%
                  </span>
                </div>
                <div
                  class="row mx-1 mt-n3 w-100"
                  slot="reference"
                  v-if="
                    getAverageConcretePercent().percent ||
                    getAverageServicePercent().percent ||
                    getAverageAdditionalPercent().percent ||
                    getAverageSinglePercent().percent
                  "
                >
                  <div :style="{ 'width': getAverageConcretePercent().percent + '%' }">
                    <small class="mt-3" style="position: absolute; font-size: 10.2px;" v-if="getAverageConcretePercent().percent > 0">
                      {{ getAverageConcretePercent().percent.toFixed(1) }}%
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #0d6efd',
                        'border-top-right-radius': getAverageServicePercent().border,
                        'border-bottom-right-radius': getAverageServicePercent().border
                      }"
                    >
                  </div>
                  <div :style="{ 'width': getAverageServicePercent().percent + '%' }">
                    <small class="mt-3" style="position: absolute; font-size: 10.2px;" v-if="getAverageServicePercent().percent > 0">
                      {{ getAverageServicePercent().percent.toFixed(1) }}%
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #808080',
                        'border-top-right-radius': getAverageAdditionalPercent().border,
                        'border-bottom-right-radius': getAverageAdditionalPercent().border
                      }"
                    >
                  </div>
                  <div :style="{ 'width': getAverageAdditionalPercent().percent + '%' }">
                    <small class="mt-3" style="position: absolute; font-size: 10.2px;" v-if="getAverageAdditionalPercent().percent > 0">
                      {{ getAverageAdditionalPercent().percent.toFixed(1) }}%
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #ffc107',
                        'border-top-right-radius': getAverageSinglePercent().border,
                        'border-bottom-right-radius': getAverageSinglePercent().border
                      }"
                      class="yellow-divider"
                    >
                  </div>
                  <div :style="{ 'width': getAverageSinglePercent().percent + '%' }">
                    <small class="mt-3" style="position: absolute; right: 1px; font-size: 10.2px;" v-if="getAverageSinglePercent().percent > 0">
                      {{ getAverageSinglePercent().percent.toFixed(1) }}%
                    </small>
                    <hr
                      :style="{
                        'border-top': '4px solid #6610f2',
                        'border-top-left-radius': getAverageAdditionalPercent().border,
                        'border-bottom-left-radius': getAverageAdditionalPercent().border
                      }"
                      class="purple-divider"
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row mb-4 mt-n4" style="margin-top: -45px;">
        <div v-for="item in 3" class="col p-1">
          <div class="mb-3 card" style="height: 155px">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <SkeletonPuzl type="small"/>
                </div>
                <div class="col-2 text-right float-right" style="margin-top: -10px">
                  <SkeletonPuzl type="circle"/>
                </div>
                <div class="col-2" style="margin-top: -17px">
                  <SkeletonPuzl type="small"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-0" style="margin-top: -28px;">
        <div class="col-md-12 p-1">
          <div>
            <ul role="tablist" class="nav nav-tabs nav-justified">
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                  marginSortCheckboxGeneral ? filterOrderByMarginGeneral() : null,
                  fetchByPlant({}, true)
                "
                  role="tab"
                  aria-selected="true"
                  aria-setsize="4"
                  aria-posinset="1"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="
                  padding: 8px 0;
                  border: 1px solid #DCDFE6;"
                  :style="
                  grouped_table_listing.by_plant
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                  "
                  :class="grouped_table_listing.by_plant && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        grouped_table_listing.by_plant
                          ? '/img/icons/chemical-plant-white.png'
                          : '/img/icons/chemical-plant-black.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    <span class="text-smaller">Central</span>
                  </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    marginSortCheckboxSeller ? filterOrderByMarginSeller() : null,
                    fetchBySeller({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="
                  padding: 8px 0;
                  border: 1px solid #DCDFE6;"
                  :style="
                  grouped_table_listing.by_seller
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                  "
                  :class="grouped_table_listing.by_seller && 'active'"
                >
                 <b class="font-weight-500">
                    <img
                      :src="
                        grouped_table_listing.by_seller
                          ? '/img/icons/salesman-white.png'
                          : '/img/icons/salesman-dark.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    <span class="text-smaller">Vendedor</span>
                  </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    marginSortCheckboxContract ? filterOrderByMarginContract() : null,
                    fetchByContract({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="
                  padding: 8px 0;
                  border: 1px solid #DCDFE6;"
                  :style="
                  grouped_table_listing.by_contract
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                  "
                  :class="grouped_table_listing.by_contract && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        grouped_table_listing.by_contract
                          ? '/img/icons/client-company-white.png'
                          : '/img/icons/client-company-dark.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    <span class="text-smaller">Contrato</span>
                  </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    marginSortCheckboxCity ? filterOrderByMarginCity() : null,
                    fetchByCity({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="
                  padding: 8px 0;
                  border: 1px solid #DCDFE6;"
                  :style="
                  grouped_table_listing.by_city
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                  "
                  :class="grouped_table_listing.by_city && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        grouped_table_listing.by_city
                          ? '/img/icons/icons8/ios/order-delivered.png'
                          : '/img/icons/icons8/ios/order-delivered-dark.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    <span class="text-smaller">Município</span>
                  </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    marginSortCheckboxDay ? filterOrderByMarginDay() : null,
                    fetchByDay({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="
                  padding: 8px 0;
                  border: 1px solid #DCDFE6;"
                  :style="
                  grouped_table_listing.by_day
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                  "
                  :class="grouped_table_listing.by_day && 'active'"
                >
                  <b class="font-weight-500">
                    <img
                      :src="
                        grouped_table_listing.by_day
                          ? '/img/icons/calendar-white.png'
                          : '/img/icons/calendar-black.png'
                      "
                      width="22px"
                      height="22px"
                      class="mr-1"
                    />
                    <span class="text-smaller">Dia</span>
                  </b>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <div class="card-body mt-n3">
              <!-- Tabela 1 agrupadada por central -->
              <div class="row pt-0" v-if="grouped_table_listing.by_plant">
                <div class="col-md-12 p-1">
                  <div class="card">
                    <div class="card-body mt-n2">
                      <h4>Geral</h4>
                      <div class="form-check mt-n2">
                        <input
                          v-model="marginSortCheckboxGeneral"
                          class="form-check-input"
                          type="checkbox"
                          id="marginSortCheckboxGeneral"
                          @input="filterOrderByMarginGeneral"
                        >
                        <label for="marginSortCheckboxGeneral" style="font-size: 13px;">
                          Ordenar por margem
                        </label>
                        <a href="#"
                          class="float-right mt-n2 px-1"
                          @click.prevent="downloadGeneralPdf('plant_id')"
                        >
                          <img
                            v-if="$_byPlant.length && !loadingByPlant"
                            style="width: 25px"
                            src="/img/icons/pdf-v2.png" />
                        </a>
                        <a href="#"
                          class="float-right mt-n2"
                          @click.prevent="downloadGeneralExcel('plant_id')"
                        >
                          <img
                            v-if="$_byPlant.length && !loadingByPlant"
                            style="width: 25px"
                            src="/img/icons/excel.png" />
                        </a>
                      </div>
                      <div class="row">
                        <div class="col-md-12 table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr class="fixed">
                                <th class="text-center table-column-light-gray text-dark">Central</th>
                                <th class="text-center table-column-light-gray text-dark">Concreto(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Serviços(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Total</th>
                                <th class="text-center table-column-light-gray text-dark">Preço Médio</th>
                                <th class="text-center table-column-light-gray text-dark">Margem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in 10" v-show="loadingByPlant">
                                <th colspan="12">
                                  <SkeletonPuzl type="button" />
                                </th>
                              </tr>
                              <tr v-for="item in $_byPlant" v-show="!loadingByPlant">
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{ item.plant_name }}
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_schedule_travel_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_service_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.total_contract_proposal_formulation_value | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.total_services | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{
                                                item.total_schedule_additional_price | currency
                                              }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.total_single_additional_invoice_value | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ item.total | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.total_schedule_travel_volume > 0">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto {{ item.sale_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.service_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais {{ item.additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos {{ item.single_price | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ (item.total / item.total_schedule_travel_volume) | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.margin > 0">
                                    <el-popover class="p-0 float-right pr-0" placement="bottom" trigger="hover">
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span class="">Mcc {{ item.margin | currency() }} /m³</span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button slot="reference" class="p-0 m-0 shadow-none" outiline size="sm" type="secundary">
                                        <i class="fa-solid fa-circle-chevron-down text-default" style="cursor: pointer"/>
                                      </base-button>
                                    </el-popover>
                                  </span>
                                  <span class="style-table numeric" style="font-size: 0.85rem">
                                    {{ marginCalculated(item) | currency() }}
                                  </span>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <PuzlEmptyData v-show="$_byPlant && !$_byPlant.length && !loadingByPlant"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabela 2 agrupadada por vendedor -->
              <div class="row pt-0" v-if="grouped_table_listing.by_seller">
                <div class="col-md-12 p-1">
                  <div class="card">
                    <div class="card-body mt-n2">
                      <h4>Vendedores</h4>
                      <div class="form-check mt-n2">
                        <input
                          v-model="marginSortCheckboxSeller"
                          class="form-check-input"
                          type="checkbox"
                          id="marginSortCheckboxSeller"
                          @input="filterOrderByMarginSeller"
                        >
                        <label for="marginSortCheckboxSeller" style="font-size: 13px;">
                          Ordenar por margem
                        </label>
                        <a href="#"
                          class="float-right mt-n2 px-1"
                          @click.prevent="downloadGeneralPdf('seller')"
                        >
                          <img
                            v-if="$_bySeller.length && !loadingBySeller"
                            style="width: 25px"
                            src="/img/icons/pdf-v2.png" />
                        </a>
                        <a href="#"
                          class="float-right mt-n2"
                          @click.prevent="downloadGeneralExcel('seller')"
                        >
                          <img
                            v-if="$_bySeller.length && !loadingBySeller"
                            style="width: 25px"
                            src="/img/icons/excel.png" />
                        </a>
                      </div>
                      <div class="row">
                        <div class="col-md-12 table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr class="fixed">
                                <th class="text-center table-column-light-gray text-dark">Vendedor</th>
                                <th class="text-center table-column-light-gray text-dark">Concreto(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Serviços(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Total</th>
                                <th class="text-center table-column-light-gray text-dark">Preço Médio</th>
                                <th class="text-center table-column-light-gray text-dark">Margem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in 10" v-show="loadingBySeller">
                                <th colspan="12">
                                  <SkeletonPuzl type="button" />
                                </th>
                              </tr>
                              <tr v-for="item in $_bySeller" v-show="!loadingBySeller">
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{ item.seller_name }}
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_schedule_travel_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_service_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.total_contract_proposal_formulation_value | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.total_services | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.total_schedule_additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.total_single_additional_invoice_value | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ item.total | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto {{ item.sale_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.service_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais {{ item.additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos {{ item.single_price | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ (item.total / item.total_schedule_travel_volume) | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover class="p-0 float-right pr-0" placement="bottom" trigger="hover">
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Mcc
                                              <span v-if="item.margin > 0">
                                                {{ item.margin | currency() }}
                                              </span>
                                              <span v-else>
                                                R$ 0,00
                                              </span>
                                              /m³
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button slot="reference" class="p-0 m-0 shadow-none" outiline size="sm" type="secundary">
                                        <i class="fa-solid fa-circle-chevron-down text-default" style="cursor: pointer"/>
                                      </base-button>
                                    </el-popover>
                                  </span>
                                  <small class="style-table numeric" style="font-size: 0.85rem">
                                      {{ marginCalculated(item) | currency() }}
                                  </small>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <PuzlEmptyData v-show="$_bySeller && !$_bySeller.length && !loadingBySeller"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabela 3 agrupadada por contrato -->
              <div class="row pt-0" v-if="grouped_table_listing.by_contract">
                <div class="col-md-12 p-1">
                  <div class="card">
                    <div class="card-body mt-n2">
                      <h4>Contrato</h4>
                      <div class="form-check mt-n2">
                        <input
                          v-model="marginSortCheckboxContract"
                          class="form-check-input"
                          type="checkbox"
                          id="marginSortCheckboxContract"
                          @input="filterOrderByMarginContract"
                        >
                        <label for="marginSortCheckboxContract" style="font-size: 13px;">
                          Ordenar por margem
                        </label>
                        <a href="#"
                          class="float-right mt-n2 px-1"
                          @click.prevent="downloadGeneralPdf('contract_proposals.id')"
                        >
                          <img
                            v-if="$_byContract.length && !loadingByContract"
                            style="width: 25px"
                            src="/img/icons/pdf-v2.png" />
                        </a>
                        <a href="#"
                          class="float-right mt-n2"
                          @click.prevent="downloadGeneralExcel('contract_proposals.id')"
                        >
                          <img
                            v-if="$_byContract.length && !loadingByContract"
                            style="width: 25px"
                            src="/img/icons/excel.png" />
                        </a>
                      </div>
                      <div class="row">
                        <div class="col-md-12 table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr class="fixed">
                                <th class="text-center table-column-light-gray text-dark">Cliente</th>
                                <th class="text-center table-column-light-gray text-dark">Concreto(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Serviços(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Total</th>
                                <th class="text-center table-column-light-gray text-dark">Preço Médio</th>
                                <th class="text-center table-column-light-gray text-dark">Margem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in 10" v-show="loadingByContract">
                                <th colspan="12">
                                  <skeleton-puzl type="button" />
                                </th>
                              </tr>
                              <tr v-for="item in $_byContract" v-show="!loadingByContract">
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{ item.contract_proposal_code }} |
                                    <span class="font-weight-normal" :title="item.entity_name">
                                      {{ truncateString(item.entity_name, 25) }}
                                    </span>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_schedule_travel_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_service_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.total_contract_proposal_formulation_value | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.total_services | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.total_schedule_additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.total_single_additional_invoice_value | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ item.total | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.total_schedule_travel_volume > 0">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span class=""
                                              >Concreto{{ item.sale_price | currency }}</span
                                            >
                                          </h5>
                                          <h5 class="mb-0">
                                            <span class="">
                                              Serviços {{ item.service_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span class="">
                                              Adicionais
                                              {{ item.additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span class="">
                                              Avulsos
                                              {{ item.single_price | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>
                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ (item.total / item.total_schedule_travel_volume) | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.margin > 0">
                                    <el-popover class="p-0 float-right pr-0" placement="bottom" trigger="hover">
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span class="">Mcc {{ item.margin | currency() }} /m³</span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button slot="reference" class="p-0 m-0 shadow-none" outiline size="sm" type="secundary">
                                        <i class="fa-solid fa-circle-chevron-down text-default" style="cursor: pointer"/>
                                      </base-button>
                                    </el-popover>
                                  </span>
                                  <span class="style-table numeric" style="font-size: 0.85rem">
                                    {{ marginCalculated(item) | currency() }}
                                  </span>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <PuzlEmptyData v-show="$_byContract && !$_byContract.length && !loadingByContract"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabela 4 agrupadada por cidade -->
              <div class="row pt-0" v-if="grouped_table_listing.by_city">
                <div class="col-md-12 p-1">
                  <div class="card">
                    <div class="card-body mt-n2">
                      <h4>Dia</h4>
                      <div class="form-check mt-n2">
                        <input
                          v-model="marginSortCheckboxCity"
                          class="form-check-input"
                          type="checkbox"
                          id="marginSortCheckboxCity"
                          @input="filterOrderByMarginCity"
                        >
                        <label for="marginSortCheckboxCity" style="font-size: 13px;">
                          Ordenar por margem
                        </label>
                        <a href="#"
                          class="float-right mt-n2 px-1"
                          @click.prevent="downloadGeneralPdf('city')"
                        >
                          <img
                            v-if="$_byCity.length && !loadingByCity"
                            style="width: 25px"
                            src="/img/icons/pdf-v2.png" />
                        </a>
                        <a href="#"
                          class="float-right mt-n2"
                          @click.prevent="downloadGeneralExcel('city')"
                        >
                          <img
                            v-if="$_byCity.length && !loadingByCity"
                            style="width: 25px"
                            src="/img/icons/excel.png" />
                        </a>
                      </div>
                      <div class="row">
                        <div class="col-md-12 table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr class="fixed">
                                <th class="text-center table-column-light-gray text-dark">Cidade</th>
                                <th class="text-center table-column-light-gray text-dark">Concreto(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Serviços(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Total</th>
                                <th class="text-center table-column-light-gray text-dark">Preço Médio</th>
                                <th class="text-center table-column-light-gray text-dark">Margem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in 10" v-show="loadingByCity">
                                <th colspan="12">
                                  <SkeletonPuzl type="button" />
                                </th>
                              </tr>
                              <tr v-for="item in $_byCity" v-show="!loadingByCity">
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{ item.city }}
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_schedule_travel_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_service_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.total_contract_proposal_formulation_value | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.total_services | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.total_schedule_additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.total_single_additional_invoice_value | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ item.total | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.total_schedule_travel_volume > 0">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                            Concreto
                                            {{ item.sale_price | currency }}
                                          </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.service_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.single_price | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>
                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ (item.total / item.total_schedule_travel_volume) | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.margin > 0">
                                    <el-popover class="p-0 float-right pr-0" placement="bottom" trigger="hover">
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span class="">Mcc {{ item.margin | currency() }} /m³</span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button slot="reference" class="p-0 m-0 shadow-none" outiline size="sm" type="secundary">
                                        <i class="fa-solid fa-circle-chevron-down text-default" style="cursor: pointer"/>
                                      </base-button>
                                    </el-popover>
                                  </span>
                                  <span class="style-table numeric" style="font-size: 0.85rem">
                                    {{ marginCalculated(item) | currency() }}
                                  </span>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <PuzlEmptyData v-show="$_byCity && !$_byCity.length && !loadingByCity"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabela 5 agrupadada por dia (filtro de data) -->
              <div class="row pt-0" v-if="grouped_table_listing.by_day">
                <div class="col-md-12 p-1">
                  <div class="card">
                    <div class="card-body mt-n2">
                      <h4>Dia</h4>
                      <div class="form-check mt-n2">
                        <input
                          v-model="marginSortCheckboxDay"
                          class="form-check-input"
                          type="checkbox"
                          id="marginSortCheckboxDay"
                          @input="filterOrderByMarginDay"
                        >
                        <label for="marginSortCheckboxDay" style="font-size: 13px;">
                          Ordenar por margem
                        </label>
                        <a href="#"
                          class="float-right mt-n2 px-1"
                          @click.prevent="downloadGeneralPdf('day')"
                        >
                          <img
                            v-if="$_byDay.length && !loadingByDay"
                            style="width: 25px"
                            src="/img/icons/pdf-v2.png" />
                        </a>
                        <a href="#"
                          class="float-right mt-n2"
                          @click.prevent="downloadGeneralExcel('day')"
                        >
                          <img
                            v-if="$_byDay.length && !loadingByDay"
                            style="width: 25px"
                            src="/img/icons/excel.png" />
                        </a>
                      </div>
                      <div class="row">
                        <div class="col-md-12 table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr class="fixed">
                                <th class="text-center table-column-light-gray text-dark">Dia</th>
                                <th class="text-center table-column-light-gray text-dark">Concreto(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Serviços(m³)</th>
                                <th class="text-center table-column-light-gray text-dark">Total</th>
                                <th class="text-center table-column-light-gray text-dark">Preço Médio</th>
                                <th class="text-center table-column-light-gray text-dark">Margem</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in 10" v-show="loadingByDay">
                                <th colspan="12">
                                  <SkeletonPuzl type="button" />
                                </th>
                              </tr>
                              <tr v-for="item in $_byDay" v-show="!loadingByDay">
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{ item.day | parseDate('DD MMM YYYY') }}
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_schedule_travel_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    {{
                                      Number(item.total_service_volume)
                                        .toFixed(1)
                                        .toString()
                                        .replace(".", ",")
                                    }}
                                    m³
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.total_contract_proposal_formulation_value | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.total_services | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.total_schedule_additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.total_single_additional_invoice_value | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>

                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ item.total | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.total_schedule_travel_volume > 0">
                                    <el-popover
                                      class="p-0 float-right pr-0"
                                      placement="bottom"
                                      trigger="hover"
                                    >
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span>
                                              Concreto
                                              {{ item.sale_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Serviços {{ item.service_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Adicionais
                                              {{ item.additional_price | currency }}
                                            </span>
                                          </h5>
                                          <h5 class="mb-0">
                                            <span>
                                              Avulsos
                                              {{ item.single_price | currency }}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button
                                        slot="reference"
                                        class="p-0 m-0 shadow-none"
                                        outiline
                                        size="sm"
                                        type="secundary"
                                      >
                                        <i
                                          class="fa-solid fa-circle-chevron-down text-default"
                                          style="cursor: pointer"
                                        />
                                      </base-button>
                                    </el-popover>
                                    <small
                                      class="numeric font-weight-600"
                                      style="font-size: 0.85rem"
                                    >
                                      {{ (item.total / item.total_schedule_travel_volume) | currency }}
                                    </small>
                                  </span>
                                </th>
                                <th class="text-left">
                                  <span class="style-table font-weight-normal" v-if="item.margin > 0">
                                    <el-popover class="p-0 float-right pr-0" placement="bottom" trigger="hover">
                                      <div class="mt-2 border-left border-3 border-indigo p-2">
                                        <div>
                                          <h5 class="mb-0">
                                            <span class="">Mcc {{ item.margin | currency() }} /m³</span>
                                          </h5>
                                        </div>
                                      </div>
                                      <base-button slot="reference" class="p-0 m-0 shadow-none" outiline size="sm" type="secundary">
                                        <i class="fa-solid fa-circle-chevron-down text-default" style="cursor: pointer"/>
                                      </base-button>
                                    </el-popover>
                                  </span>
                                  <span class="style-table numeric" style="font-size: 0.85rem">
                                    {{ marginCalculated(item) | currency() }}
                                  </span>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <PuzlEmptyData v-show="$_byDay && !$_byDay.length && !loadingByDay"/>
                        </div>
                      </div>
                      <Pagination
                        :loading="loadingByDay"
                        :source="sourceByDay"
                        @navegate="navegateByDay"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <ModalSearchContract @setContractProposal="handleFilterContract" ref="modalSearchContract" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import InputDatePicker from "@/components/InputDatePicker";
import Pagination from "@/components/Utils/Pagination";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { mapGetters } from "vuex";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";

export default {
  name: "ListResume",
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    InputDatePicker,
    SkeletonPuzl,
    MultiFilter,
    Pagination,
    SpinnerPuzl,
    PuzlEmptyData,
    LoadingPagination,
    ModalSearchContract,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingByPlant: false,
      loadingBySeller: false,
      loadingByContract: false,
      loadingByCity: false,
      loadingByDay: false,
      loadingWidgets: true,
      loadingSeller: true,
      loadingCompanyPlants: true,
      loadingContractProposals: true,
      filter: {
        company_plants: null,
        sellers_uuid: null,
        contract_proposal_code: null,
        filterGroupBy: null,
        marginSortCheckboxGeneral: false,
        marginSortCheckboxSeller: false,
        marginSortCheckboxContract: false,
        marginSortCheckboxCity: false,
        marginSortCheckboxDay: false,
        general_setting_general_tax_margin_percent: null,
        delivery_type: null,
        concrete_removal: null,
        range: {
          start: null,
          end: null,
        },
      },
      selectedCompanyPlants: [],
      selectedSellers: [],
      sourceByDay: null,
      range: {
        start: null,
        end: null,
      },
      params: {
        page: 1,
        per_page: 10,
      },
      defaultRange: {
        start: new Date(),
        end: new Date(),
      },
      marginSortCheckboxGeneral: false,
      marginSortCheckboxSeller: false,
      marginSortCheckboxContract: false,
      marginSortCheckboxCity: false,
      marginSortCheckboxDay: false,
      grouped_table_listing: {
        by_plant: true,
        by_seller: false,
        by_contract: false,
        by_city: false,
        by_day: false,
      },
      isInitialDataLoad: true,
      general_setting_general_tax_margin_percent: null,
      delivery_types: [
        {id: 1, name: 'Venda de concreto'},
        {id: 2, name: 'Doação'},
        {id: 3, name: 'Transferência'},
        {id: 4, name: 'Uso interno'},
        {id: 5, name: 'Reparo de concretagem'},
      ],
      concrete_removals: [
        {id: 0, name: 'Entrega própria'},
        {id: 1, name: 'Retira'},
      ],
    };
  },
  watch: {
    // Observa alterações no filtro global de central.
    '$filterable': {
      handler() {
        this.getWidgets();
      },
    }
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_sellers: "user/getAllActiveSellersSimplified",
      $_widgets: "generalResume/getWidgets",
      $_byPlant: "generalResume/fetchByPlant",
      $_bySeller: "generalResume/fetchBySeller",
      $_byContract: "generalResume/fetchByContract",
      $_byCity: "generalResume/fetchByCity",
      $_byDay: "generalResume/fetchByDay",
    }),
    /**
     * Caso na tela de configurações gerais a opção de aplicação de imposto geral em margem
     * estiver definido, então vai considerar o cálculo.
     * @returns {string}
     */
    totalCalculatedMargin() {
      if (this.general_setting_general_tax_margin_percent) {
        return this.$_widgets.total_margin - (this.$_widgets.total / this.$_widgets.total_concrete) * (this.general_setting_general_tax_margin_percent / 100);
      } else {
        return this.$_widgets.total_margin;
      }
    },
  },
  async mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingCompanyPlants = false;
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    }

    this.$store.dispatch("user/getAllActiveSellersSimplified").then(() => {
      this.loadingSeller = false;
    });
    await this.$store.dispatch("generalSetting/show").then(response => {
      if (response.data.geral_tax_application_in_margin) {
        this.general_setting_general_tax_margin_percent = response.data.general_tax_margin_percent;
      }
    });
    this.$refs["multi-filter"]._self.$forceUpdate();
    this.range = this.defaultRange;
    this.init({}, true);
  },
  methods: {
    /**
     * Chama todas as funções de fetch
     * @param {object} filter
     */
    load(filter = null) {
      this.params.page = 1;
      this.filter = filter;
      this.init(filter, true, true);
    },
    /**
     * @param {object} filter
     * @param {boolean} isInitialDataLoad - carregamento inicial da tela
     * @param {boolean} isSelectedFilter - quando aplicar um filtro
     */
    init(filter = null, isInitialDataLoad = null, isSelectedFilter = null) {
      this.checkActiveMarginFilter();
      if (isInitialDataLoad) {
        // Quando aplica algum filtro permanece na mesma aba, se não é o carregamento inicial.
        if (isSelectedFilter) {
          this.switchTabAndFetchData(filter, true);
          this.getWidgets();
        } else {
          this.fetchByPlant(filter, true);
          this.getWidgets();
        }
      } else {
        this.switchTabAndFetchData(filter);
      }
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.filter.general_setting_general_tax_margin_percent = this.general_setting_general_tax_margin_percent;
      this.$store
        .dispatch("generalResume/getWidgets", { filter: this.filter })
        .then(() => {
          this.loadingWidgets = false;
        });
    },
    /**
     * @param {object} filter
     * @param {boolean} initTabLoad
     */
    fetchByPlant(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.loadingByPlant = true;
      }
      this.grouped_table_listing = {
        by_plant: true,
        by_seller: false,
        by_contract: false,
        by_city: false,
        by_day: false,
      };
      this.startCursor(filter);
      this.filter.range = this.range;
      this.filter.filterGroupBy = "plant_id";
      this.$store
        .dispatch("generalResume/fetchByPlantItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingByPlant = false;
        });
    },
    /**
     * @param {object} filter
     * @param {boolean} initTabLoad
     */
    fetchBySeller(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.loadingBySeller = true;
      }
      this.grouped_table_listing = {
        by_plant: false,
        by_seller: true,
        by_contract: false,
        by_city: false,
        by_day: false,
      };
      this.startCursor(filter);
      this.filter.range = this.range;
      this.filter.filterGroupBy = "seller";
      this.$store
        .dispatch("generalResume/fetchBySellerItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingBySeller = false;
        });
    },
    /**
     * @param {object} filter
     * @param {boolean} initTabLoad
     */
    fetchByContract(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.loadingByContract = true;
      }
      this.grouped_table_listing = {
        by_plant: false,
        by_seller: false,
        by_contract: true,
        by_city: false,
        by_day: false,
      };
      this.startCursor(filter);
      this.filter.range = this.range;
      this.filter.filterGroupBy = "contract_proposals.id";
      this.$store
        .dispatch("generalResume/fetchByContractItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingByContract = false;
        });
    },
    /**
     * @param {object} filter
     * @param {boolean} initTabLoad
     */
    fetchByCity(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.loadingByCity = true;
      }
      this.grouped_table_listing = {
        by_plant: false,
        by_seller: false,
        by_contract: false,
        by_city: true,
        by_day: false,
      };
      this.startCursor(filter);
      this.filter.range = this.range;
      this.filter.filterGroupBy = "city";
      this.$store
        .dispatch("generalResume/fetchByCityItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingByCity = false;
        });
    },
    /**
     * @param {object} filter
     * @param {boolean} initTabLoad
     */
    fetchByDay(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.loadingByDay = true;
      }
      this.grouped_table_listing = {
        by_plant: false,
        by_seller: false,
        by_contract: false,
        by_city: false,
        by_day: true,
      };
      this.filter.range = this.range;
      this.filter.filterGroupBy = "day";
      this.$store
        .dispatch("generalResume/fetchByDay", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.sourceByDay = response;
        })
        .finally(() => {
          this.loadingByDay = false;
        });
    },
    /** Listagem da aba selecionada */
    switchTabAndFetchData(filter = null, initTabLoad = null) {
      if (this.grouped_table_listing.by_plant) {
        this.fetchByPlant(filter, initTabLoad);
      } else if (this.grouped_table_listing.by_seller) {
        this.fetchBySeller(filter, initTabLoad);
      } else if (this.grouped_table_listing.by_contract)  {
        this.fetchByContract(filter, initTabLoad);
      } else if (this.grouped_table_listing.by_city)  {
        this.fetchByCity(filter, initTabLoad);
      } else {
        this.fetchByDay(filter, initTabLoad)
      }
    },
    /**
     * Caso na tela de configurações gerais a opção de aplicação de imposto geral em margem
     * estiver definido, então vai considerar o cálculo.
     * @param {object} item
     * @returns {string}
     */
    marginCalculated(item) {
      if (this.general_setting_general_tax_margin_percent) {
        return item.total_margin - (item.total / item.total_schedule_travel_volume) * (this.general_setting_general_tax_margin_percent / 100);
      } else {
        return item.total_margin;
      }
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.range = {
        start: filter.start,
        end: filter.end,
      };
      this.params.page = 1;
      this.init({}, true, true);
    },
    filterOrderByMarginGeneral() {
      this.filter.marginSortCheckboxGeneral = !this.marginSortCheckboxGeneral;
      this.marginSortCheckboxGeneral = !this.marginSortCheckboxGeneral;
      this.filter.marginSortCheckboxSeller = false;
      this.filter.marginSortCheckboxContract = false;
      this.filter.marginSortCheckboxCity = false;
      this.filter.marginSortCheckboxDay = false;
      this.fetchByPlant({}, true);
    },
    filterOrderByMarginSeller() {
      this.filter.marginSortCheckboxSeller = !this.marginSortCheckboxSeller;
      this.marginSortCheckboxSeller = !this.marginSortCheckboxSeller;
      this.filter.marginSortCheckboxGeneral = false;
      this.filter.marginSortCheckboxContract = false;
      this.filter.marginSortCheckboxCity = false;
      this.filter.marginSortCheckboxDay = false;
      this.fetchBySeller({}, true);
    },
    filterOrderByMarginContract() {
      this.filter.marginSortCheckboxContract = !this.marginSortCheckboxContract;
      this.marginSortCheckboxContract = !this.marginSortCheckboxContract;
      this.filter.marginSortCheckboxGeneral = false;
      this.filter.marginSortCheckboxSeller = false;
      this.filter.marginSortCheckboxCity = false;
      this.filter.marginSortCheckboxDay = false;
      this.fetchByContract({}, true);
    },
    filterOrderByMarginCity() {
      this.filter.marginSortCheckboxCity = !this.marginSortCheckboxCity;
      this.marginSortCheckboxCity = !this.marginSortCheckboxCity;
      this.filter.marginSortCheckboxGeneral = false;
      this.filter.marginSortCheckboxSeller = false;
      this.filter.marginSortCheckboxContract = false;
      this.filter.marginSortCheckboxDay = false;
      this.fetchByCity({}, true);
    },
    filterOrderByMarginDay() {
      this.filter.marginSortCheckboxDay = !this.marginSortCheckboxDay;
      this.marginSortCheckboxDay = !this.marginSortCheckboxDay;
      this.filter.marginSortCheckboxGeneral = false;
      this.filter.marginSortCheckboxSeller = false;
      this.filter.marginSortCheckboxContract = false;
      this.filter.marginSortCheckboxCity = false;
      this.fetchByDay({}, true);
    },
    /**
     * Envia o filtro de ordenação por margem caso esteja selecionado e a
     * requisição feita pelo botão de pesquisar do multi filter.
     */
    checkActiveMarginFilter() {
      if (this.marginSortCheckboxGeneral) {
        this.filter.marginSortCheckboxGeneral = true;
      } else if (this.marginSortCheckboxSeller) {
        this.filter.marginSortCheckboxSeller = true;
      } else if (this.marginSortCheckboxContract) {
        this.filter.marginSortCheckboxContract = true;
      } else if (this.marginSortCheckboxCity) {
        this.filter.marginSortCheckboxCity = true;
      } else if (this.marginSortCheckboxDay) {
        this.filter.marginSortCheckboxDay = true;
      }
    },
    /**
     * Lida com o filtro de Centrais
     * @param {array} filter
     */
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.params.page = 1;
      this.switchTabAndFetchData(filter, true);
      this.getWidgets();
    },
    /**
     * Lida com o filtro de Vendedores
     * @param {array} filter
     */
    handleFilterSellers(filter) {
      this.selectedSellers = filter;
      this.params.page = 1;
      this.switchTabAndFetchData(filter, true);
      this.getWidgets();
    },
    /**
     * Lida com o filtro do tipo de venda
     * @param {array} filter
     */
    handleDeliveryType(filter) {
      this.params.page = 1;
      this.switchTabAndFetchData(filter, true);
      this.getWidgets();
    },
    /**
     * Lida com o filtro do tipo de retira
     * @param {array} filter
     */
    handleConcreteRemoval(filter) {
      this.params.page = 1;
      this.switchTabAndFetchData(filter, true);
      this.getWidgets();
    },
    /**
     * Lida com o filtro de contrato selecionado na modal
     * @param {object} contract
     */
    handleFilterContract(contract) {
      this.filter.contract_proposal_code = contract.code;
      this.switchTabAndFetchData({}, true);
      this.getWidgets();
      this.filter.contract_proposal_code = null;
    },
    navegateByDay(page) {
      this.params.page = page;
      this.fetchByDay();
    },
    /**
     * Download pdf relatório das listagens
     * @param {string} grouping
     */
    async downloadGeneralPdf(grouping) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.filter.filterGroupBy = grouping;
      this.$store
        .dispatch("generalResume/getReportPdf", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório resumo geral.pdf");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo pdf!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
    /**
     * Download excel relatório das listagens
     * @param {string} grouping
     */
    downloadGeneralExcel(grouping) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.filter.filterGroupBy = grouping;
      this.$store
        .dispatch("generalResume/getReportExcel", { filter: this.filter })
        .then(async (response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
    /** Reseta so filtros */
    clearFilters() {
      this.filter = {};
      this.selectedCompanyPlants = [];
      this.selectedSellers = [];
      this.range = this.defaultRange;
      this.params.page = 1;
      this.switchTabAndFetchData();
      this.getWidgets();
    },
    /**
     * @param {string} str
     * @param {number} maxLength
     */
    truncateString(str, maxLength) {
      if (str && str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    },
    /** Abre modal de pesquisa por contrato */
    openModalSearchContract() {
      this.$refs.modalSearchContract.openModal();
    },
    /**
     * CARD VOLUME E ENTREGAS
     * Calcula a porcentagem do volume total de serviços em relação ao volume total de concreto.
     * @return {object} Contém a porcentagem e o raio da borda.
     */
    getServicePercent() {
      let service = Number(this.$_widgets.total_services_volume);
      let concrete = Number(this.$_widgets.total_concrete);
      if (!service || !concrete) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (service / concrete) * 100;
      return {
        percent: percent.toFixed(1) + '%',
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * Calcula a porcentagem restante do volume total de concreto após subtrair a porcentagem do volume total de serviços.
     * @return {object} Contém a porcentagem e o raio da borda.
     */
    getConcretePercent() {
      let servicePercent = this.getServicePercent().percent;
      if (servicePercent === '') {
        return {
          percent: '',
          border: '10px'
        };
      }
      servicePercent = Number(servicePercent.replace('%', ''));
      let percent = 100 - servicePercent;
      return {
        percent: percent.toFixed(1) + '%',
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * CARD MCC E MARGEM
     * Calcula a porcentagem do MCC total em relação à soma da MCC e da margem total.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getMccPercent() {
      let mcc = Number(this.$_widgets.margin);
      let margin = Number(this.$_widgets.total_margin);
      if (!mcc || !margin) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (mcc / (mcc + margin)) * 100;
      return {
        percent: percent.toFixed(1) + '%',
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * Calcula a porcentagem restante da margem total após subtrair a porcentagem da margem do MCC.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getMarginPercent() {
      let mccPercent = this.getMccPercent().percent;
      if (mccPercent === '') {
        return {
          percent: '',
          border: '10px'
        };
      }
      mccPercent = Number(mccPercent.replace('%', ''));
      let percent = 100 - mccPercent;
      return {
        percent: percent.toFixed(1) + '%',
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * CARD TOTAL
     * Calcula o preço médio total dividindo o total pela quantidade total de concreto.
     * @return {Number} O preço médio total.
     */
    getTotalPrice() {
      return Number(this.$_widgets.total / this.$_widgets.total_concrete);
    },
    /**
     * Calcula a porcentagem do preço médio do concreto em relação ao preço médio total.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getAverageConcretePercent() {
      let concretePrice = Number(this.$_widgets.sale_price);
      let totalPrice = this.getTotalPrice();
      if (!concretePrice || !totalPrice) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (concretePrice / totalPrice) * 100;
      return {
        percent: percent,
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * Calcula a porcentagem do preço médio do serviço em relação ao preço médio total.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getAverageServicePercent() {
      let servicePrice = Number(this.$_widgets.service_price);
      let totalPrice = this.getTotalPrice();
      if (!servicePrice || !totalPrice) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (servicePrice / totalPrice) * 100;
      return {
        percent: percent,
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * Calcula a porcentagem do preço médio adicional em relação ao preço médio total.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getAverageAdditionalPercent() {
      let additionalPrice = Number(this.$_widgets.additional_price);
      let totalPrice = this.getTotalPrice();
      if (!additionalPrice || !totalPrice) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (additionalPrice / totalPrice) * 100;
      return {
        percent: percent,
        border: percent == 0 ? '10px' : '0px'
      };
    },
    /**
     * Calcula a porcentagem do preço médio avulso em relação ao preço médio total.
     * @return {Object} Contém a porcentagem e o raio da borda.
     */
    getAverageSinglePercent() {
      let singlePrice = Number(this.$_widgets.single_price);
      let totalPrice = this.getTotalPrice();
      if (!singlePrice || !totalPrice) {
        return {
          percent: '',
          border: '10px'
        };
      }
      let percent = (singlePrice / totalPrice) * 100;
      return {
        percent: percent,
        border: percent == 0 ? '10px' : '0px'
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.font-weight-semibold {
  font-size: 12px;
}

.font-weight-normal {
  font-size: 12px;
}

.doc-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    display: flex;
  }
}

.custom-icon {
  font-size: 22px;
  cursor: pointer;
  align-self: center;
}

.card-text {
  display: flex;
  flex-wrap: nowrap;
}

.new-default-black-font {
  color: #2B2D32 !important;
}

/* Cards totais */
.card-text-border {
  border: 2px solid #D3D3D3;
  border-radius: 10px;
  padding: 6px;
}
.card-margin-top {
  margin-top: -14px;
}
@media (max-width: 1190px) {
  .card-margin-top-mobile {
    margin-top: -19px;
  }
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.height-card {
    height: 180px;
}
.font-size-card {
  font-size: 10px;
}

/* texto abas tabela */
@media (max-width: 576px) {
  .text-smaller {
    font-size: 0.5rem;
  }
}

/* cor do cabeçalho da tabela */
.table-column-light-gray {
  background-color: #CCCCCC;
}
</style>
