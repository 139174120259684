<template>
  <div>
    <div class="row card-row">
      <div class="col-md-12 p-1">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 p-1">
                <InputDatePicker :filterable="4" ref="period" :defaultRange="defaultRange" @handleFilterDate="handleFilterDate"/>
              </div>
              <div class="col-md-3 p-1">
                <puzl-select :loading="loadingPlant"
                             :disabled="loading"
                             class="select-xl"
                             @change="handleFilter"
                             v-model="filter.company_plants_uuid"
                             placeholder="Centrais"
                             multiple
                             :items="plants"
                             customKey="uuid"
                >
                </puzl-select>
              </div>
              <div class="col-md-3 p-1">
                <puzl-select :loading="loadingCustomerConstruction"
                             :disabled="loading"
                             class="select-xl"
                             @change="handleFilter(false)"
                             v-model="filter.constructions_id"
                             placeholder="Cliente / Obra"
                             multiple
                             :items="customerConstructions"
                >
                </puzl-select>
              </div>
              <div class="col-md-3 p-1">
                <base-input input-classes="form-control-sm">
                  <puzl-select :loading="loadingSeller"
                               :disabled="loading"
                               class="select-xl"
                               @change="handleFilter(false)"
                               v-model="filter.sellers_uuid"
                               placeholder="Vendedor"
                               multiple
                               :items="sellers"
                               customKey="uuid"
                  >
                  </puzl-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="container-fluid">-->
  <!--    <div class="card">-->
  <!--      <div class="card-body pb-2">-->
  <!--        <div class="form-group row m-0 d-flex justify-content-center custom-dashboard">-->
  <!--          <div class="col-md-3 p-1">-->
  <!--            <InputDatePicker ref="period" :defaultRange="defaultRange" @handleFilterDate="handleFilterDate"/>-->
  <!--          </div>-->

  <!--          &lt;!&ndash; Central &ndash;&gt;-->
  <!--          <div class="col-md-2 p-1 select-lg">-->
  <!--            <div>-->
  <!--              <el-select-->
  <!--                :disabled="loadingPlant || loading"-->
  <!--                collapse-tags-->
  <!--                @change="handleFilter"-->
  <!--                :loading="loadingPlant"-->
  <!--                v-model="filter.company_plants_uuid"-->
  <!--                placeholder="Centrais"-->
  <!--                filterable-->
  <!--                multiple-->
  <!--              >-->
  <!--                <el-option v-for="plant in plants" :key="plant.id" :label="plant.name" :value="plant.uuid"></el-option>-->
  <!--              </el-select>-->
  <!--            </div>-->
  <!--          </div>-->

  <!--          &lt;!&ndash; Cliente/Obra &ndash;&gt;-->
  <!--          <div class="col-md-2 p-1 select-lg">-->
  <!--            <el-select-->
  <!--              collapse-tags-->
  <!--              :disabled="loadingCustomerConstruction || loading"-->
  <!--              @change="handleFilter(false)"-->
  <!--              :loading="loadingCustomerConstruction"-->
  <!--              v-model="filter.constructions_id"-->
  <!--              placeholder="Cliente / Obra"-->
  <!--              filterable-->
  <!--              multiple-->
  <!--            >-->
  <!--              <el-option-->
  <!--                v-for="customerConstruction in customerConstructions"-->
  <!--                :key="customerConstruction.id"-->
  <!--                :label="customerConstruction.name"-->
  <!--                :value="customerConstruction.id"-->
  <!--              >-->
  <!--              </el-option>-->
  <!--            </el-select>-->
  <!--          </div>-->

  <!--          &lt;!&ndash; Vendedor &ndash;&gt;-->
  <!--          <div class="col-md-2 p-1 select-lg">-->
  <!--            <el-select-->
  <!--              collapse-tags-->
  <!--              :disabled="loading"-->
  <!--              @change="handleFilter(false)"-->
  <!--              :loading="loadingSeller"-->
  <!--              v-model="filter.sellers_uuid"-->
  <!--              placeholder="Vendedor"-->
  <!--              filterable-->
  <!--              multiple-->
  <!--            >-->
  <!--              <el-option-->
  <!--                v-for="seller in sellers"-->
  <!--                :key="seller.id"-->
  <!--                :label="seller.name"-->
  <!--                :value="seller.uuid"-->
  <!--              >-->
  <!--              </el-option>-->
  <!--            </el-select>-->
  <!--          </div>-->
  <!--          &lt;!&ndash; Limpar &ndash;&gt;-->
  <!--          <div class="col-md-2 p-1">-->
  <!--            <base-button title="Limpar filtros" @click.prevent="clearFilter" block type="secondary">-->
  <!--              <i class="fas fa-1x fa-eraser"></i>-->
  <!--            </base-button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="row m-0 d-flex justify-content-center">-->
  <!--          <div class="col-md-9 p-1"></div>-->
  <!--          <div class="col-md-3 p-1 text-center">-->
  <!--            <base-button @click="config.dark_mode = !config.dark_mode" type="default">-->
  <!--              <span class="btn-label"><i-->
  <!--                :class="config.dark_mode ? 'fa-2x fas fa-moon' : 'fa-2x far fa-moon'"></i></span>-->
  <!--              Modo escuro-->
  <!--            </base-button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "DashboardFilter",

  data() {
    return {
      filter: {
        period: {
          start: null,
          end: null
        },
        company_plants_uuid: [],
        constructions_id: [],
        sellers_uuid: [],
      },
      loadingPlant: true,
      loadingSeller: true,
      loadingCustomerConstruction: true,
      config: {
        dark_mode: false
      },
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      }
    };
  },
  components: {
    InputDatePicker,
    PuzlSelect
  },
  watch: {
    "config.dark_mode"() {
      this.$emit("changeTheme");
    }
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      services: "service/activeItems",
      customerConstructions: "customerConstruction/fetch",
      sellers: "user/sellers",
      loading: "commercialDashboard/loading"
    })
  },
  methods: {
    handleFilterDate(filter) {
      this.filter.period = filter;
      this.handleFilter();
    },
    handleFilter(customerConstruction = true) {
      if (!this.filter.period.start) {
        this.filter.period = this.defaultRange;
      }
      if (customerConstruction) {
        this.getCustomerConstructions();
      }
      this.$emit("fetch", this.filter);
    },
    clearFilter() {
      this.filter = {
        period: {
          start: null,
          end: null
        },
        company_plants_uuid: [],
        constructions_id: [],
        sellers_uuid: [],
      };
      this.$refs.period.resetDate();
      this.handleFilter();
    },
    getCustomerConstructions() {
      this.loadingCustomerConstruction = true;
      if (!this.filter.period.start) {
        this.filter.period = this.defaultRange;
      }
      this.$store.dispatch("customerConstruction/getByPeriodAndCompanyPlant", {filters: this.filter}).then(() => {
        this.loadingCustomerConstruction = false;
      });
    }
  },

  mounted() {
    const localPlants = localStorage.getItem('companyPlants');
    if (localPlants) {
      const plants = JSON.parse(localPlants);
      this.$store.commit('plant/SET_ACTIVE', plants.data);
      this.loadingPlant = false;
    } else {
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingPlant = false;
      });
    }

    this.$store.dispatch("user/fetchSellers").then(() => {
      this.loadingSeller = false;
    });
    this.getCustomerConstructions();
  }
};
</script>

<style>
.col-date-picker {
  max-height: 29px !important;
}

.label-config-control {
  padding-top: 0.2rem !important;
}

.custom-dashboard .el-select__tags-text {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
