<template>
  <div>
    <modal size="lg" :show="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <template>
          <div class="rom form-row p-2">
            <div class="col-md-4">
              <label>
                Nome <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-md-8">
              <validation-provider rules="required">
                <base-input
                  v-model="equipment.name"
                  input-classes="form-control form-control-sm"
                  name="Equipamento"
                  placeholder="Equipamento">
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="rom form-row p-2">
            <div class="col-md-4">
              <label>
                Tipo de arquivo <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-md-8">
              <validation-provider rules="required">
                <PuzlSelect
                  :items="$_types"
                  v-model="equipment.type_id"
                  :loading="loadingEquipmentTypes"
                  :disabled="loadingEquipmentTypes"
                />
              </validation-provider>
            </div>
          </div>
          <div class="rom form-row p-2">
            <div class="col-md-4">
              <label>
                Ignorar faixa
              </label>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input @input="updateIgnoreFrom" v-model="equipment.ignore_from" type="text" inputmode="numeric"
                           v-mask="['#######']"
                           class="form-control form-control-sm" input-group-classes="input-group-sm">
                  </base-input>
                </div>
                <div class="col-md-6">
                  <validation-provider :rules="{required: !!equipment.ignore_from}">
                    <base-input input-group-classes="input-group-sm">
                      <input :disabled="!equipment.ignore_from" v-model="equipment.ignore_to" type="text"
                             inputmode="numeric" v-mask="['#######']"
                             class="form-control form-control-sm" input-group-classes="input-group-sm">
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="rom form-row p-2">
            <div class="col-md-4">
              <label>
                Central <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-md-8">
              <validation-provider rules="required">
                <PuzlSelect
                  :items="[{id: 'all', name: 'Todos'}, ...$_plants]"
                  :multiple="true"
                  :loading="loadingPlants"
                  :disabled="loadingPlants"
                  v-model="equipment.company_plant_ids"
                  @input="setAll"
                />
              </validation-provider>
            </div>
          </div>
        </template>
        <div class="row  p-2">
          <div class="col-md-12 float-right text-right">
            <base-button @click.prevent="cancel" type="danger"
                         style="width: 140px;">
              <img class=" pointer mr-2" width="14" src="/img/icons/icons8/ios/cancel.png">
              Cancelar
            </base-button>
            <base-button type="success"
                         style="width: 120px;"
                         @click.prevent="save()"
                         :disabled="invalid"
                         native-type="submit" :loading="loadingSave">
              <img class=" pointer mr-2" width="14" src="/img/icons/save.png">
              Salvar
            </base-button>
          </div>
        </div>
        <template>
          <div class="row mt-n2">
            <div class="col-md-12">
              <hr>
            </div>
            <div class="col-md-12 table-responsive" style="height: 350px">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th width="40%">Nome</th>
                  <th class="text-center" width="10%">Central</th>
                  <th width="25%">Tipo de arquivo</th>
                  <th class="text-center" width="20%">Faixa</th>
                  <th class="text-center" width="5%">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!$_equipments">
                  <th colspan="3">
                    <PuzlEmptyData :with-card="false"/>
                  </th>
                </tr>
                <tr v-else v-for="item in $_equipments">
                  <th>
                    {{ item.name }}
                  </th>
                  <th class="text-center" width="10%">
                    <el-popover ref="dropdown" trigger="click" placement="bottom" class="p-0">
                      <span v-for="item in formatCompanyPlant(item.company_plant)">
                        {{item}} <br>
                      </span>
                      <span slot="reference" class="pointer">
                                            <img width="25"
                                                 src="/img/icons/chemical-plant-black.png"
                                                 alt="plus-math--v1" class="mr-2">
                    </span>
                    </el-popover>
                  </th>
                  <th>
                    {{ item.equipment_type }}
                  </th>
                  <th class="text-center" v-if="item.ignore_from">

                    {{ item.ignore_from }} - {{ item.ignore_to }}
                  </th>
                  <th class="text-center" v-else>
                    -
                  </th>
                  <th class="text-center">
                    <a href="#" @click.prevent="setEdit(item)">
                      <img  src="/img/icons/icons8/ios/create-new-yellow.png" width="21px" height="21px">
                    </a>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe"
import PuzlSelect from "@/components/PuzlSelect";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData.vue";

const defaultEquipment = {
  name: "",
  type_id: 1, // padrão CT310 Server
  ignore_from: null,
  ignore_to: null,
  company_plant_ids: []
}
export default {
  components: {
    PuzlEmptyData,
    PuzlSelect,
  },
  name: "ModalDefaultMolding",
  data() {
    return {
      loadingSave: false,
      is_editing: false,
      loadingPlants: true,
      loadingEquipmentTypes: true,
      loadingEquipments: true,
      show: false,
      equipment: undefined,
      title: 'Cadastro de equipamento de ensaio'
    }
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_types: "hardenedStateTestEquipment/getEquipmentTypes",
      $_equipments: "hardenedStateTestEquipment/fetch",
    }),
  },
  methods: {
    cancel() {
      if (this.is_editing) {
        return this.is_editing = false
      }
      this.show = false
    },
    updateIgnoreFrom() {
      if (!this.equipment.ignore_from) {
        this.equipment.ignore_to = null
      }
    },
    setEdit(equipment) {
      if (! equipment.company_plant) {
        equipment.company_plant_ids = ['all']
      }
      this.equipment = {...equipment}
      this.is_editing = true
    },
    setAll() {
      const index = this.equipment.company_plant_ids.findIndex((item) => item == 'all');
      if (this.equipment.company_plant_ids.length > 1 && index === 0) {
        return this.equipment.company_plant_ids.splice(0, 1)
      }
      if (this.equipment.company_plant_ids.length > 1 && this.equipment.company_plant_ids.includes("all")) {
        this.equipment.company_plant_ids = []
        this.equipment.company_plant_ids.push("all")
      }
    },
    formatCompanyPlant(company_plant) {
      if (! company_plant) {
        return ['Todos']
      }
      return company_plant
    },
    save() {
      this.loadingSave = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let equipment = {...this.equipment}
      if (this.equipment.company_plant_ids.includes('all')) {
        equipment.company_plant_ids = null
      }
      let loader = this.$loading.show()
      this.$store.dispatch('hardenedStateTestEquipment/add', equipment).then((response) => {
        this.$notify({
          type: 'success',
          message: response.message
        });
      }).finally(() => {
        loader.hide()
        if (this.is_editing) {
          this.is_editing = false
          this.equipment = {...defaultEquipment}
          this.loadEquipments()
        }
        else {
          this.close()
        }
        this.loadingSave = false
      })
    },
    close() {
      this.show = false
    },
    handleCreateModal() {
      this.loadData()
      this.equipment = {...defaultEquipment}
      this.show = true
    },
    loadEquipments() {
      this.loadingEquipments = true;
      this.$store.dispatch('hardenedStateTestEquipment/fetchItems').then(() => {
        this.loadingEquipments = false;
      });
    },
    loadData() {
      this.loadingPlants = true;
      const localPlants = localStorage.getItem('companyPlants');
      if (localPlants) {
        const plants = JSON.parse(localPlants);
        this.$store.commit('plant/SET_ACTIVE', plants.data);
        this.loadingPlants = false;
      } else {
        this.$store.dispatch('plant/getPlantsSimplified').then(() => {
          this.loadingPlants = false;
        });
      }

      this.loadingEquipmentTypes = true;
      this.$store.dispatch('hardenedStateTestEquipment/fetchEquipmentTypeItems').then(() => {
        this.loadingEquipmentTypes = false;
      });
     this.loadEquipments()
    },
  }
}
</script>

<style scoped>

</style>
