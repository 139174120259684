<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                     <div>
                        <div class="form-group row m-0 p-0 pt-2">
                            <label class="col-md-5 pt-1 pb-3 mb-1 col-form-label form-control-label text-left">
                                Central
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pb-3  pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="adjustment.plant_uuid" 
                                            :items="plants" 
                                            customKey="uuid" 
                                            :loading="loadingPlants"
                                            :disabled="loadingPlants"
                                            @input="getChargePoints" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                                Ponto de carga
                                <span class="text-danger">&nbsp;*</span>
                             </label>
                            <div class="col-md-7 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <PuzlSelect 
                                            v-model="adjustment.charge_point_uuid" 
                                            :items="chargePoints" 
                                            customKey="uuid" 
                                            :loading="!secondSelect && adjustment.plant_uuid"
                                            :disabled="!secondSelect || !adjustment.plant_uuid" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal('create')">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatErrorValidation } from '@/plugins'
import PuzlSelect from "@/components/PuzlSelect"
export default {
    name: "ModalCreateAdjustment",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Ajuste Ponto de Carga',
                create: false,
            },
            adjustment: {
                plant_uuid: null,
                charge_point_uuid:null,
            },
            loadingStore: false,
            secondSelect: false,
            loadingPlants: true,
        }
    },
    computed: {
        ...mapGetters({
            'plants': 'plant/activeItems',
            'chargePoints': 'plantChargePoint/fetch'
        }),
    },
    methods: {
        closeModal() {
            this.adjustment = {
                plant_uuid: null,
                charge_point_uuid : null
            }
            this.modal.create = false
        },
        handleCreateModal() {
            this.loadData();
            this.modal.create = true;
        },
        getChargePoints(){
            if(this.adjustment.plant_uuid){
                this.adjustment.charge_point_uuid = null
                this.secondSelect = false
                this.loadingStore = true
                this.$store.dispatch('plantChargePoint/getByPlant',this.adjustment.plant_uuid).then(() => {
                    this.loadingStore = false
                    this.secondSelect = true
                }) 
            } else {
                this.adjustment.charge_point_uuid = null
            }
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentChargePoint/add', this.adjustment)
                .then(response => {
                    this.adjustment = {
                        plant_uuid: null,
                        charge_point_uuid: null
                    };
                    this.closeModal('create');
                    this.$emit("createdChargePointAdjustments");
                    this.loadingStore = false
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch((error) => {
                    if (error.status === 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                })
        },
        loadData() {
            this.loadingPlants = true;
            const localPlants = localStorage.getItem('companyPlants');
            if (localPlants) {
                const plants = JSON.parse(localPlants);
                this.$store.commit('plant/SET_ACTIVE', plants.data);
                this.loadingPlants = false;
            } else {
                this.$store.dispatch('plant/getPlantsSimplified').then(() => {
                this.loadingPlants = false;
                });
            }
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    }
}
</script>

<style scoped>

</style>
